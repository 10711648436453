import React from 'react'

export default function AddIcon() {
  return (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
      <title>Add_icon</title>
      <g
        id="Add_icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <path
          d="M18,31 C21.5833333,31 24.6458333,29.7291667 27.1875,27.1875 C29.7291667,24.6458333 31,21.5833333 31,18 C31,14.4166667 29.7291667,11.3541667 27.1875,8.8125 C24.6458333,6.27083333 21.5833333,5 18,5 C14.4166667,5 11.3541667,6.27083333 8.8125,8.8125 C6.27083333,11.3541667 5,14.4166667 5,18 C5,21.5833333 6.27083333,24.6458333 8.8125,27.1875 C11.3541667,29.7291667 14.4166667,31 18,31 Z M19.3125,24.6875 L16.6875,24.6875 L16.6875,19.3125 L11.3125,19.3125 L11.3125,16.6875 L16.6875,16.6875 L16.6875,11.3125 L19.3125,11.3125 L19.3125,16.6875 L24.6875,16.6875 L24.6875,19.3125 L19.3125,19.3125 L19.3125,24.6875 Z"
          id="ADD"
          fill="#EEF7FF"></path>
      </g>
    </svg>
  )
}
