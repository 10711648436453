import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { InputContainer, Label, InputComponent } from './password-components'

type Props = {
  label?: string
  placeholder?: string
  name?: string
  value?: string
  onChange?: (e: any) => void
  register?: any
  onKeyUp?: (e: any) => void
}

export default function Password({
  label,
  placeholder,
  name,
  value,
  onChange,
  register,
  onKeyUp,
}: Props) {
  const [showpassword, setShowPassword] = React.useState(false)
  const toggleShowPassword = () => {
    setShowPassword(!showpassword)
  }

  return (
    <InputContainer>
      {label && <Label>{label}</Label>}
      <InputComponent
        type={showpassword ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        ref={register}
        onKeyUp={onKeyUp}
      />
      <FontAwesomeIcon
        icon={showpassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
      />
    </InputContainer>
  )
}
