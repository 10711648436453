import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {
  Teams,
  Players,
  Products,
  Orders,
  OrderDetail,
  PlayerWelcome,
  PlayerOrders,
  Designer,
  Login,
  Users,
} from '@app/modules'
import { Layout } from '@app/components'
import { PrivateRoute } from './private-route'
import { PlayerRoute } from './player-route'

const InvalidPageStyles = {
  height: '100%',
  width: 'calc(100 - 72px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/orders',
    component: Orders,
    private: true,
  },
  {
    path: '/teams',
    component: Teams,
    private: true,
  },
  {
    path: '/players',
    component: Players,
    private: true,
  },
  {
    path: '/styles',
    component: Products,
    private: true,
  },
  {
    path: '/admins',
    component: Users,
    private: true,
  },
  {
    path: '/create-order',
    component: OrderDetail,
    private: true,
  },
  {
    path: '/orders/:orderId',
    component: OrderDetail,
    private: true,
  },
  {
    path: '/user',
    component: PlayerWelcome,
  },
  {
    path: '/user/orders',
    component: PlayerOrders,
    private: true,
    player: true,
  },
  {
    path: '/designer/:orderId',
    component: Designer,
    private: true,
    player: true,
  },
]

export default function Routes() {
  return (
    <Router>
      <Layout />
      <Switch>
        {routes.map((route, i) =>
          route.private && !route.player ? (
            <PrivateRoute exact path={route.path} key={i}>
              <route.component />
            </PrivateRoute>
          ) : route.player ? (
            <PlayerRoute exact path={route.path} key={i}>
              <route.component />
            </PlayerRoute>
          ) : (
            <Route exact path={route.path} key={i}>
              <route.component />
            </Route>
          ),
        )}
        <Route>
          <div style={InvalidPageStyles}>Page Not Found</div>
        </Route>
      </Switch>
    </Router>
  )
}
