import React, { useState, useEffect } from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { useLocation, getShortName } from '@app/utils'
import {
  useLazyGetOrders,
  useGetOrderCount,
  getStatusColors,
} from '@app/graphql'
import CountUp from 'react-countup'
import { useHistory } from 'react-router-dom'
import TooltipTrigger from 'react-popper-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import {
  Container,
  AgContainer,
  FilterHeader,
  StatsHeader,
  StatsBox,
  OrderStatsText,
  OrdersCount,
  Title,
  Header,
  Actions,
  FiltersContainer,
  PlayerCell,
  Avatar,
  PlayerContainer,
  PlayerName,
  PlayerDescription,
  OrderStatus,
  ProductContainer,
  ProductName,
  ImageContainer,
  NoData,
  NoDataText,
  ToolTipContainer,
  ToolTipArrow,
  ToolTipBody,
  StatusContainer,
  StatusRow,
  StatusLabel,
  StatusCount,
  FilterBox,
} from './orders-components'
import { EmptyScreen, SearchInput, Icon, OrderFilter } from '@app/components'

const orderStatusCounts = [
  { type: 'total', color: '#EFF7FF', code: 0, label: 'Total Designs' },
  // { type: 'approved', color: '#EFFFFB', code: 1, label: 'Created' },
  {
    type: 'approvedToday',
    color: '#fbebff',
    code: 3,
    label: 'Ready For Player ',
  },
  // { type: 'pending', color: '#fbf5e6', code: 4, label: 'Sent to Player' },
  { type: 'late', color: '#FFF7F7', code: 5, label: 'Player Approved' },
  { type: 'archive', color: '#b3cdec', code: 9, label: 'Archived' },
]

type filterProps = {
  status: string[]
  size: string[]
  season: string[]
  gloveType: string[]
  team: string[]
  player: string[]
}

export default function Orders() {
  var { navigate } = useLocation()
  const selectedTeam = localStorage.getItem('selectedTeam')
  const selectedPlayer = localStorage.getItem('selectedPlayer')
  let selectedTeamList: string[] = selectedTeam ? [selectedTeam] : []
  let selectedPlayerList: string[] = selectedPlayer ? [selectedPlayer] : []
  const [orders, setOrders] = useState<null | any[]>(null)
  const [gridApi, setGridApi] = useState<any>()
  const [searchText, setSearchText] = useState('')
  const [, setStatusText] = useState('')
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const { colors } = getStatusColors()
  const [applyFilter, setAppliedFilter] = useState<filterProps>({
    status: [],
    size: [],
    season: [],
    gloveType: [],
    team: selectedTeamList,
    player: selectedPlayerList,
  })

  const history = useHistory()
  const { loading, data, refetch } = useLazyGetOrders({
    page: 1,
    search: searchText,
    limit: 50,
    filter: applyFilter,
  })
  const { loading: countLoading, data: statusData } = useGetOrderCount()

  const columnDefs: any = [
    {
      headerName: 'Player',
      field: 'playerId',
      resizable: true,
      width: 250,
      cellRendererFramework: PlayerCustomCell,
      sortable: true,
    },
    {
      headerName: 'Season',
      width: 80,
      sortable: true,
      resizable: true,
      field: 'season',
    },
    {
      headerName: 'Style',
      sortable: true,
      resizable: true,
      width: 80,
      cellRendererFramework: ProductCustomCell,
      field: 'productType',
    },
    // {
    //   headerName: 'Team',
    //   sortable: true,
    //   resizable: true,
    //   field: 'teamName',
    // },
    {
      headerName: 'Size',
      width: 80,
      sortable: true,
      resizable: true,
      field: 'gloveSize',
    },
    /* {
      headerName: 'File',
      sortable: true,
      width: 150,
      resizable: false,
      field: 'fileName',
    }, */
    {
      headerName: 'Colorway',
      sortable: true,
      width: 200,
      resizable: true,
      field: 'productItems',
      cellRendererFramework: ColorWayCustomCell,
    },
    {
      headerName: 'Last Modified',
      sortable: true,
      width: 180,
      resizable: true,
      field: 'updatedAt',
      cellRendererFramework: LastModifiedCustomCell,
    },
    {
      headerName: 'Status',
      sortable: true,
      resizable: true,
      width: 180,
      cellRendererFramework: StatusCustomCell,
      field: 'orderStatus',
    },
  ]

  function PlayerCustomCell(ev: any) {
    if (ev && ev.data && ev.data.player && ev.data.player.name) {
      return (
        <PlayerCell>
          {ev.data.player.playerProfileLogo ? (
            <Avatar>
              <ImageContainer src={ev.data.player.playerProfileLogo} />
            </Avatar>
          ) : (
            <Avatar>{getShortName(ev.data.player.name)}</Avatar>
          )}

          <PlayerContainer>
            <PlayerName>{ev.data.player.name}</PlayerName>
            <PlayerDescription>{ev.data.teamName}</PlayerDescription>
          </PlayerContainer>
        </PlayerCell>
      )
    }
    return '--'
  }

  function getStatusCount(id: any) {
    if (!statusData) return {}
    let { OrderStatusList } = statusData.orderStatus
    let statusObj = _.find(OrderStatusList, { code: id })
    return statusObj
  }

  function ProductCustomCell(ev: any) {
    if (ev && ev.data && ev.data.product && ev.data.product.name) {
      return (
        <PlayerCell>
          {/* {ev.data.product.productProfileLogo ? (
            <ProductAvatar>
              <ImageContainer src={ev.data.product.productProfileLogo} />
            </ProductAvatar>
          ) : (
            <ProductAvatar>{getShortName(ev.data.product.name)}</ProductAvatar>
          )} */}

          <ProductContainer>
            <ProductName>{ev.data.product.name}</ProductName>
          </ProductContainer>
        </PlayerCell>
      )
    }
    return '--'
  }

  function LastModifiedCustomCell(ev: any) {
    return (
      <div className="modified-date">
        {ev.value && moment(ev.value).format('MMM Do, YYYY')}
      </div>
    )
  }

  function ColorWayCustomCell(ev: any) {
    let productItems = ev.value

    function getSelectedColor(data: any) {
      let obj = data.find((option: any) => !!option.selected) || {}
      return obj.publicName || obj.name || '--'
    }
    function pickColorByComponentId(id: any) {
      let data = productItems.find((item: any) => item.itemType === id)
      let sB6Data = productItems.find(
        (item: any) => item.itemType === id && item.itemId === 102,
      )
      if (!data) {
        return '--'
      }
      if (id === 'swoosh' || id === 'palm') {
        let primaryColor = getSelectedColor(data.primaryColors)
        if (primaryColor !== '--') {
          return primaryColor
        } else {
          return data.primaryColors && data.primaryColors[0]
            ? data.primaryColors[0].publicName || data.primaryColors[0].name
            : '--'
        }
      } else {
        if (ev.data.product.name !== 'SB6') {
          return getSelectedColor([...data.options, ...data.optionalColors])
        } else {
          return getSelectedColor([
            ...sB6Data.options,
            ...sB6Data.optionalColors,
          ])
        }
      }
    }
    let palmColor = pickColorByComponentId('palm')
    let swooshColor = pickColorByComponentId('swoosh')
    let bohColor = pickColorByComponentId('color')

    return <div>{`${bohColor} / ${palmColor} / ${swooshColor}`}</div>
  }

  function getStatusColor(id: any) {
    let statusObj = colors.find((color) => color.code === id)
    return statusObj
  }

  function StatusCustomCell(ev: any) {
    if (!ev.value) {
      return '--'
    }
    return (
      <OrderStatus status={getStatusColor(ev.data.statusId)}>
        {ev.value}
      </OrderStatus>
    )
  }

  function onGridReady(params: any) {
    setGridApi(params.api)

    window.addEventListener('resize', () => {
      handleResize(params.api)
    })
    params.api.sizeColumnsToFit()
  }

  function handleResize(api: any) {
    if (api && api.destroyCalled) return
    if (api) {
      api.sizeColumnsToFit()
    }
  }

  function onclickRow(ev: any) {
    if (ev && ev.data && ev.data.orderId) {
      navigate(`/orders/${ev.data.orderId}`)
    }
  }
  useEffect(() => {
    setDataLoading(true)
    refetch({ page: 1, search: searchText, limit: 50, filter: applyFilter })
    // eslint-disable-next-line
  }, [searchText, applyFilter])

  useEffect(() => {
    if (!loading && data && data.orders && data.orders.orders) {
      setDataLoading(false)
      setOrders(data.orders.orders)
    }
  }, [loading, data])

  function onAddEmpty(ev: any) {
    history.push('/create-order')
  }

  function colorSelection({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }: any) {
    return (
      <ToolTipContainer
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}>
        <ToolTipArrow
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
            className: 'tooltip-arrow',
          })}
        />
        <ToolTipBody className="tooltip-body">
          <StatusContainer>
            {statusData &&
              statusData.orderStatus &&
              statusData.orderStatus.OrderStatusList &&
              statusData.orderStatus.OrderStatusList.map(
                (color: any, colorIndex: any) => {
                  return (
                    <StatusRow
                      key={colorIndex}
                      onClick={() => setStatusText(color.name)}>
                      <StatusLabel>{color.name}</StatusLabel>
                      <StatusCount>{color.orderCount}</StatusCount>
                    </StatusRow>
                  )
                },
              )}
          </StatusContainer>
        </ToolTipBody>
      </ToolTipContainer>
    )
  }

  const applyFilters = (appliedFilters: filterProps) => {
    setAppliedFilter(appliedFilters)
  }

  const resetFilters = (appliedFilters: filterProps) => {
    if (JSON.stringify(applyFilter) !== JSON.stringify(appliedFilters)) {
      setAppliedFilter(appliedFilters)
    }
  }

  function changeColor({ getTriggerProps, triggerRef }: any) {
    return (
      <div
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
          style: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          },
        })}>
        <FontAwesomeIcon className="nike-icon icon-plus" icon={faPlayCircle} />
      </div>
    )
  }

  if (searchText === '' && !gridApi && (loading || countLoading)) {
    return <Icon name="nikeLoading" />
  }

  if (searchText === '' && !gridApi && orders && orders.length === 0) {
    return (
      <EmptyScreen
        emptyLogo="NoOrders"
        emptyText="Create Design"
        onChange={(e) => onAddEmpty(e)}
      />
    )
  }
  return (
    <Container>
      <FilterHeader>
        <FiltersContainer>
          <Header>
            <Title>All Designs</Title>
          </Header>
          <Actions>
            <FilterBox>
              <OrderFilter
                resetFilters={resetFilters}
                applyFilters={applyFilters}
                selectedTeamArray={selectedTeamList}
                selectedPlayerArray={selectedPlayerList}
              />
            </FilterBox>
            <SearchInput
              placeholder="Search"
              onChange={(ev) => setSearchText(ev.target.value)}
            />
          </Actions>
        </FiltersContainer>
      </FilterHeader>

      {orderStatusCounts.length ? (
        <StatsHeader>
          {orderStatusCounts &&
            orderStatusCounts.map((orderStatus: any) => {
              return (
                <StatsBox
                  key={orderStatus.type}
                  color={getStatusColor(orderStatus.code)}
                  onClick={() =>
                    setSearchText(
                      orderStatus.label === 'Total Designs'
                        ? ''
                        : orderStatus.label.trim(),
                    )
                  }>
                  {data && data.orders && orderStatus.type === 'total' && (
                    <TooltipTrigger
                      placement="right"
                      trigger="click"
                      tooltip={(e) => colorSelection(e)}>
                      {(e) => changeColor(e)}
                    </TooltipTrigger>
                    // <StatusPop>123</StatusPop>
                  )}
                  <OrderStatsText>{orderStatus.label}</OrderStatsText>
                  <OrdersCount>
                    {data && data.orders && orderStatus.type === 'total' ? (
                      <CountUp duration={2} end={data.orders.count} />
                    ) : (
                      getStatusCount(orderStatus.code) && (
                        <CountUp
                          duration={2}
                          end={getStatusCount(orderStatus.code).orderCount}
                        />
                      )
                    )}
                  </OrdersCount>
                </StatsBox>
              )
            })}
        </StatsHeader>
      ) : null}

      <AgContainer className="ag-theme-material">
        {loading || dataLoading ? (
          <Icon name="nikeLoading" />
        ) : orders && orders.length > 0 ? (
          <AgGridReact
            onGridReady={onGridReady}
            rowHeight={65}
            headerHeight={36}
            animateRows={true}
            onRowClicked={onclickRow}
            suppressCellSelection={true}
            columnDefs={columnDefs}
            rowData={orders || []}></AgGridReact>
        ) : (
          <NoData>
            <NoDataText>No Designs Found</NoDataText>
          </NoData>
        )}
      </AgContainer>
    </Container>
  )
}
