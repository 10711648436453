import React from 'react'

export default function HomeIcon() {
  return (
    <svg width="26px" height="27px" viewBox="0 0 26 27" version="1.1">
      <title>edit</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="edit" fill="#EDF4FA">
          <path
            d="M21.322,26.0533755 L5.86197757e-14,26.0533755 L5.86197757e-14,4.35937555 L10.381,4.35937555 C11.056,4.35937555 11.602,4.90537555 11.602,5.58037555 C11.602,6.25637555 11.056,6.80137555 10.381,6.80137555 L2.444,6.80137555 L2.444,23.6103755 L18.878,23.6103755 L18.878,15.2063755 C18.878,14.5313755 19.425,13.9853755 20.099,13.9853755 C20.775,13.9853755 21.322,14.5313755 21.322,15.2063755"
            id="Fill-130"></path>
          <path
            d="M15.122,14.0473755 L11.82,10.7453755 L21.985,0.579375546 C22.758,-0.191624454 24.019,-0.194624454 24.794,0.579375546 L25.288,1.07337555 C25.663,1.44937555 25.87,1.94837555 25.87,2.47937555 C25.869,3.00837555 25.663,3.50737555 25.288,3.88137555"
            id="Fill-131"></path>
          <path
            d="M10.988,15.8173755 C10.864,15.8173755 10.74,15.8113755 10.616,15.7973755 C10.28,15.7643755 10.035,15.4663755 10.069,15.1303755 C10.102,14.7953755 10.392,14.5473755 10.737,14.5833755 C12.623,14.7653755 14.67,12.7713755 14.691,12.7503755 C14.927,12.5133755 15.315,12.5113755 15.554,12.7533755 C15.793,12.9913755 15.793,13.3773755 15.554,13.6153755 C15.46,13.7103755 13.324,15.8173755 10.988,15.8173755"
            id="Fill-132"></path>
          <path
            d="M10.675,15.8023755 C10.365,15.8023755 10.1,15.5673755 10.069,15.2523755 C9.821,12.7763755 12.153,10.4123755 12.251,10.3143755 C12.49,10.0743755 12.875,10.0743755 13.114,10.3143755 C13.353,10.5513755 13.354,10.9383755 13.115,11.1773755 C13.096,11.1983755 11.094,13.2343755 11.283,15.1303755 C11.318,15.4653755 11.072,15.7653755 10.737,15.7983755 C10.717,15.8013755 10.696,15.8023755 10.675,15.8023755"
            id="Fill-133"></path>
        </g>
      </g>
    </svg>
  )
}
