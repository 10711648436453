import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export function PlayerRoute({ children, ...rest }: any) {
  const playerData = sessionStorage.getItem('playerData')
  const player: any = playerData ? JSON.parse(playerData) : null
  return (
    <Route
      {...rest}
      render={({ location }) =>
        player ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/user',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
