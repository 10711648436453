import { asset1, asset2, asset3, asset4 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, position: 'Right' },
  { src: asset2, x: -30, y: 18 },
  { src: asset3, x: 650, y: 0, position: 'Left' },
  { src: asset4, x: 680, y: 18 },
]

const data = {
  assets: assets,
}

export default data
