import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  useCreatePlayer,
  useGetTeamsPlayer,
  useGetSizes,
  useGetPositions,
  useLazyGetPlayers,
  useUpdatePlayer,
  useCreatePlayerLogo,
} from '@app/graphql'
import { Player as TPlayer, PlayerLogo as TLogo } from '@app/types'

import {
  Button,
  Dialog,
  Input,
  Select,
  PlayerList,
  PlayerDetail,
  Radio,
  Icon,
  EmptyScreen,
} from '@app/components'

import {
  Container,
  ListContainer,
  DetailContainer,
  ListHeader,
  ListTitle,
  PlayerListContainer,
  InputContainer,
  DialogFooter,
  InputWraper,
  IconContainer,
} from './players-components'
export default function Player() {
  const [createPlayer] = useCreatePlayer()
  const [createPlayerLogo] = useCreatePlayerLogo()
  const [updatePlayer] = useUpdatePlayer()

  const { register, handleSubmit, setValue } = useForm()
  const [showDialog, setShowDialog] = useState(false)
  const [fetching] = useState(false)
  const [currentPage] = useState(1)
  const [playerBrand, setPlayerBrand] = useState('Nike')
  const [playerList, setPlayerList] = useState([])
  const [accessCodeError, setAccessCodeError] = useState<string>('')

  const { loading: dataLoading, data: teamsList } = useGetTeamsPlayer()
  const sizes = useGetSizes()
  const positions = useGetPositions()
  const { loading, data } = useLazyGetPlayers()
  const playerObj = {
    _id: '',
    playerCode: '',
    name: '',
    teamId: '',
    size: '',
    number: 0,
    playerProfileLogo: '',
    brand: '',
    description: '',
    logo: [],
    position: '',
    accessCode: '',
  }
  const [selectedPlayer, setSelectedPlayer] = useState<TPlayer>(playerObj)

  useEffect(() => {
    register({ name: 'size' })
    register({ name: 'teamId' })
    register({ name: 'position' })
    if (currentPage === 1 && data && data.players && data.players.players) {
      setPlayerList(data.players.players)
    }
    // eslint-disable-next-line
  }, [data, register])
  function createNewPlayer(data: any) {
    data['number'] = parseInt(data['number'])
    createPlayer({ variables: { ...data } }).then(function (res: any) {
      const {
        data: { createPlayer },
      } = res

      setTimeout(() => setSelectedPlayer(createPlayer), 0)
    })
    setShowDialog(false)
  }

  function handleChange(name: any, action: any) {
    setValue(name, action.value)
  }
  function handleRadioChange(name: any, value: any) {
    setPlayerBrand(value.target.value)
  }

  function updateFormPlayer(params: any) {
    console.log(params)
    updatePlayer({ variables: { ...params } }).then((res: any) => {
      console.log('updateFormPlayer::', res)
      const {
        createPlayer: { status = false, message = '', accessCode = '' },
      } = res.data
      if (status) {
        setAccessCodeError('')
      } else {
        setAccessCodeError(message)
        setTimeout(() => {
          setSelectedPlayer({ ...selectedPlayer, accessCode: accessCode })
        }, 50)
      }
    })
  }

  function addLogo() {
    const { _id, logo } = selectedPlayer
    const newLogo = {
      playerId: _id,
      name: '',
      logo: '',
      aiName: '',
      aiLogo: '',
      status: true,
    }

    createPlayerLogo({ variables: { ...newLogo } }).then((res: any) => {
      const {
        data: { createPlayerLogo },
      } = res
      setSelectedPlayer({
        ...selectedPlayer,
        logo: [...logo, createPlayerLogo],
      })
    })
  }

  function updateLogo(updatedLogo: TLogo, name?: String) {
    const { logo } = selectedPlayer
    if (name && name !== '') {
      updatedLogo['aiLogo'] = updatedLogo.logo
      updatedLogo['aiName'] = name
      delete updatedLogo['logo']
    }
    const updatedLogos = logo.map((logo: TLogo) => {
      if (logo._id === updatedLogo._id) {
        createPlayerLogo({ variables: { ...updatedLogo } }).then((res: any) => {
          console.log('updateLogo::', res)
        })
        return updatedLogo
      } else {
        return logo
      }
    })

    setSelectedPlayer({ ...selectedPlayer, logo: updatedLogos })
  }

  if (dataLoading || loading) {
    return <Icon name="nikeLoading" />
  }
  if (!teamsList || !data) {
    return <div>Something is went wrong ...</div>
  }
  const { teams } = teamsList.teams
  const { players } = data.players

  //for selecting the first player by default
  if (playerList.length && !selectedPlayer._id) {
    setTimeout(() => setSelectedPlayer(playerList[0]), 0)
  }

  function handleScroll(event: any) {
    var node = event.target
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight
    if (bottom && !fetching) {
      // getPlayers()
      console.log('BOTTOM REACHED:', bottom)
    }
  }
  const paneDidMount = (node: any) => {
    if (node) {
      node.addEventListener('scroll', handleScroll)
    }
  }

  function updateSelectedPlayer(data: any) {
    setAccessCodeError('')
    setTimeout(() => {
      setSelectedPlayer(data)
    }, 10)
  }
  return (
    <>
      {players && players.length === 0 ? (
        <EmptyScreen
          emptyLogo="NoPlayers"
          emptyText="Add Player"
          onChange={(e) => setShowDialog(true)}
        />
      ) : (
        <Container>
          <ListContainer>
            <ListHeader>
              <ListTitle>Players</ListTitle>
              <Button width="150px" onClick={() => setShowDialog(true)}>
                Add Player
              </Button>
            </ListHeader>

            <PlayerListContainer ref={paneDidMount}>
              <PlayerList
                players={players}
                selectedPlayer={selectedPlayer}
                onSelect={updateSelectedPlayer}
              />
            </PlayerListContainer>
          </ListContainer>

          <DetailContainer>
            {players && selectedPlayer && selectedPlayer._id && (
              <PlayerDetail
                logos={selectedPlayer.logo}
                player={selectedPlayer}
                updatePlayer={updateFormPlayer}
                addLogo={addLogo}
                updateLogo={updateLogo}
                errorMessage={accessCodeError}
              />
            )}
          </DetailContainer>
        </Container>
      )}
      {showDialog && (
        <Dialog title="Add Player" onClose={() => setShowDialog(false)}>
          <Dialog.Body>
            <form>
              <Input
                label="Player Name"
                placeholder="Enter Player Name"
                name="name"
                register={register({ required: true })}
              />

              <InputContainer>
                <InputWraper>
                  <Select
                    label="Team"
                    options={teams}
                    placeholder="Select Team"
                    name="teamId"
                    onChange={(val: any) => handleChange('teamId', val)}
                    register={register({ required: true })}
                  />
                </InputWraper>
                <InputWraper>
                  <Select
                    label="Position"
                    options={positions}
                    placeholder="Select Position"
                    name="position"
                    onChange={(val: any) => handleChange('position', val)}
                    register={register({ required: true })}
                  />
                </InputWraper>
              </InputContainer>
              <InputContainer>
                <InputWraper>
                  <Input
                    label="Number"
                    placeholder="Enter Number"
                    name="number"
                    type="number"
                    register={register({ required: true, min: 1, max: 99 })}
                  />
                </InputWraper>
                <InputWraper>
                  <Select
                    label="Size"
                    options={sizes}
                    placeholder="Select Size"
                    name="size"
                    onChange={(val: any) => handleChange('size', val)}
                    register={register({ required: true })}
                  />
                </InputWraper>
              </InputContainer>
              <Radio
                label="Brand"
                name="brand"
                value="Nike"
                checked={playerBrand === 'Nike'}
                onChange={(val: any) => handleRadioChange('brand', val)}
                register={register({ required: true })}>
                <IconContainer>
                  <Icon name="nike" />
                </IconContainer>
                Nike
              </Radio>
              <Radio
                label="Brand"
                name="brand"
                value="Jordan"
                checked={playerBrand === 'Jordan'}
                onChange={(val: any) => handleRadioChange('brand', val)}
                register={register({ required: true })}>
                <IconContainer>
                  <Icon name="jordan" />
                </IconContainer>
                Jordan
              </Radio>
            </form>
          </Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(createNewPlayer)}>
                Add Player
              </Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  )
}
