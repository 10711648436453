import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { GlobalFonts, GlobalStyles } from '@app/components'
import { client, AuthProvider } from '@app/providers'
import { Routes } from '@app/routes'

function App() {
  return (
    <ApolloProvider client={client}>
      <GlobalFonts />
      <GlobalStyles />
      <AuthProvider>
        <div className="nike-font">Nike Font</div>
        <Routes />
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
