import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const GET_PRODUCTS = gql`
  query {
    products(limit: 50, page: 1, sort: ASC, search: "") {
      count
      pages
      page
      products {
        _id
        name
        type
        franchise
        productProfileLogo
        year
        status
        logo {
          _id
          name
          logo
          ProductId
          status
          size
        }
      }
    }
  }
`

export function useGetProducts() {
  return useQuery(GET_PRODUCTS)
}

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String
    $type: String
    $franchise: String
    $year: Int
    $productProfileLogo: String
  ) {
    createProduct(
      name: $name
      type: $type
      franchise: $franchise
      year: $year
      productProfileLogo: $productProfileLogo
    ) {
      _id
      name
      type
      franchise
      year
      status
      productProfileLogo
      logo {
        name
        _id
        status
        ProductId
      }
    }
  }
`

export function useCreateProduct() {
  return useMutation(CREATE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }],
    // update(cache, { data: { createProductLogo } }) {
    //   const { products }: any = cache.readQuery({ query: GET_PRODUCTS })
    //   const productIndex = products.products.findIndex(
    //     (product: any) => product._id === createProductLogo._id,
    //   )
    //   if (productIndex > -1) {
    //     const selectedProduct = products.products[productIndex]
    //     const logoIndex = selectedProduct.logo.findIndex(
    //       (logo: any) => logo._id === createProductLogo._id,
    //     )

    //     if (logoIndex === -1) {
    //       selectedProduct.logo.push(createProductLogo)
    //     }

    //     cache.writeQuery({
    //       query: GET_PRODUCTS,
    //       data: { products: { ...products } },
    //     })
    //   }
    // },
  })
}

const UPDATE_PRODUCT = gql`
  mutation createProduct(
    $_id: ID
    $name: String
    $type: String
    $franchise: String
    $year: Int
    $status: Boolean
    $productProfileLogo: String
  ) {
    createProduct(
      _id: $_id
      name: $name
      type: $type
      franchise: $franchise
      year: $year
      status: $status
      productProfileLogo: $productProfileLogo
    ) {
      _id
      name
      type
      franchise
      year
      status
      productProfileLogo
      logo {
        name
        _id
        status
        ProductId
      }
    }
  }
`

export function useUpdateProduct() {
  return useMutation(UPDATE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }],
    // update(cache, { data: { createProductLogo } }) {
    //   const { products }: any = cache.readQuery({ query: GET_PRODUCTS })
    //   const productIndex = products.products.findIndex(
    //     (product: any) => product._id === createProductLogo._id,
    //   )
    //   if (productIndex > -1) {
    //     const selectedProduct = products.products[productIndex]
    //     const logoIndex = selectedProduct.logo.findIndex(
    //       (logo: any) => logo._id === createProductLogo._id,
    //     )

    //     if (logoIndex === -1) {
    //       selectedProduct.logo.push(createProductLogo)
    //     }

    //     cache.writeQuery({
    //       query: GET_PRODUCTS,
    //       data: { products: { ...products } },
    //     })
    //   }
    // },
  })
}

const dropdowns = {
  productTypes: [{ value: 'Football Gloves', label: 'Football Gloves' }],
  franchises: [{ value: 'NFL', label: 'NFL' }],
  years: [
    { value: 2018, label: '2018' },
    { value: 2019, label: '2019' },
    { value: 2020, label: '2020' },
  ],
}

export function getProductsDropdown() {
  return dropdowns
}

const CREATE_PRODUCT_LOGO = gql`
  mutation createProductLogo(
    $_id: ID
    $ProductId: String
    $name: String
    $logo: String
    $size: String
    $order: Int
    $status: Boolean
  ) {
    createProductLogo(
      _id: $_id
      ProductId: $ProductId
      name: $name
      logo: $logo
      size: $size
      order: $order
      status: $status
    ) {
      _id
      ProductId
      name
      logo
      size
      order
      status
    }
  }
`

export function useCreateProductLogo() {
  return useMutation(CREATE_PRODUCT_LOGO, {
    refetchQueries: [{ query: GET_PRODUCTS }],
    // update(cache, { data: { createProductLogo } }) {
    //   const { products }: any = cache.readQuery({ query: GET_PRODUCTS })
    //   const productIndex = products.products.findIndex(
    //     (product: any) => product._id === createProductLogo._id,
    //   )
    //   if (productIndex > -1) {
    //     const selectedProduct = products.products[productIndex]
    //     const logoIndex = selectedProduct.logo.findIndex(
    //       (logo: any) => logo._id === createProductLogo._id,
    //     )

    //     if (logoIndex === -1) {
    //       selectedProduct.logo.push(createProductLogo)
    //     }

    //     cache.writeQuery({
    //       query: GET_PRODUCTS,
    //       data: { products: { ...products } },
    //     })
    //   }
    // },
  })
}
