import styled from 'styled-components'
import {
  boldFont,
  primaryColor,
  primaryHoverColor,
  primaryActiveColor,
  secondaryColor,
  secondaryHoverColor,
  secondaryActiveColor,
  whiteColor,
  whiteHoverColor,
  whiteActiveColor,
} from '@app/styles'

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'text' | 'outline'
  width?: string
  background?: string
  hover?: string
  active?: string
  size?: 'medium' | 'large'
}

export const ButtonContainer = styled.button`
    min-width: 120px;
    padding: 8px 32px;
    border: 0 none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    ${boldFont}
    ${({ width }: ButtonProps) => {
      if (width) {
        return `width: ${width};`
      }
    }}
    ${({ size }: ButtonProps) => {
      if (size === 'large') {
        return `height: 48px;`
      } else {
        return `height: 40px;`
      }
    }}
    ${({ variant }: ButtonProps) => {
      if (variant === 'primary') {
        return `
            background-color: ${primaryColor};
            color: ${whiteColor};
            &:hover {
                background-color: ${primaryHoverColor};
            }
            &:active {
                background-color: ${primaryActiveColor};
            }
        `
      } else if (variant === 'secondary') {
        return `
              background-color: ${secondaryColor};
              color: ${whiteColor};
              &:hover {
                  background-color: ${secondaryHoverColor};
              }
              &:active {
                  background-color: ${secondaryActiveColor};
              }
          `
      } else if (variant === 'text') {
        return `
            background-color: ${whiteColor};
            color: rgba(36, 41, 50, 0.5);
            &:hover {
                background-color: ${whiteHoverColor};
            }
            &:active {
                background-color: ${whiteActiveColor};
            }
        `
      } else if (variant === 'outline') {
        return `
            background-color: ${whiteColor};
            border: 2px solid #3C3C3C;
            &:hover {
                background-color: ${whiteHoverColor};
            }
            &:active {
                background-color: ${whiteActiveColor};
            }
        `
      }
    }}
    ${({ background }: ButtonProps) => {
      if (background) {
        return `background-color: ${background};`
      }
    }}
    ${({ hover }: ButtonProps) => {
      if (hover) {
        return `&:hover{
          background-color: ${hover};
        }`
      }
    }}
    ${({ active }: ButtonProps) => {
      if (active) {
        return `&:active{
          background-color: ${active};
        }`
      }
    }}
`
