import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Team as TTeam, Logo as TLogo, Color as TColor } from '@app/types'
import {
  useGetTeams,
  useCreateTeam,
  useUpdateTeamName,
  useCreateTeamLogo,
  useCreateTeamColor,
} from '@app/graphql'
import {
  Button,
  Dialog,
  Input,
  TeamList,
  TeamDetail,
  EmptyScreen,
  Icon,
} from '@app/components'
import {
  Container,
  ListContainer,
  DetailContainer,
  ListHeader,
  ListTitle,
  DialogFooter,
} from './teams-components'


export default function Team() {
  const { loading, data, error } = useGetTeams()
  const [createTeam] = useCreateTeam()
  const [updateTeamName] = useUpdateTeamName()
  const [createTeamLogo] = useCreateTeamLogo()
  const [createTeamColor] = useCreateTeamColor()
  

  const { register, handleSubmit } = useForm()
  const [showDialog, setShowDialog] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<TTeam>({
    _id: '',
    name: '',
    logo: [],
    color: [],
  })

  function createNewTeam(data: any) {
    createTeam({ variables: { ...data } }).then(function (res: any) {
      const {
        data: { createTeam },
      } = res

      setTimeout(() => setSelectedTeam(createTeam), 0)
    })
    setShowDialog(false)
  }

  function updateName(name: string) {
    if (name) {
      const { _id } = selectedTeam

      updateTeamName({ variables: { _id: _id, name: name } })
    }
  }

  function addLogo() {
    const { _id, logo } = selectedTeam
    const newLogo = {
      teamId: _id,
      name: '',
      logo: '',
      aiName: '',
      aiLogo: '',
      status: true,
    }

    createTeamLogo({ variables: newLogo }).then((res: any) => {
      const {
        data: { createTeamLogo },
      } = res
      setSelectedTeam({
        ...selectedTeam,
        logo: [...logo, createTeamLogo],
      })
    })
  }

  function updateLogo(updatedLogo: TLogo, name?: String) {
    const { logo } = selectedTeam
    if (name && name !== '') {
      updatedLogo['aiLogo'] = updatedLogo.logo
      updatedLogo['aiName'] = name
      delete updatedLogo['logo']
    }
    const updatedLogos = logo.map((logo: TLogo) => {
      if (logo._id === updatedLogo._id) {
        createTeamLogo({ variables: updatedLogo })
        return updatedLogo
      } else {
        return logo
      }
    })

    setSelectedTeam({ ...selectedTeam, logo: updatedLogos })
  }

  function addColor() {
    const { _id, color } = selectedTeam
    const newColor = {
      teamId: _id,
      name: 'Jet Black',
      code: '#222222',
      status: true,
    }

    createTeamColor({ variables: { ...newColor } }).then((res: any) => {
      const {
        data: { createTeamColor },
      } = res

      setSelectedTeam({
        ...selectedTeam,
        color: [...color, createTeamColor],
      })
    })
  }

  function updateColor(updatedColor: TColor) {
    const { color } = selectedTeam

    const updatedColors = color.map((color: TColor) => {
      if (color._id === updatedColor._id) {
        createTeamColor({ variables: { ...updatedColor } })
        return updatedColor
      } else {
        return color
      }
    })

    setSelectedTeam({ ...selectedTeam, color: updatedColors })
  }

  if (loading) {
    return <Icon name="nikeLoading" />
  }
  if (error) {
    return <div>Teams Error</div>
  }
  const { teams } = data.teams

  //for selecting the first team by default
  if (teams.length && !selectedTeam._id) {
    setTimeout(() => setSelectedTeam(teams[0]), 0)
  }

  function submitTeam(e: any) {
    if (e.keyCode === 13 && e.target.value.trim() !== '') {
      createNewTeam({ name: e.target.value })
    }
  }

  return (
    <>
      {teams.length === 0 ? (
        <EmptyScreen
          emptyLogo="NoTeams"
          emptyText="Create Team"
          onChange={(e) => setShowDialog(true)}
        />
      ) : (
        <Container>
          <ListContainer>
            <ListHeader>
              <ListTitle>Teams</ListTitle>
              <Button width="150px" onClick={() => setShowDialog(true)}>
                Add Team
              </Button>
            </ListHeader>
            <TeamList
              teams={teams}
              selectedTeam={selectedTeam}
              onSelect={setSelectedTeam}
            />
          </ListContainer>

          <DetailContainer>
            {selectedTeam && selectedTeam._id && (
              <TeamDetail
                name={selectedTeam.name}
                logos={selectedTeam.logo}
                colors={selectedTeam.color}
                updateName={updateName}
                addLogo={addLogo}
                updateLogo={updateLogo}
                addColor={addColor}
                updateColor={updateColor}
                teamId={selectedTeam._id}
              />
            )}
          </DetailContainer>
        </Container>
      )}
      {showDialog && (
        <Dialog title="Add Team" onClose={() => setShowDialog(false)}>
          <Dialog.Body>
            {/* <form> */}
            <Input
              label="Team Name"
              placeholder="Enter Team Name"
              name="name"
              onKeyUp={(e) => submitTeam(e)}
              register={register({ required: true })}
            />
            {/* </form> */}
          </Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(createNewTeam)}>Add Team</Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  )
}
