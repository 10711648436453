import React from 'react'

export default function NikeIcon() {
  return (
    <div className="indicator">
      <svg width="60px" height="30px" viewBox="0 0 60 30" version="1.1">
        <g
          id="Artboard"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g
            id="Nike_logo"
            transform="translate(4.000000, 6.000000)"
            fill="#282A34"
            fillRule="nonzero">
            <path
              d="M5.84629659,18.9922768 C4.26711533,18.9291732 2.9751071,18.4945201 1.96486375,17.6872293 C1.77206302,17.5330063 1.31264026,17.0706093 1.15850784,16.8755866 C0.748840098,16.3574299 0.470320116,15.8531452 0.284549992,15.2936448 C-0.287091991,13.571352 0.00711164076,11.3113186 1.12605891,8.83096673 C2.08411357,6.7074765 3.56243274,4.60139416 6.14158185,1.68448508 C6.52150474,1.25527186 7.65289077,0 7.66019178,0 C7.66289585,0 7.60124289,0.107439303 7.52363586,0.238270455 C6.85302464,1.36815113 6.2792194,2.6990385 5.96662804,3.85122302 C5.46448085,5.69999503 5.52505218,7.28656074 6.14401552,8.51680876 C6.57098936,9.36435527 7.30298314,10.0984785 8.12610433,10.5042999 C9.56710723,11.2144873 11.6768285,11.2732389 14.2532735,10.6762028 C14.430661,10.634859 23.2205353,8.28778625 33.7864477,5.46036459 C44.3523602,2.63267093 52.998648,0.320957918 53,0.322861906 C53.0029745,0.32530989 28.4523845,10.8924414 15.7077968,16.3745658 C13.6894734,17.2425122 13.1497395,17.4617428 12.2008787,17.7968446 C9.77532119,18.653639 7.60259492,19.0624524 5.84629659,18.9922768 Z"
              id="front"></path>
            <path
              d="M5.84629659,18.9922768 C4.26711533,18.9291732 2.9751071,18.4945201 1.96486375,17.6872293 C1.77206302,17.5330063 1.31264026,17.0706093 1.15850784,16.8755866 C0.748840098,16.3574299 0.470320116,15.8531452 0.284549992,15.2936448 C-0.287091991,13.571352 0.00711164076,11.3113186 1.12605891,8.83096673 C2.08411357,6.7074765 3.56243274,4.60139416 6.14158185,1.68448508 C6.52150474,1.25527186 7.65289077,0 7.66019178,0 C7.66289585,0 7.60124289,0.107439303 7.52363586,0.238270455 C6.85302464,1.36815113 6.2792194,2.6990385 5.96662804,3.85122302 C5.46448085,5.69999503 5.52505218,7.28656074 6.14401552,8.51680876 C6.57098936,9.36435527 7.30298314,10.0984785 8.12610433,10.5042999 C9.56710723,11.2144873 11.6768285,11.2732389 14.2532735,10.6762028 C14.430661,10.634859 23.2205353,8.28778625 33.7864477,5.46036459 C44.3523602,2.63267093 52.998648,0.320957918 53,0.322861906 C53.0029745,0.32530989 28.4523845,10.8924414 15.7077968,16.3745658 C13.6894734,17.2425122 13.1497395,17.4617428 12.2008787,17.7968446 C9.77532119,18.653639 7.60259492,19.0624524 5.84629659,18.9922768 Z"
              id="back"></path>
          </g>
        </g>
      </svg>
    </div>
  )
}
