import styled from 'styled-components'
import {
  boldFont,
  primaryColor,
  primaryHoverColor,
  primaryActiveColor,
} from '@app/styles'

export const Container = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0px;
  -webkit-flex: 0px;
  -ms-flex: 0px;
  flex: 0px;
  overflow: auto;
`

export const LogoSection = styled.div`
  min-height: 120px;
  padding: 30px 30px 0 30px;
`

export const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  max-width: 640px;
  /* padding: 4px 30px 0 30px; */
`

export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 28px 30px 16px 30px;
  background-color: #fff;
`

export const ProductCardInfo = styled.div`
  display: flex;
`

export const ProductName = styled.div`
  margin-left: 12px;
`

export const ProductActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-delete {
    cursor: pointer;
  }
`

export const ProductType = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 200px;
  margin: 0 15px 0 0;
`

export const ProductFranchise = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 200px;
  margin-right: 15px;
`

export const ProductField = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  div {
    margin-bottom: 5px;
  }
  select {
    text-transform: none;
    display: flex;
    width: 100%;
    height: 40px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: #7a8894;
  margin-bottom: 10px;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .select-container {
    width: 100%;
  }
`

export const ProductYear = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 120px;
  margin-right: 15px;
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${boldFont}
`

export const Title = styled.div`
  font-size: 1.2rem;
`

export const Link = styled.a`
  margin-left: 4px;
  color: ${primaryColor};
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 10px;
  padding-right: 14px;
  margin-top: 4px;
  &:hover {
    color: ${primaryHoverColor};
    cursor: pointer;
    background: #e8eaed;
  }
  &:active {
    color: ${primaryActiveColor};
  }
`

export const LogosList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
`

export const LogoListItem = styled.div`
  width: 50%;
  padding: 8px;
`

export const LogoCardBorder = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid #d8dcdd;
`

export const ColorsList = styled.div`
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;
`

export const ColorListItem = styled.div`
  width: 25%;
  padding: 8px;
`
export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 16px;
  }
`

export const select = styled.div`
  text-transform: none;
  display: flex;
  width: 100%;
  height: 40px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
`
export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 300px;
`
export const ToolTipArrow = styled.div`
  display: flex;
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 1px;
  width: 1rem;
  position: absolute;
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
    border-color: transparent transparent silver transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
    position: absolute;
    top: -1px;
  }
  &::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
    border-color: transparent transparent white transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
  }
`
export const ToolTipBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border: 1px solid #e3e8ef;
  border-radius: 4px;
  box-shadow: 0px 0px 1px;
  .label {
    margin-bottom: 12px;
  }
`

export const ContentSection = styled.div`
  overflow-y: auto;
`
