import React, { useState, useEffect } from 'react'
import {
  ProductListContainer,
  ProductListItem,
  Logo,
  PreviewImg,
  ProductName,
  NoData,
} from './product-list-components'

type Props = {
  onSelect: (e: any) => void
  products: any
  product: any
}

export default function ProductList({ onSelect, products, product }: Props) {
  const [selectedProduct, setSelectedProduct] = useState({
    _id: product ? product._id : null,
  })

  useEffect(() => {
    setSelectedProduct(product)
  }, [product])

  // useEffect(() => {
  //   if (products.length) {
  //     selectProduct(products[0])
  //   }
  // }, [products])

  function selectProduct(product: any) {
    setSelectedProduct(product)
    onSelect(product)
  }

  return (
    <ProductListContainer>
      {products.length ? (
        products.map((product: any, index: number) => {
          return (
            <ProductListItem
              key={index}
              active={selectedProduct._id === product._id}
              onClick={() => selectProduct(product)}>
              <Logo>
                <PreviewImg src={product.productProfileLogo}></PreviewImg>
              </Logo>
              <ProductName>{product.name}</ProductName>
            </ProductListItem>
          )
        })
      ) : (
        <NoData className="no-data">No Products Added</NoData>
      )}
    </ProductListContainer>
  )
}
