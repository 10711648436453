/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import {
  InlineEdit,
  LogoCard,
  ColorCard,
  Dialog,
  Button,
} from '@app/components'
import { getLogosCount, useLocation } from '@app/utils'
import {
  Container,
  Header,
  LogoSection,
  ColorSection,
  SectionHeader,
  LogosList,
  ColorsList,
  Title,
  Link,
  LogoListItem,
  ColorListItem,
  LogoCardBorder,
  ContentSection,
  DialogFooter,
  LogoInfo,
  ShowAllDesigns,
} from './team-detail-components'

type Props = {
  name: string
  teamId: string
  logos: any[]
  colors: any[]
  updateName: (e: any) => void
  addLogo: (e: any) => void
  updateLogo: (e: any, name?: String) => void
  addColor: (e: any) => void
  updateColor: (e: any) => void
}

export default function TeamDetail({
  name,
  logos,
  colors,
  updateName,
  addLogo,
  updateLogo,
  addColor,
  updateColor,
  teamId,
}: Props) {
  const { navigate } = useLocation()
  const [teamName, setTeamName] = useState(name)
  const [showDelete, setShowDelete] = useState(false)
  const [logoToDelete, setLogoToDelete] = useState({})
  const [showDeleteColor, setShowDeleteColor] = useState(false)
  const [colorToDelete, setColorToDelete] = useState({})
  useEffect(() => {
    setTeamName(name)
  }, [name, teamId])
  function deleteLogo(logo: any) {
    setShowDelete(true)
    setLogoToDelete(logo)
  }
  function deleteColor(color: any) {
    setShowDeleteColor(true)
    setColorToDelete(color)
  }
  function deleteUpdateLogo(logo: any) {
    updateLogo({ ...logo, status: false })
    setShowDelete(false)
  }
  function deleteUpdateColor(color: any) {
    updateColor({ ...color, status: false })
    setShowDeleteColor(false)
  }
  function showAllDesigns() {
    localStorage.setItem('selectedTeam', teamId)
    navigate(`/orders`)
  }
  return (
    <Container>
      {showDelete && (
        <Dialog title="Remove Logo" onClose={() => setShowDelete(false)}>
          <Dialog.Body>Are you sure, you want to remove this logo?</Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button onClick={() => deleteUpdateLogo(logoToDelete)}>
                Remove Logo
              </Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}

      {showDeleteColor && (
        <Dialog title="Remove Color" onClose={() => setShowDeleteColor(false)}>
          <Dialog.Body>
            Are you sure, you want to remove this Color?
          </Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDeleteColor(false)}>
                Cancel
              </Button>
              <Button onClick={() => deleteUpdateColor(colorToDelete)}>
                Remove Color
              </Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
      <Header>
        <InlineEdit
          size="large"
          label="TEAM"
          placeholder="Enter Team Name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          onBlur={(e) => updateName(teamName)}
        />
        <ShowAllDesigns>
          <a onClick={showAllDesigns}>Show All Designs {'>'}</a>
        </ShowAllDesigns>
      </Header>

      <ContentSection>
        <LogoSection>
          <SectionHeader>
            <Title>Logos</Title>
            {logos && getLogosCount(logos) && (
              <Link onClick={addLogo}>+ Add</Link>
            )}
          </SectionHeader>
          <LogoInfo>(Max file size is 5 mb)</LogoInfo>
          <LogosList>
            {logos.map((logo: any, index: number) => {
              // console.log(logo)
              if (!logo.status || !logo._id) {
                return <div key={index + logo._id}></div>
              }
              return (
                <LogoListItem key={index + logo._id}>
                  <LogoCard
                    url={logo.logo}
                    name={logo.name}
                    aiName={logo.aiName}
                    allowAi={true}
                    onDelete={() => deleteLogo(logo)}
                    updateLogo={(url: String, name?: any) =>
                      updateLogo({ ...logo, logo: url }, name)
                    }
                    updateName={(name) => updateLogo({ ...logo, name: name })}
                  />
                  <LogoCardBorder />
                </LogoListItem>
              )
            })}
          </LogosList>
        </LogoSection>
        <ColorSection>
          <SectionHeader>
            <Title>Colors</Title>
            {colors && getLogosCount(colors) && (
              <Link onClick={addColor}>+ Add</Link>
            )}
          </SectionHeader>
          <ColorsList>
            {colors.map((color: any, index: number) => {
              if (!color.status || !color._id) {
                return <div key={index + color._id}></div>
              }
              return (
                <ColorListItem key={index + color._id}>
                  <ColorCard
                    {...color}
                    onDelete={() => deleteColor(color)}
                    onChange={(data) => updateColor({ ...color, ...data })}
                  />
                </ColorListItem>
              )
            })}
          </ColorsList>
        </ColorSection>
      </ContentSection>
    </Container>
  )
}
