import styled from 'styled-components'
import { antennaBoldFont } from '@app/styles'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: calc(100vw - 72px);
  float: right;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 372px;
  padding: 0px;

  border-right: 1px solid #dadcdb;
`

export const DetailContainer = styled.div`
  flex: 1;
  padding: 0 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
`
export const selectContainer = styled.select`
  flex: 1;
  width: 100%;
  padding: 32px;
`

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  flex: 0 0 auto;

  padding: 16px 16px;
`

export const ListTitle = styled.div`
  ${antennaBoldFont}
  font-size: 2rem;
  text-transform: uppercase;
`

export const ProductListContainer = styled.div`
  flex: 1;
  margin-top: 16px;
  overflow: auto;
`

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 16px;
  }
`

export const ProductField = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  select {
    text-transform: none;
    display: flex;
    width: 100%;
    height: 40px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: #7a8894;
  margin-bottom: 10px;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .select-container {
    width: 100%;
  }
`
