import { baseURL } from '@app/providers'
import { s3, s3Bucket } from '@app/utils'

export function dataURItoBlob(dataURI: any) {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

export function generateThumbnail(orderId: string, canvasId: string) {
  return new Promise((resolve, reject) =>
    getResizedCanvas(canvasId, 400).toBlob((blob: any) => {
      const params = {
        Bucket: s3Bucket,
        Key: `player/${orderId}-thumb-nail.png`,
        Body: blob,
        ACL: 'public-read',
        ContentEncoding: 'base64',
        ContentType: `image/*`,
      }

      s3.upload(params)
        .promise()
        .then((res: any) => resolve(res))
    }),
  )
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function is2dLengthEqual(sourceObj: any[], destinationObj: any[]) {
  const keys: any[] = Object.keys(sourceObj)

  for (let i = 0; i < keys.length; i++) {
    let a1 = sourceObj[keys[i]],
      a2 = destinationObj[keys[i]]
    if (!a1 || !a2 || Object.keys(a1).length !== Object.keys(a2).length) {
      return false
    }
  }

  return true
}

export function isLightColor(hex: string) {
  let rgb = hexToRgb(hex) || { r: 255, g: 255, b: 255 }

  return rgb.r + rgb.g + rgb.b > 600
}

export function isValidHexColor(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return !!result
}

export function loadImage(src: string, canvas?: boolean) {
  return new Promise((resolve: any, reject: any) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = reject
    img.src = canvas ? `${baseURL}getImage?src=${src}` : src
  })
}

export function getResizedCanvas(
  id: string,
  maxSize: number,
  imageWithBorder?: boolean,
) {
  const canvas = document.getElementById(id) as HTMLCanvasElement
  const resizedCanvas = document.createElement('canvas')
  const ctx: any = resizedCanvas.getContext('2d')
  const ratio = maxSize / Math.max(canvas.width, canvas.height)
  let x = 0,
    y = 0

  if (imageWithBorder) {
    resizedCanvas.width = maxSize
    resizedCanvas.height = maxSize
    x = (maxSize - canvas.width * ratio) / 2
    y = (maxSize - canvas.height * ratio) / 2
  } else {
    resizedCanvas.width = canvas.width * ratio
    resizedCanvas.height = canvas.height * ratio
  }
  ctx.drawImage(canvas, x, y, canvas.width * ratio, canvas.height * ratio)

  return resizedCanvas
}


