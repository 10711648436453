import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { appBackgroundColor, appFontColor, regularFont } from '@app/styles'

const GlobalStyles = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
    margin:0;
    padding:0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    ${regularFont}
    background-color: ${appBackgroundColor};
    color: ${appFontColor};
  }

  .nike-font {
    font-family: 'NikeFieldTypeFootball';
    position: absolute;
    text-indent: -1000px;
  }

  *::-webkit-scrollbar {
    width: 3px;
  }


  *::-webkit-scrollbar-track {
    background: #fafafa;
    width: 3px;
  }
 

  *::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
  }
`

export default GlobalStyles
