import React, { useState, useEffect } from 'react'
import { useGetSizes, useGetTeamsPlayer, useGetPositions } from '@app/graphql'
import { Link as NavLink } from 'react-router-dom'
import { getLogosCount, useLocation } from '@app/utils'
import {
  InlineEdit,
  InlineEditText,
  Input,
  LogoCard,
  Select,
  Radio,
  Icon,
  ImageUploader,
  Button,
  Dialog,
  Toast
} from '@app/components'

import {
  Container,
  DetailHeader,
  PlayerUrl,
  ContentSection,
  PlayerInfo,
  InlineEditForm,
  FormFieldM,
  LabelText,
  LogoSection,
  SectionHeader,
  LogosList,
  Title,
  Link,
  LogoListItem,
  IconContainer,
  ProfileName,
  DialogFooter,
  LogoInfo,
  ShowAllDesigns,
  ShowEquContainer,
  InputBox,
} from './player-detail-components'

type Props = {
  player: any
  logos: any[]
  updatePlayer: (e: any) => void
  addLogo: (e: any) => void
  updateLogo: (e: any, name?: String) => void
  errorMessage:string
}

export default function PlayerDetail({
  player,
  updatePlayer,
  addLogo,
  logos,
  updateLogo,
  errorMessage
}: Props) {
  const {
    _id,
    name,
    accessCode,
    brand,
    playerProfileLogo,
    size,
    teamId,
    number,
    description,
    position,
  } = player
  const { data } = useGetTeamsPlayer()
  const sizes = useGetSizes()
  const positions = useGetPositions()
  const { navigate } = useLocation()
  const { teams } = data.teams
  const [showDelete, setShowDelete] = useState(false)
  const [logoToDelete, setLogoToDelete] = useState({})
  const [playerName, setPlayerName] = useState(name)
  const [playerAccessCode, setAccessCode] = useState(accessCode)
  const [playerLogo, setPlayerLogo] = useState(playerProfileLogo)
  const [playerId, setPlayerId] = useState(_id)
  const [playerDescription, setPlayerDescription] = useState(description)
  const [playerNumber, setNumber] = useState(number)
  const [playerBrand, setBrand] = useState(brand)
  const [showToast, setShowToast] = useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState(getSelected(teams, teamId))
  const [selectedSize, setSelectedSize] = useState(getSelected(sizes, size))
  const [selectedPosition, setSelectedPosition] = useState(
    getSelected(positions, position),
  )
  useEffect(() => {
    setTimeout(() => {
      setPlayerName(player.name)
      setAccessCode(player.accessCode)
      setPlayerId(player._id)
      setPlayerLogo(player.playerProfileLogo ? player.playerProfileLogo : '')
      setPlayerDescription(player.description)
      setNumber(player.number)
      setBrand(player.brand)
      setSelectedTeam(getSelected(teams, player.teamId))
      setSelectedSize(getSelected(sizes, player.size))
      setSelectedPosition(getSelected(positions, player.position))
      setShowToast(errorMessage?true:false)
    }, 10)
  }, [player, teams, sizes, logos, positions,errorMessage])
  function getSelected(data: any, value: any) {
    try {
      if (data && value) {
        let obj = data.find((o: any) => o.value === value)
        return obj
      }
      return {}
    } catch (error) {
      console.log(error)
    }
  }

  function updatePlayerName(e: any) {
    const playerName = e.target.value

    if (playerName) {
      const data = { _id: _id, name: playerName }
      setPlayerName(playerName)
      updatePlayer(data)
    }
  }

  function updateAccessCode(e: any) {
    const accessCode = e.target.value
    if (accessCode) {
      const data = { _id: _id, accessCode: accessCode }
      setAccessCode(accessCode)
      updatePlayer(data)
    }
  }
  
  function updateNumber(e: any) {
    const playerNumber = e.target.value

    if (playerNumber) {
      let number = parseInt(playerNumber)
      number = number < 0 ? 0 : number > 99 ? 99 : number
      const data = { _id: _id, number: number }
      setNumber(number)
      updatePlayer(data)
    }
  }
  function updatePlayerDescription(value: any) {
    if (value) {
      const data = { _id: _id, description: value }
      setPlayerDescription(value)
      updatePlayer(data)
    }
  }
  function updateSelected(val: any, type: any) {
    console.log(val)
    if (val) {
      let data: any = {}
      if (type === 'size') {
        setSelectedSize(val)
        data = { _id: _id, size: val.value }
      } else if (type === 'brand') {
        setBrand(val)
        data = { _id: _id, brand: val }
      } else if (type === 'position') {
        setSelectedPosition(val)
        data = { _id: _id, position: val.value }
      } else {
        setSelectedTeam(val)
        data = { _id: _id, teamId: val.value }
      }
      updatePlayer(data)
    }
  }
  function uploadProfile(e: any) {
    if (e) {
      const data = { _id: playerId, playerProfileLogo: e }
      updatePlayer(data)
    }
  }
  function deleteLogo(logo: any) {
    setShowDelete(true)
    setLogoToDelete(logo)
  }
  function deleteUpdateLogo(logo: any) {
    updateLogo({ ...logo, status: false })
    setShowDelete(false)
  }

  function showAllDesigns() {
    localStorage.setItem('selectedPlayer', playerId)
    localStorage.setItem('selectedTeam', player.teamId)
    navigate(`/orders`)
  }

  return (
    <>
    <Toast
        message={errorMessage}
        showToast={showToast}
        type='warn'
        title='Athlete Access Code Error'
        onClose={() => setShowToast(false)}
      />
    <Container>
      {showDelete && (
        <Dialog title="Remove Logo" onClose={() => setShowDelete(false)}>
          <Dialog.Body>Are you sure, you want to remove this logo?</Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button onClick={() => deleteUpdateLogo(logoToDelete)}>
                Remove Logo
              </Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
      <DetailHeader>
        {playerId && (
          <ImageUploader
            key={playerId}
            url={playerLogo}
            location="profile"
            onUpload={uploadProfile}
          />
        )}

        <ProfileName>
          <InlineEdit
            label="Player"
            value={playerName}
            placeholder="Enter Player Name"
            onChange={(e) => setPlayerName(e.target.value)}
            onBlur={updatePlayerName}
          />
          <ShowEquContainer>
            <PlayerUrl>
              <NavLink to={`/user`} target="_blank">
                Athlete Equipment View >
              </NavLink>
            </PlayerUrl>
            <PlayerUrl>
              <ShowAllDesigns onClick={showAllDesigns}>
                Show All Designs >
              </ShowAllDesigns>
            </PlayerUrl>
          </ShowEquContainer>
        </ProfileName>
      </DetailHeader>
      <ContentSection>
        <PlayerInfo>
          <InputBox>
            <InlineEdit
              label="Access Code"
              value={playerAccessCode ? playerAccessCode : ''}
              placeholder="Add Access Code"
              onChange={(e) => setAccessCode(e.target.value)}
              onBlur={updateAccessCode}
            />
          </InputBox>
          <InlineEditForm>
            <FormFieldM>
              <Select
                label="Team"
                options={teams}
                name="team"
                value={selectedTeam}
                onChange={(val: any) => updateSelected(val, 'team')}
              />
            </FormFieldM>
            <FormFieldM>
              <Select
                label="Position"
                options={positions}
                placeholder="Select Position"
                name="position"
                value={selectedPosition}
                onChange={(val: any) => updateSelected(val, 'position')}
              />
            </FormFieldM>
          </InlineEditForm>
          <InlineEditForm>
            <FormFieldM>
              <Input
                label="Number"
                type="number"
                value={playerNumber}
                onChange={(e) => setNumber(e.target.value)}
                onBlur={updateNumber}
              />
            </FormFieldM>

            <FormFieldM>
              <Select
                label="Size"
                options={sizes}
                name="size"
                value={selectedSize}
                onChange={(val: any) => updateSelected(val, 'size')}
              />
            </FormFieldM>
          </InlineEditForm>
          <LabelText>Brand</LabelText>

          <InlineEditForm>
            <Radio
              label="Brand"
              name="brand"
              checked={playerBrand === 'Nike'}
              onChange={(val: any) => updateSelected(val.target.value, 'brand')}
              value="Nike">
              <IconContainer>
                <Icon name="nike" />
              </IconContainer>
              Nike
            </Radio>
            <Radio
              label="Brand"
              name="brand"
              checked={playerBrand === 'Jordan'}
              onChange={(val: any) => updateSelected(val.target.value, 'brand')}
              value="Jordan">
              <IconContainer>
                <Icon name="jordan" />
              </IconContainer>
              Jordan
            </Radio>
          </InlineEditForm>
          <div className="player-description">
            <InlineEditForm>
              <InlineEditText
                label="Description"
                value={playerDescription}
                placeholder="Add Description"
                onChange={(e) => setPlayerDescription(e.target.value)}
                onBlur={(e) => updatePlayerDescription(e.target.value)}
              />
            </InlineEditForm>
          </div>
        </PlayerInfo>
        <LogoSection>
          <SectionHeader>
            <Title>Personal Logos</Title>
            {logos && getLogosCount(logos) && (
              <Link onClick={addLogo}>+ Add</Link>
            )}
          </SectionHeader>
          <LogoInfo>(Max file size is 5 mb)</LogoInfo>
          <LogosList>
            {logos &&
              logos.map((logo: any, index: number) => {
                if (!logo.status || !logo._id) {
                  return <div key={index + logo._id}></div>
                }
                return (
                  <LogoListItem key={index + logo._id}>
                    <LogoCard
                      url={logo.logo}
                      onDelete={() => deleteLogo(logo)}
                      name={logo.name}
                      aiName={logo.aiName}
                      allowAi={true}
                      updateLogo={(url: String, name?: any) =>
                        updateLogo({ ...logo, logo: url }, name)
                      }
                      updateName={(name) => updateLogo({ ...logo, name: name })}
                    />
                  </LogoListItem>
                )
              })}
          </LogosList>
        </LogoSection>
      </ContentSection>
    </Container>
    </>
  )
}
