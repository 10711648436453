import { createGlobalStyle } from 'styled-components'

import AntennaExtraCondBold from '@app/fonts/AntennaExtraCond-Bold.woff'
import WhitneyBoldPro from '@app/fonts/Whitney-Bold-Pro.woff'
import NikeFieldTypeFootball from '@app/fonts/NikeFieldTypeFootball.otf'

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'NikeFieldTypeFootball';
        src: url(${NikeFieldTypeFootball}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'AntennaExtraCond-Bold';
        src: url(${AntennaExtraCondBold}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Whitney-Bold-Pro';
        src: url(${WhitneyBoldPro}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`

export default GlobalFonts
