import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Password, Icon } from '@app/components'
import { useGetUser } from '@app/graphql'
import {
  Loginui,
  Welcomeheading,
  Welcomelogo,
  Picholder,
  ButtonContainer,
  ErrorUi,
  InputBox,
  UserErrorUi,
} from './login-components'
import { useLocation } from '@app/utils'
import { AuthContext } from '@app/providers'

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export default function Login() {
  const { register, handleSubmit, errors } = useForm()
  const [saving, setSaving] = useState<boolean>(false)
  const [userErrror, setUserError] = useState<string>('')
  const [getLoginUser] = useGetUser()
  const { navigate } = useLocation()
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const userEmail = userInfo && userInfo.email
  React.useEffect(() => {
    if (userEmail) {
      setTimeout(() => {
        navigate('/orders')
      }, 50)
    }
    // eslint-disable-next-line
  }, [userEmail, navigate])
  function login(data: any) {
    setSaving(true)
    setUserError('')
    const { email = '', password = '' } = data
    const loginVaribles = {
      email: email.toLowerCase(),
      password: password,
    }
    getLoginUser({ variables: loginVaribles })
      .then((res: any) => {
        const {
          data: { login },
        } = res

        const { status = false, data = [], message = '' } = login

        if (status) {
          localStorage.setItem('userEmail', JSON.stringify(data[0]))
          setUserInfo(data[0])
          navigate('/orders')
          setUserError('')
          setSaving(false)
        } else {
          setSaving(false)
          setUserError(message)
        }
      })
      .catch((err: any) => {
        setSaving(false)
        console.log(err)
      })
  }

  return (
    <Picholder>
      <Loginui>
        <Welcomelogo>
          <Icon name="nikeLarge" />
        </Welcomelogo>
        <Welcomeheading>Login</Welcomeheading>
        <form onSubmit={handleSubmit(login)}>
          <InputBox>
            <Input
              label="Email"
              placeholder="Enter Email"
              name="email"
              register={register({ required: true, pattern: emailPattern })}
              onKeyUp={() => {
                setUserError('')
              }}
            />
            {errors.email && errors.email.type === 'required' && (
              <ErrorUi>Please enter Email</ErrorUi>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <ErrorUi>Please enter valid email</ErrorUi>
            )}
          </InputBox>
          <InputBox>
            <Password
              label="Password"
              placeholder="Enter Password"
              name="password"
              register={register({ required: true })}
              onKeyUp={() => {
                setUserError('')
              }}
            />
            {errors.password && errors.password.type === 'required' && (
              <ErrorUi>Please enter Password</ErrorUi>
            )}
          </InputBox>
          <ButtonContainer disabled={saving}>
            <Button width={'100%'} size="large" type="submit">
              {saving ? 'Logging In...' : 'Login'}
            </Button>
          </ButtonContainer>
          {userErrror && !errors.email && !errors.password && (
            <UserErrorUi>{`${userErrror}`}</UserErrorUi>
          )}
        </form>
      </Loginui>
    </Picholder>
  )
}
