import gql from 'graphql-tag'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
const GET_COUNT = gql`
  {
    orderStatus {
      totalOrders
      totalStatus
      OrderStatusList {
        _id
        code
        name
        orderCount
        color
        backgroundColor
        status
      }
    }
  }
`
export function useGetOrderCount() {
  return useQuery(GET_COUNT)
}
const GET_ORDERS = gql`
  query orders($page: Int, $search: String, $limit: Int, $filter: JSON) {
    orders(
      limit: $limit
      page: $page
      search: $search
      sort: DESC
      filter: $filter
    ) {
      count
      pages
      page
      page
      pages
      count
      orders {
        _id
        playerId
        productId
        orderId
        updatedAt
        product {
          _id
          name
          productProfileLogo
        }
        player {
          _id
          name
          playerProfileLogo
        }
        productType
        teamName
        gloveSize
        season
        fileName
        styleName
        orderStatus
        trackStatus
        statusId
        status
        productItems {
          _id
          item
          itemId
          itemType
          orderId
          notes
          status
          options
          optionalColors
          primaryColors
          secondaryColors
          tertiaryColors
          pattern
          material
          supplier
          component
        }
      }
    }
  }
`

const GET_ORDER = gql`
  query order($orderId: String) {
    order(orderId: $orderId) {
      _id
      orderId
      orderCode
      orderColorNumber
      playerId
      teamName
      gloveSize
      productId
      productType
      orderStatus
      trackStatus
      statusId
      season
      owner
      groupName
      fileName
      styleName
      updatedAt
      product {
        _id
        name
      }
      player {
        _id
        name
        playerCode
      }
      playerLogo {
        _id
        name
        teamId
        aiName
        aiLogo
        logo
        status
      }
      teamColor {
        _id
        name
        code
        status
      }
      teamLogo {
        _id
        name
        teamId
        aiName
        aiLogo
        logo
        status
      }
      productItems {
        _id
        orderId
        itemId
        item
        playerItem
        notes
        status
        itemType
        options
        optionalColors
        primaryColors
        secondaryColors
        tertiaryColors
        pattern
        material
        brand
        logos
        customPattern
        supplier
        component
      }
      customMessage
      dieCast
    }
  }
`

export function useGetOrder(variables: { orderId: string }) {
  return useQuery(GET_ORDER, { variables, fetchPolicy: 'network-only' })
}

const PLAYER_ORDERS = gql`
  query players($playerCode: String) {
    playerOrders(playerCode: $playerCode) {
      name
      number
      playerCode
      accessCode
      teamName
      size
      position
      playerProfileLogo
      orders {
        orderId
        orderCode
        orderColorNumber
        teamName
        productProfileLogo
        thumbnailLogo
        updatedAt
        status
        orderStatus
        trackStatus
      }
    }
  }
`

export function usePlayerOrders(variables: { playerCode?: any }) {
  return useQuery(PLAYER_ORDERS, { variables })
}

const GET_ORDER_LOG = gql`
  query orderLog($orderId: String) {
    orderLog(orderId: $orderId) {
      activityLog {
        _id
        userId
        orderId
        logId
        logType
        logOrderStatus
        userName
        userProfileLogo
        logActivity
        status
        updatedAt
      }
    }
  }
`

export function useOrderLog(variables: { orderId?: any }) {
  return useQuery(GET_ORDER_LOG, { variables, fetchPolicy: 'no-cache' })
}

const GET_CONFIGURATION = gql`
  query productItems($productName: String) {
    productsItems(productName: $productName) {
      productName
      products {
        _id
        itemId
        item
        playerItem
        notes
        itemType
        options
        optionalColors
        primaryColors
        secondaryColors
        tertiaryColors
        pattern
        material
        brand
        logos
        customPattern
        component
        supplier
      }
    }
  }
`

export function useGetConfiguration() {
  return useLazyQuery(GET_CONFIGURATION)
}

const CREATE_ORDER = gql`
  mutation createOrder(
    $_id: ID
    $orderStatus: String
    $trackStatus: String
    $statusId: Int
    $playerId: String
    $teamName: String
    $productType: String
    $productId: String
    $season: String
    $owner: String
    $groupName: String
    $thumbnailLogo: String
    $fileName: String
    $styleName: String
    $gloveSize: String
    $singleColor: Boolean
    $productItems: [productOptions]
    $customMessage: JSON
    $userId: String
    $dieCast: JSON
  ) {
    createOrder(
      _id: $_id
      orderStatus: $orderStatus
      trackStatus: $trackStatus
      statusId: $statusId
      playerId: $playerId
      teamName: $teamName
      productType: $productType
      productId: $productId
      thumbnailLogo: $thumbnailLogo
      season: $season
      owner: $owner
      groupName: $groupName
      fileName: $fileName
      styleName: $styleName
      gloveSize: $gloveSize
      singleColor: $singleColor
      productItems: $productItems
      customMessage: $customMessage
      userId: $userId
      dieCast: $dieCast
    ) {
      _id
      playerId
      teamName
      productType
      productId
      gloveSize
      updatedAt
      thumbnailLogo
      dieCast
      productItems {
        orderId
        itemId
        item
        notes
        options
        optionalColors
        primaryColors
        secondaryColors
        tertiaryColors
        pattern
        material
        brand
        logos
        customPattern
        supplier
        component
      }
    }
  }
`

export function useCreateOrder() {
  return useMutation(CREATE_ORDER, {
    update(cache, { data: { createOrder } }) {
      const { orders }: any = cache.readQuery({
        query: GET_ORDERS,
        variables: {
          page: 1,
          search: '',
          limit: 50,
        },
      })
      const orderIndex = orders.orders.findIndex(
        (order: any) => order._id === createOrder._id,
      )

      if (orderIndex === -1) {
        orders.orders.unshift(createOrder)

        cache.writeQuery({
          query: GET_ORDERS,
          variables: {
            page: 1,
            search: '',
            limit: 50,
          },
          data: { orders: { ...orders } },
        })
      }
    },
  })
}

export function useUpdateOrders(variables: { orderId?: any }) {
  return useMutation(CREATE_ORDER, {
    refetchQueries: [{ query: GET_ORDER_LOG, variables }],
  })
}

const UPDATE_ORDER = gql`
  mutation createOrder(
    $_id: ID
    $playerId: String
    $orderStatus: String
    $trackStatus: String
    $thumbnailLogo: String
    $productItems: [productOptions]
    $customMessage: JSON
    $dieCast: JSON
  ) {
    createOrder(
      _id: $_id
      playerId: $playerId
      orderStatus: $orderStatus
      trackStatus: $trackStatus
      thumbnailLogo: $thumbnailLogo
      productItems: $productItems
      customMessage: $customMessage
      dieCast: $dieCast
    ) {
      _id
    }
  }
`

export function useUpdateOrder(variables: { playerCode?: any }) {
  return useMutation(UPDATE_ORDER, {
    refetchQueries: [{ query: PLAYER_ORDERS, variables }],
  })
}

export function useLazyGetOrders(variables: {
  page?: any
  search?: any
  limit?: any
  filter?: any
}) {
  return useQuery(GET_ORDERS, { variables, fetchPolicy: 'no-cache' })
}

const ORDERS = {
  data: {
    orders: {
      count: 3,
      pages: 1,
      page: 1,
      teams: [
        {
          _id: '5ede2d8f68047c05d6aba785',
          player: 'Team B',
          order: '',
          product: '',
          team: '',
          gloveSize: '',
          lastModified: '',
          status: '',
        },
      ],
    },
  },
}
export function useGetOrders() {
  return ORDERS
}

const DUPLICATE_ORDER = gql`
  mutation orderDuplicate($_id: ID) {
    orderDuplicate(_id: $_id) {
      orderId
    }
  }
`
export function useGetDuplicateOrder() {
  return useMutation(DUPLICATE_ORDER)
}

const DELETE_ORDER = gql`
  mutation deleteOrder($_id: ID, $orderId: String) {
    deleteOrder(_id: $_id, orderId: $orderId) {
      message
      success
    }
  }
`
export function useGetDeleteOrder() {
  return useMutation(DELETE_ORDER)
}

const currentYear = new Date().getFullYear()

const dropdowns = {
  productTypes: [{ value: 'Football Gloves', label: 'Football Gloves' }],
  gloveeTypes: [{ value: 'VJ6', label: 'VJ6' }],
  status: [{ value: 'Created', label: 'Created' }],
  gloveSizes: [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: '2XL', value: '2XL' },
    { label: '3XL', value: '3XL' },
    { label: '4XL', value: '4XL' },
    { label: '5XL', value: '5XL' },
  ],
  seasons: [
    { label: 'FA ' + currentYear, value: String(currentYear) },
    { label: 'FA ' + (currentYear + 1), value: String(currentYear + 1) },
    { label: 'FA ' + (currentYear + 2), value: String(currentYear + 2) },
  ],
  owners: [
    { label: 'Andrew Nguyen', value: 'Andrew Nguyen' },
    { label: 'Cody Dill', value: 'Cody Dill' },
    { label: 'Drew Browder', value: 'Drew Browder' },
    { label: 'Other', value: 'Other' },
  ],
  colors: [
    { code: '#ffd400', name: 'yellow' },
    { code: '#45d7b5', name: 'Green' },
    { code: '#ffffff', name: 'White' },
    { code: '#fb807f', name: 'Red' },
    { code: '#8f77e8', name: 'Purple' },
    { code: '#fb7dc1', name: 'Pink' },
  ],
  orderStatus: [
    { label: 'Created', value: 'Created' },
    { label: 'Pending Internal Review', value: 'Pending Internal Review' },
    { label: 'Ready for Player', value: 'Ready for Player' },
    { label: 'Sent to Player', value: 'Sent to Player' },
    { label: 'Player Approved', value: 'Player Approved' },
    { label: 'Pending Nike Approval', value: 'Pending Nike Approval' },
    { label: 'Final Approved', value: 'Final Approved' },
    { label: 'SPEC Created', value: 'SPEC Created' },
    { label: 'Archive', value: 'Archive' },
  ],
  logos: [
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/player/1592977985470-3.jpg',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be1',
    },
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/player/1592921841138-4.jpg',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be2',
    },
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/player/1592921841138-4.jpg',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be3',
    },
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/team/1592969117151-cavaliers.png',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be4',
    },
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/player/1592921841138-4.jpg',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be5',
    },
    {
      logo:
        'https://nikegloverteamlogo.s3.us-east-2.amazonaws.com/team/1592969117151-cavaliers.png',
      name: 'TeamLogo',
      _id: '5ecd22c20cbe2947943546be6',
    },
  ],
}

export function getOrdersDropdown() {
  return dropdowns
}
const statusColors = {
  colors: [
    {
      code: 0,
      name: 'Total Designs',
      backgroundColor: '#C6C5C5',
    },
    {
      code: 1,
      name: 'Created',
      backgroundColor: '#C9CCDD',
    },
    {
      code: 2,
      name: 'Pending Internal Review',
      backgroundColor: '#F5C3DE',
    },
    {
      code: 3,
      name: 'Ready for Player',
      backgroundColor: '#FFFBBF',
    },
    {
      code: 4,
      name: 'Sent to Player',
      backgroundColor: '#E0EFCF',
    },
    {
      code: 5,
      name: 'Player Approved',
      backgroundColor: '#BFE5D1',
    },
    {
      code: 6,
      name: 'Pending Nike Approval',
      backgroundColor: '#FAE2CA',
    },
    {
      code: 7,
      name: 'Final Approved',
      backgroundColor: '#BFE8F6',
    },
    {
      code: 8,
      name: 'Spec Created',
      backgroundColor: '#F5C8CA',
    },
    {
      code: 9,
      name: 'Archived',
      backgroundColor: '#F5C8CA',
    },
  ],
}
export function getStatusColors() {
  return statusColors
}
