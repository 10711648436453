import React, { useState } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import {
  useCreateProduct,
  getProductsDropdown,
  useGetProducts,
} from '@app/graphql'
import {
  Button,
  Dialog,
  Input,
  ProductList,
  ProductDetail,
  EmptyScreen,
  Icon,
} from '@app/components'
import {
  Container,
  ListContainer,
  DetailContainer,
  ListHeader,
  ListTitle,
  DialogFooter,
  ProductField,
  Label,
  Field,
} from './products-components'

export default function Product() {
  const [showDialog, setShowDialog] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [createdProduct, setCreatedProduct] = useState(null)
  const { loading, data, error } = useGetProducts()

  const { register, handleSubmit } = useForm()
  const [createProduct] = useCreateProduct()
  const { productTypes, franchises, years } = getProductsDropdown()

  function onSubmit(data: any) {
    if (
      selectedType.value !== 'Select Product Type' &&
      selectedFranchise.value !== 'Select Franchise' &&
      selectedYear.value !== 'Select Year'
    ) {
      createProduct({
        variables: {
          ...data,
          type: selectedType.value,
          franchise: selectedFranchise.value,
          year: selectedYear.value,
        },
      }).then((res) => {
        onProductSelect(res.data.createProduct)
        setCreatedProduct(null)
        setCreatedProduct(res.data.createProduct)
      })
      setShowDialog(false)
    }
  }

  function onProductSelect(product: any) {
    setSelectedProduct(null)
    setCreatedProduct(null)
    setSelectedProduct(product)
  }

  function onProductDelete() {
    setSelectedProduct(null)
    setCreatedProduct(null)
  }

  const [selectedType, setSelectedType] = useState({
    value: 'Select Style Type',
    label: 'Select Style Type',
  })
  const [selectedFranchise, setSelectedFranchise] = useState({
    value: 'Select Franchise',
    label: 'Select Franchise',
  })
  const [selectedYear, setSelectedYear] = useState({
    value: 'Select Year',
    label: 'Select Year',
  })

  function changeProductType(type: any) {
    setSelectedType(type)
  }

  function changeFranchise(franchise: any) {
    setSelectedFranchise(franchise)
  }

  function changeYear(year: any) {
    setSelectedYear(year)
  }

  function resetForm() {
    setSelectedType({
      value: 'Select Style Type',
      label: 'Select Style Type',
    })
    setSelectedFranchise({
      value: 'Select Franchise',
      label: 'Select Franchise',
    })
    setSelectedYear({
      value: 'Select Year',
      label: 'Select Year',
    })
    setShowDialog(true)
  }

  if (loading) {
    return <Icon name="nikeLoading" />
  }

  if (error) {
    return <div>Styles Error</div>
  }

  if (
    data &&
    data.products &&
    data.products.products &&
    data.products.products.length &&
    !selectedProduct
  ) {
    setTimeout(() => setSelectedProduct(data.products.products[0]), 0)
  }

  return (
    <>
      {data &&
      data.products &&
      data.products.products &&
      data.products.products.length === 0 ? (
        <EmptyScreen
          emptyLogo="NoProducts"
          emptyText="Add Style"
          onChange={(e) => resetForm()}
        />
      ) : (
        <Container>
          <ListContainer>
            <ListHeader>
              <ListTitle>Styles</ListTitle>
              {/* <Button width="150px" onClick={() => resetForm()}>
                Add Product
              </Button> */}
            </ListHeader>
            {data && data.products && (
              <ProductList
                onSelect={onProductSelect}
                products={data.products.products}
                product={
                  createdProduct ? createdProduct : data.products.products[0]
                }
              />
            )}
          </ListContainer>

          <DetailContainer>
            {selectedProduct && (
              <ProductDetail
                onDelete={onProductDelete}
                product={selectedProduct}
              />
            )}
          </DetailContainer>
        </Container>
      )}
      {showDialog && (
        <Dialog title="Add Style" onClose={() => setShowDialog(false)}>
          <Dialog.Body>
            <form>
              <Input
                label="Style Name"
                placeholder="Enter Style Name"
                name="name"
                register={register({ required: true })}
              />
              <ProductField>
                <Label>Style Type</Label>
                <Field>
                  <Select
                    className="select-container"
                    options={productTypes}
                    value={selectedType}
                    placeholder="Enter Style Name"
                    name="type"
                    onChange={changeProductType}
                    register={register({ required: true })}
                  />
                </Field>
              </ProductField>
              <ProductField>
                <Label>Sports Franchise</Label>
                <Field>
                  <Select
                    className="select-container"
                    options={franchises}
                    value={selectedFranchise}
                    name="franchise"
                    onChange={changeFranchise}
                    register={register({ required: true })}
                  />
                </Field>
              </ProductField>
              <ProductField>
                <Label>Year</Label>
                <Field>
                  <Select
                    className="select-container"
                    options={years}
                    value={selectedYear}
                    name="year"
                    onChange={changeYear}
                    register={register({ required: true })}
                  />
                </Field>
              </ProductField>
            </form>
          </Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)}>Add Style</Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  )
}
