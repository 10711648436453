import React, { useState, useEffect, useContext } from 'react'
import { UserList, Icon, UserDetail } from '@app/components'
import { useGetAllUsers, useUpdateUser } from '@app/graphql'
import { User as Tuser } from '@app/types'
import { AuthContext } from '@app/providers'

import {
  Container,
  ListContainer,
  DetailContainer,
  UserListContainer,
  ListHeader,
  ListTitle,
} from './users-components'
export default function User() {
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const [updateUser] = useUpdateUser()
  const { loading, data } = useGetAllUsers()
  const [users, setUsers] = useState<Tuser[]>([])
  const userObj = {
    _id: '',
    name: '',
    email: '',
    userProfileLogo: '',
  }
  const [selectedUser, setSelectedUser] = useState<Tuser>(userObj)

  useEffect(() => {
    if (!loading && data && data.users) {
      const { users } = data
      setUsers(users)
    }
  }, [loading, data])

  function handleScroll(event: any) {
    var node = event.target
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight
    if (bottom) {
      console.log('BOTTOM REACHED:', bottom)
    }
  }
  const paneDidMount = (node: any) => {
    if (node) {
      node.addEventListener('scroll', handleScroll)
    }
  }

  function updateSelectedUser(data: any) {
    setSelectedUser(data)
  }

  function updateUserData(params: any) {
    updateUser({ variables: { ...params } })
      .then((res: any) => {
        const {
          data: { createUser },
        } = res
        const { success = false, user = {} } = createUser
        if (success) {
          if (userInfo && userInfo._id === user._id) {
            setUserInfo(user)
          }
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  if (loading) {
    return <Icon name="nikeLoading" />
  }
  if (!data) {
    return <div>Something is went wrong ...</div>
  }
  if (users.length && !selectedUser._id) {
    setTimeout(() => setSelectedUser(users[0]), 0)
  }
  return (
    <>
      <Container>
        <ListContainer>
          <ListHeader>
            <ListTitle>Users</ListTitle>
          </ListHeader>
          <UserListContainer ref={paneDidMount}>
            <UserList
              users={users}
              selectedUser={selectedUser}
              onSelect={updateSelectedUser}
            />
          </UserListContainer>
        </ListContainer>
        <DetailContainer>
          {users && selectedUser && selectedUser._id && (
            <UserDetail user={selectedUser} updateUser={updateUserData} />
          )}
        </DetailContainer>
      </Container>
    </>
  )
}
