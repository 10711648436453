import styled, { css, keyframes } from 'styled-components'
import {
  antennaBoldFont,
  screenSize,
  primaryColor,
  boldFont,
} from '@app/styles'

const SlideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`

const SlideInRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`

const SlideOutLeft = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`

const SlideOutRight = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  overflow: hidden;
  ${({ blur }: { blur?: boolean }) => {
    if (blur) {
      return `filter: blur(4px)`
    }
  }}
`

export const InnerContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`

export const DesignerViewContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease;
  @media (${screenSize.tablet}) {
    width: calc(100vw - 360px);
    background-color: #f5f5f5;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 16px 20px;
  @media (${screenSize.mobile}) {
    border-bottom: 1px solid #e3e3e3;
  }
  @media (${screenSize.tablet}) {
    padding: 24px;
  }
`

export const LeftNav = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;

  @media (${screenSize.mobile}) {
    padding: 16px 20px;
    justify-content: space-between;
    > div {
      &:first-child {
        display: none;
      }
    }
  }
  @media (${screenSize.tablet}) {
    flex-direction: column;
    width: 150px;
    left: 24px;
    top: 100px;
    bottom: 100px;
    z-index: 2;
    button {
      margin: 0 16px;
    }
    &.hideNav {
      > div {
        &:last-child {
          display: none;
        }
      }
    }
  }
`

export const RightNav = styled.div`
  width: 100%;
  background-color: #fff;
  border-left: 1px solid #e3e3e3;
  z-index: 2;
  @media (${screenSize.mobile}) {
    position: absolute;
    top: calc(100vh - 60px);
    height: calc(100vh - 120px);
    border-top: 1px solid #e3e3e3;
    transition: all 0.3s ease;
    &.active {
      top: 120px;
      box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.5);
      border-radius: 6px 6px 0 0;
    }
  }
  @media (${screenSize.tablet}) {
    width: 360px;
  }
`

export const BackIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #404040;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  > svg {
    position: relative;
    left: 0px;
    transition: left 0.3s ease;
  }
  &:hover {
    > svg {
      left: -4px;
    }
  }
`

export const HeaderTitle = styled.div`
  margin-left: 16px;
  color: #404040;
  font-size: 2rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const VerticalSeperator = styled.div`
  width: 2px;
  height: 32px;
  margin: 0 16px;
  ${({ isAdmin }: { isAdmin: boolean }) => {
    if (isAdmin) {
      return `
        flex: 1;
      `
    } else {
      return `
        border: 1px solid #808080;
        border-radius: 50%;
      `
    }
  }}
`

export const OrderStatus = styled.div`
  height: 22px;
  padding: 4px 8px;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  &.new {
    background-color: rgba(240, 79, 100, 0.8);
  }
  &.in-progress {
    background-color: rgba(34, 105, 210, 0.8);
  }
  &.submitted {
    background-color: rgba(82, 178, 127, 0.8);
  }
`

export const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transform: scale(1, 1);
  transition: transform 0.15s ease-in-out;
  > svg {
    font-size: 2.4rem;
  }
  &.preview {
    > svg {
      font-size: 1.5rem;
    }
  }
`

export const ViewInfo = styled.div`
  width: 100px;
  overflow: hidden;
  margin-left: 6px;
  @media (${screenSize.mobile}) {
    margin-top: 8px;
    font-size: 0.8rem;
  }
`

export const ViewName = styled.div`
  @media (${screenSize.mobile}) {
    text-align: center;
  }
  @media (${screenSize.tablet}) {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
`

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  @media (${screenSize.mobile}) {
    flex-direction: column;
    padding: 16px 0;
    background-color: #eeeef1;
    border-radius: 6px;
  }
  @media (${screenSize.tablet}) {
    padding: 30px 0;
  }
  ${({ active }: { active: boolean }) => {
    if (active) {
      return `
        @media (${screenSize.mobile}) {
          background-color: #75757A;
          color: #fff;
          ${NavIcon} {
            svg path,
            svg polygon {
              fill: #fff;
            }
          }
        }
        @media (${screenSize.tablet}) {
          color: #FF3366;
          ${NavIcon} {
            transform: scale(1.1, 1.1);
            svg path,
            svg polygon {
              fill: #ff3366;
            }
          }
          ${ViewName} {
            transform: translateX(0%);
          }
        }
      `
    } else {
      return `
        color: #3A404A;
      `
    }
  }}
  &:hover {
    ${NavIcon} {
      transform: scale(1.1, 1.1);
    }
    ${ViewName} {
      transform: translateX(0%);
    }
  }
  &:active {
    color: #ff3366;
    svg path,
    svg polygon {
      fill: #ff3366;
    }
  }
`

export const CanvasSlider = styled.div`
  width: 100%;
  position: absolute;
  bottom: 80px;
  @media (${screenSize.mobile}) {
    top: 220px;
  }
  @media (${screenSize.tablet}) {
    top: 100px;
  }
`

export const CanvasSlide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({
    active,
    inactive,
    left,
    right,
  }: {
    active: boolean
    inactive: boolean
    left: boolean
    right: boolean
  }) => {
    if (active) {
      if (left) {
        return css`
          animation: 0.4s ${SlideInRight} ease;
          z-index: 2;
        `
      } else if (right) {
        return css`
          animation: 0.4s ${SlideInLeft} ease;
          z-index: 2;
        `
      } else {
        return `
          z-index: 2;
        `
      }
    } else if (inactive) {
      if (left) {
        return css`
          animation: 0.4s ${SlideOutLeft} ease;
          z-index: 1;
        `
      } else if (right) {
        return css`
          animation: 0.4s ${SlideOutRight} ease;
          z-index: 1;
        `
      }
    }
  }}
  @media (${screenSize.tablet}) {
    background-color: #f5f5f5;
  }
`

export const CanvasInnerSlide = styled.div`
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
`

export const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  float: left;
`

export const CanvasInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .canvas-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    .lower-canvas,
    .upper-canvas {
      width: auto !important;
      height: auto !important;
      left: auto !important;
      top: auto !important;
    }
  }
`

export const CanvasSliderProgressBar = styled.div`
  display: flex;
  position: absolute;
  bottom: 50px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 2px;
`

export const CanvasSliderThumb = styled.div`
  width: 60px;
  height: 100%;
  margin: 0 8px;
  background-color: #dfdfdf;
  cursor: pointer;
  border-radius: 2px;
`

export const CanvasSliderProgress = styled.div`
  width: 60px;
  height: 100%;
  margin: 0 8px;
  position: absolute;
  left: 0;
  transition: left 0.4s ease-in-out;
  background-color: #f53366;
  border-radius: 2px;
`

export const OverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 3;
`

export const OverlayMessageContainer = styled.div`
  text-align: center;
`

export const OverlayMessage = styled.div`
  margin-top: 80px;
  font-size: 21px;
  font-weight: 400;
  color: #4c4f5b;
  white-space: pre-line;
`

export const OverlayIconContainer = styled.div``

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.95);
`

export const SuccessLogo = styled.div`
  margin-bottom: 16px;
`

export const SuccessMsg = styled.div`
  margin-bottom: 24px;
  text-align: center;
  color: #4e515e;
  ${antennaBoldFont}
  font-size: 3rem;
  font-weight: 600;
`
export const ToolTipContainer = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-top: 5px;
  margin-left: 20px;
`
export const ToolTipArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 10px;
  top: -10px;
  left: 42% !important;
  &::before,
  &::after {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    margin: auto;
    border-style: solid;
    border-width: 0 12px 10px 12px;
  }
  &::before {
    border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
    top: -1px;
  }
  &::after {
    border-color: transparent transparent white transparent;
  }
`
export const ToolTipBody = styled.div`
  min-width: 200px;
  min-height: 100px;
`
export const ExportContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ExportItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 15px 0px;
  padding: 0 10px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: ${primaryColor};
  }
`
export const SwitchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ active }: { active?: boolean }) => {
    if (active) {
      return `
        button{
               border:2px solid ${primaryColor};
                color: ${primaryColor};
                &:hover {
                    border:2px solid black;
                    
                   
                }
                &:active {
                    border:2px solid ${primaryColor};
                    color: ${primaryColor};
                     
                }
              }
            `
    }
  }}
  }
`
export const SizeBox = styled.button`
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  margin-right: 20px;
  ${boldFont};
  &:hover {
    color: black;
    border: 2px solid black;
  }
`
export const SavingIndicator = styled.div`
  margin-left: 10px;
`
export const ExportButton = styled.div`
  display: flex;
  flex-direction: row;
`