import React from 'react'
export default function PlayerIcon() {
  return (
    <svg width="23px" height="30px" viewBox="0 0 23 30" version="1.1">
      <title>user</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="user" fill="#EDF4FA">
          <path
            d="M22.98,25.541 L22.98,29.48 L-2.29150032e-13,29.48 L-2.29150032e-13,25.541 C-2.29150032e-13,25.541 1.91,23.161 5.08,21.451 C5.85,22.851 6.759,24.02 7.82,24.501 C8.86,24.971 9.69,25.221 10.31,25.361 C10.919,25.491 11.32,25.511 11.49,25.511 C11.83,25.511 13.08,25.44 15.169,24.501 C16.22,24.02 17.14,22.851 17.9,21.451 C21.07,23.161 22.98,25.541 22.98,25.541"
            id="Fill-136"></path>
          <path
            d="M15.642,12.119 L12.017,12.119 L12.017,19.056 C12.017,19.332 11.793,19.556 11.517,19.556 C11.241,19.556 11.017,19.332 11.017,19.056 L11.017,12.119 L7.391,12.119 C7.115,12.119 6.891,11.895 6.891,11.619 C6.891,11.343 7.115,11.119 7.391,11.119 L11.017,11.119 L11.017,4.182 C11.017,3.905 11.241,3.681 11.517,3.681 C11.793,3.681 12.017,3.905 12.017,4.182 L12.017,11.119 L15.642,11.119 C15.918,11.119 16.142,11.343 16.142,11.619 C16.142,11.895 15.918,12.119 15.642,12.119 M18.284,3.43 C16.447,0.229 12.772,-1.42108547e-14 11.755,-1.42108547e-14 C11.585,-1.42108547e-14 11.49,0.007 11.49,0.007 C11.49,0.007 11.395,-1.42108547e-14 11.225,-1.42108547e-14 C10.209,-1.42108547e-14 6.534,0.229 4.697,3.43 C2.432,7.376 4.615,16.452 6.91,20.608 C7.503,21.683 8.105,22.434 8.639,22.676 C10.471,23.505 11.449,23.514 11.489,23.514 L11.49,23.514 L11.491,23.514 L11.492,23.514 C11.532,23.514 12.508,23.505 14.341,22.676 C14.875,22.434 15.478,21.683 16.071,20.608 C18.367,16.452 20.548,7.376 18.284,3.43"
            id="Fill-137"></path>
        </g>
      </g>
    </svg>
  )
}
