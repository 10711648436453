import React, { useState } from 'react'
import { User } from '@app/types'

type AuthContextType = {
  userInfo: User | null
  setUserInfo?: any
}

type Props = {
  children: any
}

export const AuthContext = React.createContext<AuthContextType>({
  userInfo: null,
})

export function AuthProvider({ children }: Props) {
  let user: any = localStorage.getItem('userEmail')
  user = user ? JSON.parse(user) : null
  const [userInfo, setUserInfo] = useState<User | null>(user)
  const value = { userInfo, setUserInfo }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
