import * as React from 'react'
import { InputContainer, Label, InputComponent } from './input-components'

type Props = {
  type?: string
  label?: string
  placeholder?: string
  name?: string
  value?: string
  onChange?: (e: any) => void
  onKeyUp?: (e: any) => void
  register?: any
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  maxLength?: number
}

export default function Input({
  type = 'text',
  label,
  placeholder,
  name,
  value,
  onChange,
  onKeyUp,
  register,
  onBlur,
  maxLength = 256,
  onFocus,
}: Props) {
  return (
    <InputContainer>
      {label && <Label>{label}</Label>}
      <InputComponent
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyUp={onKeyUp}
        ref={register}
        maxLength={maxLength}
      />
    </InputContainer>
  )
}
