import React from 'react'

export default function NoTeams() {
  return (
    <svg fill="none" viewBox="0 0 186 186" width="186" height="186">
      <circle fill="#ebf2f9" cx="93" cy="93" r="93" />
      <path
        fill="#ebebeb"
        d="M 173.048 138.454 H 12 V 138.535 H 173.048 V 138.454 Z"
      />
      <path
        fill="#ebebeb"
        d="M 155.416 142.142 H 139.021 V 142.223 H 155.416 V 142.142 Z"
      />
      <path
        fill="#ebebeb"
        d="M 44.103 141.189 H 28.8969 V 141.269 H 44.103 V 141.189 Z"
      />
      <path
        fill="#ebebeb"
        d="M 140.194 140.648 H 134.013 V 140.728 H 140.194 V 140.648 Z"
      />
      <path
        fill="#ebebeb"
        d="M 118.684 142.783 H 114.149 V 142.864 H 118.684 V 142.783 Z"
      />
      <path
        fill="#ebebeb"
        d="M 111.279 142.783 H 99.8643 V 142.864 H 111.279 V 142.783 Z"
      />
      <path
        fill="#ebebeb"
        d="M 93.236 141.424 H 74.8798 V 141.504 H 93.236 V 141.424 Z"
      />
      <path
        fill="#ebebeb"
        d="M 88.3365 124.089 H 26.1431 C 25.6559 124.088 25.1889 123.894 24.8447 123.549 C 24.5005 123.204 24.3071 122.737 24.3071 122.249 V 34.8231 C 24.3114 34.3386 24.5066 33.8754 24.8503 33.5341 C 25.1941 33.1928 25.6586 33.0008 26.1431 33 H 88.3365 C 88.8243 33 89.2921 33.1938 89.637 33.5387 C 89.9819 33.8836 90.1757 34.3514 90.1757 34.8392 V 122.249 C 90.1757 122.737 89.9819 123.205 89.637 123.55 C 89.2921 123.895 88.8243 124.089 88.3365 124.089 V 124.089 Z M 26.1431 33.0644 C 25.6772 33.0653 25.2307 33.2509 24.9016 33.5807 C 24.5725 33.9104 24.3877 34.3572 24.3877 34.8231 V 122.249 C 24.3877 122.715 24.5725 123.162 24.9016 123.492 C 25.2307 123.822 25.6772 124.007 26.1431 124.008 H 88.3365 C 88.8027 124.007 89.2495 123.822 89.5791 123.492 C 89.9088 123.162 90.0943 122.716 90.0952 122.249 V 34.8231 C 90.0943 34.3569 89.9088 33.9101 89.5791 33.5805 C 89.2495 33.2508 88.8027 33.0653 88.3365 33.0644 H 26.1431 Z"
      />
      <path
        fill="#ebebeb"
        d="M 158.009 124.089 H 95.8122 C 95.3247 124.088 94.8574 123.894 94.5126 123.549 C 94.1679 123.204 93.9739 122.737 93.973 122.249 V 34.8231 C 93.9781 34.3383 94.174 33.8752 94.5183 33.5339 C 94.8626 33.1926 95.3274 33.0008 95.8122 33 H 158.009 C 158.493 33.0017 158.956 33.194 159.3 33.5352 C 159.643 33.8765 159.837 34.3392 159.842 34.8231 V 122.249 C 159.842 122.736 159.649 123.203 159.305 123.548 C 158.962 123.892 158.496 124.087 158.009 124.089 V 124.089 Z M 95.8122 33.0644 C 95.346 33.0653 94.8992 33.2508 94.5696 33.5805 C 94.24 33.9101 94.0544 34.3569 94.0535 34.8231 V 122.249 C 94.0544 122.716 94.24 123.162 94.5696 123.492 C 94.8992 123.822 95.346 124.007 95.8122 124.008 H 158.009 C 158.475 124.007 158.922 123.822 159.251 123.492 C 159.581 123.162 159.767 122.716 159.768 122.249 V 34.8231 C 159.767 34.3569 159.581 33.9101 159.251 33.5805 C 158.922 33.2508 158.475 33.0653 158.009 33.0644 H 95.8122 Z"
      />
      <path
        fill="#e6e6e6"
        d="M 118.018 110.435 H 127.674 V 82.2806 H 118.018 V 110.435 Z"
      />
      <path
        fill="#f5f5f5"
        d="M 147.699 82.2839 H 127.674 V 110.438 H 147.699 V 82.2839 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 141.901 103.471 H 147.699 V 99.6448 H 141.901 V 103.471 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 141.901 108.358 H 147.699 V 104.531 H 141.901 V 108.358 Z"
      />
      <path
        fill="#e0e0e0"
        d="M 117.589 82.2839 H 127.526 V 80.857 H 117.589 V 82.2839 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 148.124 80.857 H 127.526 V 82.2839 H 148.124 V 80.857 Z"
      />
      <path
        fill="#dce8f4"
        d="M 110.741 138.454 H 124.169 V 112.422 H 110.741 V 138.454 Z"
      />
      <path
        fill="#dce8f4"
        d="M 152.011 112.422 H 124.169 V 138.454 H 152.011 V 112.422 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 126.434 123.879 H 136.055 V 115.402 H 126.434 V 123.879 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 126.434 134.708 H 136.055 V 126.231 H 126.434 V 134.708 Z"
      />
      <path
        fill="#e0e0e0"
        d="M 110.146 112.419 H 123.961 V 110.435 H 110.146 V 112.419 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 152.601 110.438 H 123.961 V 112.422 H 152.601 V 110.438 Z"
      />
      <path
        fill="#dce8f4"
        d="M 95.7318 40.8076 H 69.9061 V 138.454 H 95.7318 V 40.8076 Z"
      />
      <path
        fill="#fafafa"
        d="M 93.9895 136.48 V 42.782 H 71.6522 V 136.48 H 93.9895 Z"
      />
      <path
        fill="#dce8f4"
        d="M 69.1333 40.8076 H 43.3077 V 138.454 H 69.1333 V 40.8076 Z"
      />
      <path
        fill="#e0e0e0"
        d="M 69.1299 138.071 H 69.9061 L 69.9061 41.1942 H 69.1299 L 69.1299 138.071 Z"
      />
      <path
        fill="#fafafa"
        d="M 67.3877 136.48 V 42.782 H 45.0504 L 45.0504 136.48 H 67.3877 Z"
      />
      <path
        fill="#dce8f4"
        d="M 107.476 39.2004 H 31.5581 C 31.3446 39.2004 31.1716 39.3734 31.1716 39.5869 V 39.6899 C 31.1716 39.9034 31.3446 40.0765 31.5581 40.0765 H 107.476 C 107.69 40.0765 107.863 39.9034 107.863 39.6899 V 39.5869 C 107.863 39.3734 107.69 39.2004 107.476 39.2004 Z"
      />
      <path
        fill="#e0e0e0"
        d="M 93.9861 44.647 V 42.782 H 71.6487 V 44.647 H 93.9861 Z"
      />
      <path
        fill="white"
        d="M 93.9861 61.5473 V 42.782 H 71.6488 V 61.5473 H 93.9861 Z"
      />
      <path
        fill="white"
        d="M 67.3877 83.8073 V 42.782 H 45.0504 V 83.8073 H 67.3877 Z"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          fill="#f5f5f5"
          d="M 98.8116 37.1518 H 91.4292 V 137.253 H 98.8116 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f0f0f0"
          d="M 96.8631 37.1518 H 93.3781 V 137.253 H 96.8631 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f5f5f5"
          d="M 102.771 37.1518 H 95.3883 V 137.253 H 102.771 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f0f0f0"
          d="M 100.822 37.1518 H 97.3367 V 137.253 H 100.822 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f5f5f5"
          d="M 106.462 37.1518 H 99.0798 V 137.253 H 106.462 V 37.1518 Z"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          fill="#dce8f4"
          d="M 54.4104 37.1518 H 32.35 V 137.253 H 54.4104 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f0f0f0"
          d="M 48.5871 37.1518 H 38.1737 V 137.253 H 48.5871 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f5f5f5"
          d="M 66.2448 37.1518 H 44.1844 V 137.253 H 66.2448 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#f0f0f0"
          d="M 60.421 37.1518 H 50.0077 V 137.253 H 60.421 V 37.1518 Z"
        />
        <path
          opacity="0.5"
          fill="#dce8f4"
          d="M 77.2766 37.1518 H 55.2162 V 137.253 H 77.2766 V 37.1518 Z"
        />
      </g>
      <path
        fill="#dce8f4"
        d="M 120.243 69.8155 H 148.562 V 41.2005 H 120.243 V 69.8155 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 129.017 62.6328 H 139.782 C 140.703 62.6328 141.45 61.8858 141.45 60.9643 V 50.0517 C 141.45 49.1303 140.703 48.3833 139.782 48.3833 H 129.017 C 128.096 48.3833 127.349 49.1303 127.349 50.0517 V 60.9643 C 127.349 61.8858 128.096 62.6328 129.017 62.6328 Z"
      />
      <path
        fill="white"
        d="M 135.45 55.6819 H 142.974 C 143.618 55.6819 144.14 55.1599 144.14 54.516 V 46.8887 C 144.14 46.2448 143.618 45.7228 142.974 45.7228 H 135.45 C 134.806 45.7228 134.284 46.2448 134.284 46.8887 V 54.516 C 134.284 55.1599 134.806 55.6819 135.45 55.6819 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 129.205 66.3756 H 134.426 C 134.872 66.3756 135.234 66.0136 135.234 65.5671 V 60.2751 C 135.234 59.8286 134.872 59.4666 134.426 59.4666 H 129.205 C 128.758 59.4666 128.396 59.8286 128.396 60.2751 V 65.5671 C 128.396 66.0136 128.758 66.3756 129.205 66.3756 Z"
      />
      <path
        fill="#f0f0f0"
        d="M 120.24 41.2005 H 118.659 V 69.8155 H 120.24 V 41.2005 Z"
      />
      <path
        fill="#dce8f4"
        d="M 92.5238 153 C 127.015 153 154.975 151.368 154.975 149.354 C 154.975 147.34 127.015 145.708 92.5238 145.708 C 58.033 145.708 30.0727 147.34 30.0727 149.354 C 30.0727 151.368 58.033 153 92.5238 153 Z"
      />
      <path
        fill="#407bff"
        d="M 135.611 52.3676 L 135.289 52.3483 C 135.331 51.727 135.244 51.1038 135.034 50.5176 C 134.824 49.9314 134.495 49.3948 134.068 48.9414 C 133.641 48.4879 133.126 48.1274 132.553 47.8823 C 131.981 47.6372 131.364 47.5128 130.741 47.5169 H 124.908 V 47.1722 H 130.735 C 131.404 47.1675 132.067 47.3011 132.682 47.5647 C 133.297 47.8283 133.851 48.2163 134.309 48.7041 C 134.766 49.192 135.119 49.7693 135.343 50.3997 C 135.567 51.0302 135.658 51.7002 135.611 52.3676 Z"
      />
      <path
        fill="#407bff"
        d="M 135.128 54.6397 L 134.942 57.7005 L 135.273 57.7206 L 135.459 54.6598 L 135.128 54.6397 Z"
      />
      <path
        fill="#407bff"
        d="M 125.623 131.165 H 86.8269 C 86.3153 131.171 85.8083 131.07 85.3378 130.869 C 84.8673 130.668 84.4436 130.372 84.0936 129.999 C 83.7435 129.626 83.4748 129.184 83.3044 128.702 C 83.1339 128.219 83.0655 127.707 83.1034 127.197 L 87.7255 52.2967 C 87.8072 51.2294 88.2844 50.2309 89.0637 49.4969 C 89.843 48.7629 90.8682 48.3463 91.9385 48.3285 H 130.735 C 131.246 48.324 131.753 48.4256 132.222 48.6267 C 132.692 48.8278 133.115 49.1242 133.465 49.4971 C 133.815 49.8699 134.083 50.3113 134.253 50.7931 C 134.424 51.275 134.493 51.787 134.455 52.2967 L 129.833 127.197 C 129.754 128.265 129.277 129.264 128.498 129.998 C 127.719 130.732 126.694 131.149 125.623 131.165 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 102.435 65.3899 H 93.9706 C 93.6771 65.393 93.3862 65.335 93.1163 65.2198 C 92.8464 65.1045 92.6033 64.9345 92.4026 64.7204 C 92.2018 64.5063 92.0477 64.2529 91.95 63.9761 C 91.8523 63.6994 91.8132 63.4054 91.8351 63.1127 L 92.2699 56.0717 C 92.3156 55.4591 92.5889 54.8857 93.0359 54.4644 C 93.4829 54.043 94.0714 53.804 94.6856 53.7945 H 103.153 C 103.447 53.7919 103.737 53.8502 104.007 53.9656 C 104.277 54.081 104.52 54.2511 104.72 54.4651 C 104.921 54.679 105.075 54.9323 105.173 55.2088 C 105.271 55.4853 105.31 55.7791 105.289 56.0717 L 104.854 63.1127 C 104.808 63.7256 104.534 64.299 104.086 64.7203 C 103.639 65.1416 103.05 65.3804 102.435 65.3899 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 104.893 124.569 C 105.239 124.564 105.57 124.429 105.821 124.19 C 106.071 123.951 106.222 123.626 106.242 123.28 L 107.141 108.763 C 107.153 108.6 107.131 108.435 107.076 108.281 C 107.021 108.126 106.935 107.984 106.823 107.865 C 106.71 107.745 106.574 107.65 106.423 107.586 C 106.272 107.522 106.11 107.489 105.946 107.491 H 84.3171 L 83.267 124.562 L 104.893 124.569 Z"
      />
      <path
        fill="#407bff"
        d="M 82.2656 100.962 H 75.6498 C 75.4124 100.968 75.1856 101.062 75.0127 101.225 C 74.8398 101.387 74.733 101.608 74.7125 101.845 L 74.2519 109.311 C 74.2434 109.424 74.2586 109.538 74.2964 109.646 C 74.3343 109.753 74.394 109.851 74.4719 109.934 C 74.5497 110.017 74.6439 110.083 74.7485 110.128 C 74.8531 110.172 74.9659 110.195 75.0796 110.193 H 81.6955 C 81.9342 110.192 82.1634 110.1 82.3372 109.936 C 82.511 109.772 82.6166 109.549 82.6328 109.311 L 83.0934 101.845 C 83.1024 101.731 83.0875 101.617 83.0499 101.51 C 83.0122 101.402 82.9525 101.304 82.8745 101.221 C 82.7966 101.137 82.7022 101.072 82.5973 101.027 C 82.4925 100.983 82.3795 100.961 82.2656 100.962 V 100.962 Z"
      />
      <path
        opacity="0.4"
        fill="white"
        d="M 82.2656 100.962 H 75.6498 C 75.4124 100.968 75.1856 101.062 75.0127 101.225 C 74.8398 101.387 74.733 101.608 74.7125 101.845 L 74.2519 109.311 C 74.2434 109.424 74.2586 109.538 74.2964 109.646 C 74.3343 109.753 74.394 109.851 74.4719 109.934 C 74.5497 110.017 74.6439 110.083 74.7485 110.128 C 74.8531 110.172 74.9659 110.195 75.0796 110.193 H 81.6955 C 81.9342 110.192 82.1634 110.1 82.3372 109.936 C 82.511 109.772 82.6166 109.549 82.6328 109.311 L 83.0934 101.845 C 83.1024 101.731 83.0875 101.617 83.0499 101.51 C 83.0122 101.402 82.9525 101.304 82.8745 101.221 C 82.7966 101.137 82.7022 101.072 82.5973 101.027 C 82.4925 100.983 82.3795 100.961 82.2656 100.962 V 100.962 Z"
      />
      <path
        fill="white"
        d="M 130.651 49.9841 C 130.947 49.9791 131.241 50.0361 131.514 50.1515 C 131.787 50.2669 132.032 50.4381 132.235 50.6541 C 132.438 50.8701 132.593 51.1261 132.691 51.4057 C 132.788 51.6853 132.827 51.9822 132.803 52.2774 L 131.102 79.8424 C 131.085 80.1014 130.971 80.3446 130.783 80.5236 C 130.595 80.7026 130.347 80.8044 130.088 80.8087 H 88.5372 C 88.4188 80.8104 88.3013 80.7874 88.1923 80.7411 C 88.0833 80.6948 87.9852 80.6262 87.9042 80.5398 C 87.8233 80.4534 87.7613 80.351 87.7222 80.2392 C 87.6831 80.1275 87.6677 80.0087 87.6772 79.8907 L 89.3778 52.3258 C 89.4252 51.6975 89.7061 51.1098 90.1651 50.6782 C 90.6241 50.2466 91.228 50.0025 91.858 49.9938 H 130.651 V 49.9841 Z M 130.651 49.6717 H 91.858 C 91.1438 49.6811 90.4591 49.9579 89.9391 50.4474 C 89.4192 50.937 89.1016 51.6038 89.0493 52.3161 L 87.3486 79.881 C 87.3361 80.0439 87.3576 80.2077 87.4119 80.3618 C 87.4661 80.5159 87.5519 80.657 87.6637 80.7761 C 87.7756 80.8952 87.911 80.9897 88.0614 81.0535 C 88.2118 81.1174 88.3738 81.1492 88.5372 81.1469 H 130.088 C 130.429 81.1422 130.757 81.0097 131.007 80.7754 C 131.256 80.5412 131.408 80.2221 131.434 79.881 L 133.135 52.3161 C 133.161 51.9758 133.115 51.6339 133.002 51.312 C 132.888 50.9902 132.709 50.6955 132.476 50.4467 C 132.242 50.1979 131.959 50.0005 131.645 49.8671 C 131.331 49.7337 130.992 49.6671 130.651 49.6717 V 49.6717 Z"
      />
      <path
        fill="white"
        d="M 109.866 84.3678 H 88.7399 C 88.2886 84.3739 87.8559 84.5489 87.5273 84.8584 C 87.1987 85.1679 86.9982 85.5893 86.9651 86.0395 L 86.0826 100.17 C 86.066 100.384 86.0944 100.6 86.1658 100.803 C 86.2372 101.006 86.3502 101.192 86.4974 101.349 C 86.6447 101.506 86.8231 101.631 87.0212 101.715 C 87.2193 101.799 87.4328 101.841 87.648 101.838 H 108.774 C 109.225 101.833 109.658 101.659 109.987 101.35 C 110.315 101.041 110.516 100.62 110.549 100.17 L 111.422 86.0395 C 111.438 85.8257 111.409 85.6108 111.338 85.4085 C 111.267 85.2061 111.155 85.0207 111.009 84.8637 C 110.863 84.7067 110.686 84.5816 110.49 84.4963 C 110.293 84.411 110.081 84.3672 109.866 84.3678 V 84.3678 Z"
      />
      <path
        fill="white"
        d="M 103.533 104.866 H 82.4038 C 81.9531 104.871 81.5208 105.046 81.1927 105.355 C 80.8646 105.664 80.6646 106.085 80.6323 106.534 L 79.7594 120.665 C 79.7429 120.88 79.7712 121.095 79.8426 121.299 C 79.914 121.502 80.0269 121.688 80.1741 121.845 C 80.3213 122.002 80.4997 122.127 80.6978 122.212 C 80.8959 122.296 81.1094 122.339 81.3248 122.336 H 102.454 C 102.905 122.33 103.338 122.155 103.666 121.846 C 103.994 121.536 104.194 121.115 104.226 120.665 L 105.086 106.534 C 105.102 106.321 105.074 106.106 105.004 105.904 C 104.933 105.702 104.821 105.517 104.675 105.36 C 104.529 105.203 104.353 105.078 104.156 104.993 C 103.959 104.908 103.747 104.865 103.533 104.866 V 104.866 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 120.386 94.8424 C 119.994 94.847 119.619 94.9986 119.334 95.267 C 119.048 95.5355 118.874 95.9012 118.846 96.2918 L 118.089 108.551 C 118.075 108.737 118.1 108.924 118.163 109.1 C 118.225 109.276 118.323 109.437 118.451 109.573 C 118.579 109.709 118.733 109.817 118.905 109.89 C 119.077 109.963 119.262 109.999 119.449 109.997 H 130.896 L 131.82 94.8424 H 120.386 Z"
      />
      <path
        fill="#407bff"
        d="M 136.181 92.1561 H 117.619 C 117.223 92.1616 116.843 92.3155 116.555 92.5875 C 116.267 92.8595 116.091 93.2297 116.063 93.6249 L 115.297 106.038 C 115.282 106.227 115.307 106.417 115.369 106.596 C 115.432 106.774 115.531 106.938 115.66 107.076 C 115.79 107.214 115.947 107.324 116.121 107.398 C 116.295 107.472 116.483 107.51 116.672 107.507 H 135.234 C 135.631 107.502 136.011 107.348 136.299 107.076 C 136.588 106.804 136.764 106.434 136.793 106.038 L 137.56 93.6249 C 137.574 93.436 137.549 93.2462 137.486 93.0675 C 137.423 92.8888 137.323 92.7252 137.194 92.5871 C 137.064 92.4489 136.907 92.3393 136.733 92.2651 C 136.559 92.1909 136.371 92.1538 136.181 92.1561 V 92.1561 Z"
      />
      <path
        opacity="0.7"
        fill="white"
        d="M 136.181 92.1561 H 117.619 C 117.223 92.1616 116.843 92.3155 116.555 92.5875 C 116.267 92.8595 116.091 93.2297 116.063 93.6249 L 115.297 106.038 C 115.282 106.227 115.307 106.417 115.369 106.596 C 115.432 106.774 115.531 106.938 115.66 107.076 C 115.79 107.214 115.947 107.324 116.121 107.398 C 116.295 107.472 116.483 107.51 116.672 107.507 H 135.234 C 135.631 107.502 136.011 107.348 136.299 107.076 C 136.588 106.804 136.764 106.434 136.793 106.038 L 137.56 93.6249 C 137.574 93.436 137.549 93.2462 137.486 93.0675 C 137.423 92.8888 137.323 92.7252 137.194 92.5871 C 137.064 92.4489 136.907 92.3393 136.733 92.2651 C 136.559 92.1909 136.371 92.1538 136.181 92.1561 V 92.1561 Z"
      />
      <path
        fill="#407bff"
        d="M 150.05 72.0992 H 139.051 C 138.655 72.1046 138.275 72.2579 137.986 72.5292 C 137.698 72.8004 137.521 73.1699 137.492 73.5648 L 136.725 85.9816 C 136.711 86.1702 136.737 86.3597 136.8 86.538 C 136.863 86.7163 136.963 86.8795 137.092 87.0172 C 137.222 87.155 137.379 87.2644 137.553 87.3384 C 137.727 87.4124 137.914 87.4494 138.104 87.4471 H 149.103 C 149.499 87.4417 149.878 87.2882 150.166 87.0169 C 150.454 86.7456 150.63 86.3762 150.659 85.9816 L 151.425 73.5648 C 151.44 73.3763 151.415 73.1869 151.352 73.0086 C 151.289 72.8303 151.19 72.6671 151.06 72.5292 C 150.931 72.3914 150.774 72.282 150.6 72.208 C 150.427 72.1339 150.239 72.0969 150.05 72.0992 V 72.0992 Z"
      />
      <path
        opacity="0.7"
        fill="white"
        d="M 150.05 72.0992 H 139.051 C 138.655 72.1046 138.275 72.2579 137.986 72.5292 C 137.698 72.8004 137.521 73.1699 137.492 73.5648 L 136.725 85.9816 C 136.711 86.1702 136.737 86.3597 136.8 86.538 C 136.863 86.7163 136.963 86.8795 137.092 87.0172 C 137.222 87.155 137.379 87.2644 137.553 87.3384 C 137.727 87.4124 137.914 87.4494 138.104 87.4471 H 149.103 C 149.499 87.4417 149.878 87.2882 150.166 87.0169 C 150.454 86.7456 150.63 86.3762 150.659 85.9816 L 151.425 73.5648 C 151.44 73.3763 151.415 73.1869 151.352 73.0086 C 151.289 72.8303 151.19 72.6671 151.06 72.5292 C 150.931 72.3914 150.774 72.282 150.6 72.208 C 150.427 72.1339 150.239 72.0969 150.05 72.0992 V 72.0992 Z"
      />
      <path
        fill="#407bff"
        d="M 140.047 110.406 H 135.47 C 135.305 110.41 135.148 110.474 135.029 110.587 C 134.91 110.701 134.837 110.854 134.825 111.018 L 134.503 116.172 C 134.497 116.25 134.508 116.329 134.534 116.403 C 134.561 116.477 134.602 116.545 134.656 116.602 C 134.71 116.659 134.775 116.705 134.848 116.736 C 134.92 116.766 134.998 116.781 135.077 116.78 H 139.654 C 139.818 116.781 139.977 116.718 140.097 116.604 C 140.217 116.491 140.289 116.336 140.298 116.172 L 140.62 111.018 C 140.626 110.939 140.616 110.86 140.59 110.786 C 140.564 110.711 140.522 110.643 140.468 110.585 C 140.414 110.528 140.349 110.482 140.276 110.451 C 140.204 110.42 140.125 110.405 140.047 110.406 V 110.406 Z"
      />
      <path
        opacity="0.4"
        fill="white"
        d="M 140.047 110.406 H 135.47 C 135.305 110.41 135.148 110.474 135.029 110.587 C 134.91 110.701 134.837 110.854 134.825 111.018 L 134.503 116.172 C 134.497 116.25 134.508 116.329 134.534 116.403 C 134.561 116.477 134.602 116.545 134.656 116.602 C 134.71 116.659 134.775 116.705 134.848 116.736 C 134.92 116.766 134.998 116.781 135.077 116.78 H 139.654 C 139.818 116.781 139.977 116.718 140.097 116.604 C 140.217 116.491 140.289 116.336 140.298 116.172 L 140.62 111.018 C 140.626 110.939 140.616 110.86 140.59 110.786 C 140.564 110.711 140.522 110.643 140.468 110.585 C 140.414 110.528 140.349 110.482 140.276 110.451 C 140.204 110.42 140.125 110.405 140.047 110.406 V 110.406 Z"
      />
      <path
        fill="#407bff"
        d="M 117.467 87.8526 C 122.349 83.1182 122.699 75.5596 118.248 70.9699 C 113.797 66.3803 106.232 66.4976 101.35 71.232 C 96.4675 75.9663 96.1178 83.525 100.569 88.1146 C 105.019 92.7043 112.585 92.587 117.467 87.8526 Z"
      />
      <path
        fill="white"
        d="M 116.737 87.0988 C 121.176 82.7941 121.493 75.9213 117.446 71.748 C 113.399 67.5746 106.52 67.6811 102.081 71.9858 C 97.6418 76.2904 97.3241 83.1632 101.371 87.3365 C 105.418 91.5099 112.298 91.4034 116.737 87.0988 Z"
      />
      <path
        fill="#407bff"
        d="M 112.287 79.4091 C 113.921 77.824 114.038 75.2925 112.547 73.755 C 111.056 72.2174 108.522 72.256 106.887 73.8411 C 105.253 75.4263 105.136 77.9577 106.627 79.4953 C 108.118 81.0328 110.652 80.9942 112.287 79.4091 Z"
      />
      <path
        fill="#407bff"
        d="M 109.241 82.2807 C 106.123 82.2807 103.33 83.495 101.797 85.2923 C 102.532 86.4889 103.566 87.4741 104.796 88.1517 C 106.026 88.8292 107.411 89.1757 108.816 89.1574 C 110.28 89.1448 111.721 88.7885 113.023 88.1171 C 114.325 87.4458 115.451 86.4782 116.311 85.2923 C 115.003 83.4886 112.355 82.2807 109.241 82.2807 Z"
      />
      <path
        fill="white"
        d="M 100.899 63.8407 H 92.4182 C 92.1249 63.8432 91.8342 63.785 91.5645 63.6695 C 91.2948 63.5541 91.052 63.3841 90.8513 63.1701 C 90.6507 62.9561 90.4965 62.7029 90.3987 62.4264 C 90.3008 62.1498 90.2613 61.856 90.2827 61.5635 L 90.7175 54.5224 C 90.764 53.9095 91.038 53.3362 91.4855 52.9149 C 91.933 52.4935 92.5219 52.2547 93.1364 52.2452 H 101.598 C 101.891 52.2422 102.182 52.3001 102.452 52.4154 C 102.722 52.5306 102.965 52.7007 103.166 52.9147 C 103.367 53.1288 103.521 53.3823 103.618 53.659 C 103.716 53.9357 103.755 54.2298 103.733 54.5224 L 103.302 61.5635 C 103.256 62.1736 102.984 62.7448 102.54 63.1657 C 102.096 63.5866 101.511 63.8272 100.899 63.8407 V 63.8407 Z"
      />
      <path
        fill="white"
        d="M 128.329 56.5164 H 108.127 C 108.002 56.5176 107.879 56.493 107.765 56.4441 C 107.65 56.3951 107.547 56.323 107.462 56.2321 C 107.377 56.1413 107.312 56.0337 107.27 55.9163 C 107.229 55.7989 107.212 55.6742 107.222 55.5501 L 107.296 54.3197 C 107.316 54.0596 107.432 53.8163 107.623 53.6375 C 107.813 53.4588 108.063 53.3575 108.323 53.3534 H 128.525 C 128.65 53.3522 128.773 53.3768 128.888 53.4257 C 129.003 53.4746 129.106 53.5468 129.191 53.6375 C 129.276 53.7283 129.342 53.8358 129.384 53.9532 C 129.426 54.0706 129.442 54.1954 129.434 54.3197 L 129.356 55.5501 C 129.336 55.8102 129.22 56.0535 129.03 56.2323 C 128.84 56.411 128.59 56.5123 128.329 56.5164 V 56.5164 Z"
      />
      <path
        fill="white"
        d="M 128 61.8341 H 107.799 C 107.674 61.8349 107.551 61.81 107.437 61.7609 C 107.323 61.7118 107.22 61.6396 107.135 61.5488 C 107.05 61.4581 106.985 61.3507 106.943 61.2336 C 106.901 61.1164 106.885 60.9919 106.893 60.8679 L 106.968 59.6374 C 106.988 59.3776 107.105 59.1347 107.295 58.9561 C 107.485 58.7775 107.734 58.6759 107.995 58.6712 H 128.197 C 128.321 58.67 128.445 58.6946 128.56 58.7435 C 128.674 58.7924 128.777 58.8645 128.863 58.9553 C 128.948 59.0461 129.014 59.1536 129.055 59.271 C 129.097 59.3884 129.114 59.5132 129.105 59.6374 L 129.028 60.8679 C 129.009 61.1283 128.892 61.372 128.702 61.5509 C 128.512 61.7297 128.262 61.8308 128 61.8341 V 61.8341 Z"
      />
      <path
        fill="white"
        d="M 134.693 97.0165 H 118.508 C 118.425 97.0178 118.343 97.0016 118.266 96.969 C 118.19 96.9365 118.121 96.8883 118.065 96.8276 C 118.009 96.7668 117.965 96.695 117.938 96.6165 C 117.912 96.5381 117.901 96.4549 117.909 96.3724 L 117.938 95.9118 C 117.949 95.7385 118.025 95.576 118.151 95.4565 C 118.277 95.3371 118.444 95.2696 118.617 95.2676 H 134.802 C 134.885 95.2663 134.968 95.2825 135.044 95.3151 C 135.12 95.3476 135.189 95.3958 135.245 95.4565 C 135.302 95.5173 135.345 95.5891 135.372 95.6676 C 135.399 95.746 135.409 95.8292 135.402 95.9118 L 135.373 96.3724 C 135.361 96.5456 135.285 96.7081 135.159 96.8276 C 135.033 96.947 134.867 97.0145 134.693 97.0165 Z"
      />
      <path
        fill="white"
        d="M 134.468 100.701 H 118.292 C 118.209 100.703 118.127 100.686 118.05 100.654 C 117.974 100.621 117.905 100.573 117.849 100.512 C 117.792 100.452 117.748 100.38 117.721 100.302 C 117.694 100.223 117.683 100.14 117.69 100.057 L 117.719 99.5998 C 117.73 99.4266 117.807 99.264 117.933 99.1446 C 118.059 99.0251 118.225 98.9577 118.398 98.9556 H 134.584 C 134.667 98.9544 134.749 98.9706 134.825 99.0031 C 134.901 99.0357 134.97 99.0839 135.027 99.1446 C 135.083 99.2053 135.126 99.2772 135.153 99.3556 C 135.18 99.434 135.19 99.5172 135.183 99.5998 L 135.157 100.057 C 135.145 100.232 135.067 100.395 134.939 100.515 C 134.811 100.634 134.643 100.701 134.468 100.701 V 100.701 Z"
      />
      <path
        fill="white"
        d="M 134.239 104.386 H 118.054 C 117.971 104.387 117.889 104.371 117.812 104.339 C 117.736 104.306 117.667 104.258 117.611 104.197 C 117.555 104.136 117.511 104.064 117.485 103.986 C 117.458 103.908 117.447 103.824 117.455 103.742 L 117.484 103.281 C 117.494 103.108 117.57 102.946 117.695 102.826 C 117.821 102.707 117.987 102.639 118.16 102.637 H 134.345 C 134.428 102.636 134.511 102.652 134.587 102.685 C 134.663 102.717 134.732 102.765 134.789 102.826 C 134.846 102.887 134.889 102.958 134.916 103.037 C 134.944 103.115 134.954 103.199 134.948 103.281 L 134.919 103.742 C 134.907 103.915 134.831 104.078 134.705 104.197 C 134.579 104.317 134.413 104.384 134.239 104.386 V 104.386 Z"
      />
      <path
        fill="white"
        d="M 124.895 127.769 C 125.529 127.153 125.575 126.171 124.997 125.575 C 124.419 124.978 123.435 124.994 122.801 125.609 C 122.166 126.225 122.12 127.207 122.698 127.803 C 123.277 128.4 124.26 128.384 124.895 127.769 Z"
      />
      <path
        fill="white"
        d="M 125.742 121.196 C 125.711 121.614 125.524 122.005 125.219 122.293 C 124.914 122.58 124.512 122.743 124.093 122.749 C 123.893 122.751 123.694 122.712 123.51 122.633 C 123.326 122.555 123.16 122.439 123.023 122.293 C 122.886 122.147 122.781 121.974 122.715 121.785 C 122.648 121.596 122.622 121.396 122.637 121.196 C 122.667 120.778 122.852 120.387 123.157 120.099 C 123.462 119.812 123.864 119.649 124.283 119.644 C 124.483 119.641 124.682 119.68 124.867 119.758 C 125.051 119.836 125.217 119.952 125.355 120.098 C 125.492 120.244 125.597 120.417 125.664 120.606 C 125.731 120.796 125.757 120.996 125.742 121.196 V 121.196 Z"
      />
      <path
        fill="white"
        d="M 126.08 115.698 C 126.049 116.116 125.863 116.508 125.557 116.795 C 125.252 117.083 124.85 117.245 124.431 117.251 C 124.231 117.253 124.032 117.214 123.848 117.135 C 123.664 117.057 123.498 116.941 123.361 116.795 C 123.224 116.649 123.119 116.476 123.053 116.287 C 122.986 116.098 122.96 115.898 122.975 115.698 C 123.005 115.28 123.192 114.888 123.497 114.601 C 123.803 114.313 124.205 114.151 124.624 114.146 C 124.824 114.143 125.023 114.182 125.207 114.261 C 125.391 114.339 125.557 114.455 125.694 114.601 C 125.83 114.747 125.936 114.92 126.002 115.109 C 126.069 115.298 126.095 115.498 126.08 115.698 V 115.698 Z"
      />
      <path
        fill="white"
        d="M 120.21 127.769 C 120.844 127.153 120.89 126.171 120.312 125.574 C 119.733 124.978 118.75 124.993 118.115 125.609 C 117.481 126.224 117.435 127.207 118.013 127.803 C 118.592 128.4 119.575 128.384 120.21 127.769 Z"
      />
      <path
        fill="white"
        d="M 121.055 121.196 C 121.024 121.614 120.837 122.005 120.532 122.293 C 120.227 122.58 119.825 122.743 119.406 122.749 C 119.206 122.751 119.008 122.712 118.823 122.633 C 118.639 122.555 118.473 122.439 118.337 122.293 C 118.2 122.147 118.095 121.974 118.028 121.785 C 117.962 121.596 117.935 121.396 117.95 121.196 C 117.98 120.778 118.166 120.387 118.471 120.099 C 118.775 119.812 119.177 119.649 119.596 119.644 C 119.797 119.641 119.995 119.68 120.18 119.758 C 120.364 119.836 120.531 119.952 120.668 120.098 C 120.805 120.244 120.911 120.417 120.977 120.606 C 121.044 120.796 121.07 120.996 121.055 121.196 V 121.196 Z"
      />
      <path
        fill="white"
        d="M 121.394 115.698 C 121.363 116.116 121.177 116.508 120.872 116.795 C 120.566 117.083 120.164 117.245 119.745 117.251 C 119.545 117.253 119.346 117.214 119.162 117.135 C 118.978 117.057 118.812 116.941 118.675 116.795 C 118.538 116.649 118.433 116.476 118.367 116.287 C 118.3 116.098 118.274 115.898 118.289 115.698 C 118.32 115.28 118.506 114.888 118.811 114.601 C 119.117 114.313 119.519 114.151 119.938 114.146 C 120.138 114.143 120.337 114.182 120.521 114.261 C 120.705 114.339 120.871 114.455 121.008 114.601 C 121.145 114.747 121.25 114.92 121.316 115.109 C 121.383 115.298 121.409 115.498 121.394 115.698 V 115.698 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 56.1882 80.2998 L 58.008 81.717 L 55.6052 82.9796 C 55.6052 82.9796 54.481 81.7718 55.0512 80.7443 L 56.1882 80.2998 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 58.0888 84.1746 L 56.984 84.4967 C 56.8144 84.5483 56.6318 84.5361 56.4705 84.4626 C 56.3092 84.389 56.1803 84.2592 56.1079 84.0973 L 55.6055 82.9668 L 58.0083 81.7042 L 58.5623 83.2148 C 58.599 83.3086 58.6156 83.409 58.6113 83.5096 C 58.607 83.6102 58.5818 83.7088 58.5372 83.7991 C 58.4927 83.8894 58.4298 83.9695 58.3526 84.0341 C 58.2754 84.0988 58.1856 84.1466 58.0888 84.1746 V 84.1746 Z"
      />
      <path
        fill="#407bff"
        d="M 63.8289 67.3193 L 62.8626 67.9216 C 62.5406 68.131 62.2023 68.3436 61.8738 68.5658 C 61.2296 69.0007 60.5854 69.4387 59.9412 69.8961 C 58.6969 70.769 57.523 71.7383 56.4304 72.7949 L 56.2371 72.9882 L 56.1888 73.0365 C 56.1899 73.0301 56.1899 73.0236 56.1888 73.0172 C 56.1888 73.0011 56.1534 73.043 56.1212 73.117 C 56.0289 73.3541 55.9736 73.604 55.9569 73.8579 C 55.9074 74.5698 55.9257 75.2849 56.0117 75.9933 C 56.1598 77.5072 56.4755 79.1048 56.7847 80.6541 L 54.9488 81.3498 C 54.1387 79.8254 53.5003 78.2158 53.0452 76.5506 C 52.7933 75.6523 52.6431 74.7285 52.5975 73.7967 C 52.5729 73.2279 52.6293 72.6585 52.7649 72.1057 C 52.8585 71.7462 53.0039 71.4022 53.1966 71.0846 C 53.2522 70.9918 53.3135 70.9025 53.3802 70.8173 L 53.4703 70.6981 L 53.5251 70.6337 L 53.7441 70.3696 C 54.034 70.012 54.34 69.6899 54.6428 69.3582 C 54.9455 69.0264 55.2676 68.714 55.5897 68.4338 C 56.2339 67.825 56.8974 67.2774 57.5738 66.7395 C 58.2502 66.2016 58.9492 65.7056 59.6642 65.2289 C 60.0185 64.9906 60.3793 64.7619 60.7433 64.5332 C 61.1072 64.3045 61.4648 64.0951 61.8803 63.8664 L 63.8289 67.3193 Z"
      />
      <path
        fill="#263238"
        d="M 56.6331 80.2708 C 55.9985 80.5419 55.345 80.7669 54.678 80.944 C 54.6251 80.9614 54.5799 80.9966 54.55 81.0435 C 54.52 81.0905 54.5072 81.1464 54.5137 81.2017 C 54.5556 81.5238 54.62 81.7428 54.794 81.7621 C 54.9679 81.7814 56.894 81.1179 57.0293 80.8731 C 57.1066 80.7346 57.0293 80.5285 56.9423 80.3706 C 56.9136 80.3174 56.8654 80.2773 56.8078 80.2587 C 56.7502 80.2401 56.6876 80.2444 56.6331 80.2708 V 80.2708 Z"
      />
      <path
        fill="#407bff"
        d="M 65.7321 78.8858 L 61.867 69.3905 C 61.7236 69.0501 61.4578 68.7759 61.122 68.622 C 60.7863 68.4681 60.405 68.4458 60.0536 68.5595 L 49.0669 72.2475 C 48.9005 72.3036 48.7474 72.3931 48.6168 72.5106 C 48.4863 72.628 48.3812 72.7709 48.3079 72.9305 C 48.2346 73.09 48.1947 73.2629 48.1906 73.4385 C 48.1865 73.614 48.2184 73.7885 48.2842 73.9513 L 52.1494 83.4467 C 52.2927 83.7871 52.5586 84.0613 52.8943 84.2152 C 53.2301 84.369 53.6114 84.3914 53.9628 84.2777 L 64.9495 80.5897 C 65.1158 80.5336 65.269 80.4441 65.3995 80.3266 C 65.5301 80.2092 65.6352 80.0663 65.7085 79.9067 C 65.7818 79.7471 65.8217 79.5743 65.8258 79.3987 C 65.8298 79.2232 65.798 79.0486 65.7321 78.8858 V 78.8858 Z"
      />
      <path
        opacity="0.7"
        fill="white"
        d="M 65.7321 78.8858 L 61.867 69.3905 C 61.7236 69.0501 61.4578 68.7759 61.122 68.622 C 60.7863 68.4681 60.405 68.4458 60.0536 68.5595 L 49.0669 72.2475 C 48.9005 72.3036 48.7474 72.3931 48.6168 72.5106 C 48.4863 72.628 48.3812 72.7709 48.3079 72.9305 C 48.2346 73.09 48.1947 73.2629 48.1906 73.4385 C 48.1865 73.614 48.2184 73.7885 48.2842 73.9513 L 52.1494 83.4467 C 52.2927 83.7871 52.5586 84.0613 52.8943 84.2152 C 53.2301 84.369 53.6114 84.3914 53.9628 84.2777 L 64.9495 80.5897 C 65.1158 80.5336 65.269 80.4441 65.3995 80.3266 C 65.5301 80.2092 65.6352 80.0663 65.7085 79.9067 C 65.7818 79.7471 65.8217 79.5743 65.8258 79.3987 C 65.8298 79.2232 65.798 79.0486 65.7321 78.8858 V 78.8858 Z"
      />
      <path
        fill="#263238"
        d="M 74.0617 57.0896 C 74.0166 57.2797 74.0617 57.46 74.2035 57.489 C 74.3452 57.518 74.4644 57.3924 74.5256 57.2024 C 74.5867 57.0123 74.5094 56.832 74.3838 56.7997 C 74.2582 56.7675 74.11 56.8996 74.0617 57.0896 Z"
      />
      <path
        fill="#630f0f"
        d="M 74.0615 57.4535 C 74.1445 58.0911 74.3147 58.7143 74.5672 59.3056 C 74.4145 59.3667 74.2502 59.3935 74.086 59.3841 C 73.9218 59.3746 73.7617 59.3291 73.617 59.2508 L 74.0615 57.4535 Z"
      />
      <path
        fill="#263238"
        d="M 73.6498 55.8689 C 73.6716 55.877 73.6956 55.877 73.7174 55.8689 C 73.8498 55.8104 73.9943 55.7842 74.1388 55.7926 C 74.2833 55.801 74.4237 55.8437 74.5485 55.9172 C 74.5608 55.9262 74.5748 55.9327 74.5898 55.9362 C 74.6047 55.9396 74.6201 55.94 74.6352 55.9373 C 74.6503 55.9346 74.6646 55.9288 74.6774 55.9204 C 74.6902 55.9119 74.7011 55.901 74.7095 55.8882 C 74.7181 55.876 74.7241 55.8621 74.7273 55.8476 C 74.7305 55.833 74.7307 55.8179 74.728 55.8032 C 74.7253 55.7885 74.7197 55.7745 74.7116 55.762 C 74.7034 55.7495 74.6929 55.7388 74.6805 55.7304 C 74.5248 55.6351 74.3485 55.5783 74.1665 55.5649 C 73.9844 55.5515 73.8017 55.5817 73.6337 55.6531 C 73.6195 55.6587 73.6066 55.6671 73.5956 55.6778 C 73.5847 55.6885 73.576 55.7013 73.57 55.7153 C 73.564 55.7294 73.5609 55.7445 73.5607 55.7598 C 73.5606 55.7751 73.5635 55.7903 73.5693 55.8044 C 73.5761 55.8211 73.5871 55.8358 73.6012 55.8471 C 73.6153 55.8583 73.632 55.8658 73.6498 55.8689 V 55.8689 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 67.3814 57.7692 C 67.2945 59.5761 66.85 62.8196 65.3619 63.6732 C 65.3619 63.6732 65.4005 65.5414 68.6054 66.3305 C 72.1291 67.2034 70.7215 64.997 70.7215 64.997 C 68.9114 64.0629 69.314 62.6489 69.9807 61.3895 L 67.3814 57.7692 Z"
      />
      <path
        fill="#263238"
        d="M 64.7499 64.1596 C 64.6339 63.5444 64.5115 62.8422 65.1364 62.7842 C 65.8225 62.723 69.343 63.3543 70.4381 64.0726 C 70.5851 64.1549 70.7142 64.2657 70.8179 64.3984 C 70.9216 64.5311 70.9979 64.6831 71.0423 64.8456 C 71.0867 65.0081 71.0982 65.1778 71.0764 65.3448 C 71.0545 65.5118 70.9995 65.6728 70.9148 65.8184 L 64.7499 64.1596 Z"
      />
      <path
        fill="#407bff"
        d="M 48.0364 142.339 C 48.1061 142.415 48.1905 142.477 48.2845 142.521 C 48.3785 142.565 48.4803 142.589 48.5839 142.593 C 48.6483 142.593 48.7289 142.532 48.7578 142.384 C 48.7659 142.346 48.7658 142.307 48.7575 142.269 C 48.7491 142.231 48.7328 142.196 48.7095 142.165 C 48.4905 141.872 47.6821 141.788 47.6498 141.785 C 47.6384 141.784 47.6269 141.786 47.6167 141.791 C 47.6064 141.796 47.5978 141.804 47.5919 141.814 C 47.5862 141.823 47.5831 141.833 47.5831 141.844 C 47.5831 141.855 47.5862 141.866 47.5919 141.875 C 47.7242 142.044 47.8731 142.199 48.0364 142.339 V 142.339 Z M 48.5678 142.181 C 48.5856 142.196 48.6018 142.214 48.6161 142.232 C 48.63 142.25 48.6397 142.271 48.6447 142.294 C 48.6497 142.316 48.6499 142.339 48.6451 142.361 C 48.6258 142.467 48.5775 142.477 48.5581 142.48 C 48.3907 142.519 48.0138 142.2 47.7755 141.917 C 48.0563 141.95 48.3266 142.043 48.5678 142.19 V 142.181 Z"
      />
      <path
        fill="#407bff"
        d="M 47.6042 141.884 C 47.6042 141.884 47.6042 141.884 47.63 141.884 C 47.9102 141.942 48.6253 141.852 48.7606 141.614 C 48.7815 141.576 48.7888 141.532 48.7812 141.489 C 48.7736 141.446 48.7515 141.407 48.7187 141.379 C 48.6887 141.346 48.6523 141.32 48.6118 141.303 C 48.5714 141.285 48.5277 141.276 48.4836 141.276 C 48.1196 141.276 47.6236 141.768 47.601 141.788 C 47.5965 141.797 47.5941 141.807 47.5941 141.817 C 47.5941 141.827 47.5965 141.837 47.601 141.846 C 47.5985 141.859 47.5996 141.872 47.6042 141.884 Z M 48.6156 141.453 L 48.6349 141.469 C 48.6865 141.527 48.6704 141.556 48.6607 141.569 C 48.5802 141.71 48.0842 141.817 47.7717 141.801 C 47.964 141.6 48.212 141.462 48.4836 141.404 C 48.5319 141.405 48.5786 141.422 48.6156 141.453 V 141.453 Z"
      />
      <path
        fill="#407bff"
        d="M 66.2312 146.999 C 66.5533 146.999 66.8367 146.954 66.9237 146.816 C 66.9459 146.776 66.955 146.731 66.9498 146.685 C 66.9446 146.64 66.9253 146.598 66.8947 146.565 C 66.8489 146.496 66.7786 146.448 66.6982 146.429 C 66.2827 146.32 65.4839 146.803 65.4485 146.822 C 65.4386 146.829 65.4311 146.838 65.4271 146.849 C 65.423 146.86 65.4226 146.872 65.4259 146.883 C 65.4297 146.895 65.4365 146.905 65.4456 146.913 C 65.4546 146.921 65.4656 146.926 65.4775 146.929 C 65.726 146.975 65.9783 146.998 66.2312 146.999 Z M 66.5533 146.526 C 66.5907 146.522 66.6285 146.522 66.666 146.526 C 66.7142 146.537 66.7566 146.566 66.7852 146.606 C 66.8335 146.68 66.8238 146.713 66.8109 146.735 C 66.7143 146.893 66.0991 146.896 65.6417 146.832 C 65.9253 146.667 66.2401 146.563 66.5661 146.526 H 66.5533 Z"
      />
      <path
        fill="#407bff"
        d="M 65.4907 146.932 H 65.5133 C 65.7903 146.8 66.3185 146.287 66.2444 146.001 C 66.2444 145.936 66.1736 145.856 66.0157 145.84 C 65.958 145.831 65.899 145.835 65.8432 145.853 C 65.7874 145.87 65.7363 145.9 65.6936 145.94 C 65.4102 146.191 65.4134 146.848 65.4134 146.874 C 65.4132 146.884 65.4157 146.894 65.4208 146.903 C 65.4259 146.912 65.4334 146.92 65.4424 146.925 C 65.4492 146.93 65.4572 146.934 65.4657 146.935 C 65.4742 146.936 65.4828 146.935 65.4907 146.932 V 146.932 Z M 65.9803 145.946 H 66.0157 C 66.1188 145.946 66.1317 145.994 66.1349 146.01 C 66.1768 146.175 65.8128 146.587 65.5455 146.767 C 65.5413 146.497 65.6262 146.234 65.787 146.017 C 65.8406 145.97 65.9094 145.945 65.9803 145.946 V 145.946 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 62.8434 146.874 H 65.272 L 65.5168 141.247 H 63.085 L 62.8434 146.874 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 45.1533 141.176 L 47.5046 141.791 L 50.1072 136.657 L 47.7559 136.042 L 45.1533 141.176 Z"
      />
      <path
        fill="#263238"
        d="M 47.6853 141.511 L 45.6078 139.746 C 45.5694 139.716 45.5224 139.7 45.4741 139.7 C 45.4257 139.7 45.3787 139.716 45.3404 139.746 L 43.5657 141.073 C 43.5234 141.106 43.4893 141.149 43.4658 141.197 C 43.4424 141.246 43.4302 141.299 43.4302 141.353 C 43.4302 141.407 43.4424 141.46 43.4658 141.509 C 43.4893 141.557 43.5234 141.6 43.5657 141.633 C 44.3033 142.236 44.693 142.493 45.6078 143.27 C 46.1714 143.746 47.3728 145.115 48.0976 145.83 C 48.8223 146.545 49.6275 145.917 49.3859 145.56 C 48.6189 144.456 48.1083 143.195 47.8914 141.868 C 47.8686 141.728 47.7953 141.601 47.6853 141.511 V 141.511 Z"
      />
      <path
        fill="#263238"
        d="M 65.1974 146.59 H 62.5433 C 62.4966 146.59 62.4514 146.607 62.4163 146.638 C 62.3813 146.669 62.3588 146.711 62.3533 146.758 L 62.0988 148.916 C 62.0934 148.971 62.0996 149.026 62.1169 149.078 C 62.1342 149.13 62.1623 149.178 62.1993 149.219 C 62.2364 149.26 62.2815 149.292 62.3319 149.314 C 62.3823 149.336 62.4368 149.348 62.4918 149.347 C 63.4581 149.331 64.8238 149.277 66.0348 149.277 C 67.4424 149.277 67.6808 149.354 69.3299 149.354 C 70.3252 149.354 70.4991 148.346 70.0707 148.252 C 68.1381 147.837 67.5584 147.795 65.8094 146.78 C 65.6258 146.664 65.4146 146.598 65.1974 146.59 Z"
      />
      <path
        fill="#407bff"
        d="M 74.2712 68.1407 C 74.0844 70.5919 73.1632 75.8001 69.6556 86.3778 L 56.8877 83.9749 C 57.1293 82.2678 59.4258 75.0722 59.9476 66.9618 C 59.9818 66.4274 60.1255 65.9057 60.3697 65.429 C 60.6138 64.9524 60.9533 64.531 61.367 64.191 C 61.7807 63.8509 62.2599 63.5995 62.7748 63.4523 C 63.2897 63.305 63.8294 63.2651 64.3603 63.335 H 64.4247 C 66.2522 63.5707 68.0644 63.9127 69.852 64.3593 C 70.7934 64.6443 71.7155 64.9896 72.6124 65.3932 C 73.1405 65.6215 73.5841 66.009 73.8815 66.5015 C 74.1789 66.994 74.3151 67.5671 74.2712 68.1407 V 68.1407 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 71.8459 69.6256 C 71.7622 71.0041 72.2775 73.932 72.7285 76.1577 C 73.1408 74.5472 73.45 73.2137 73.6948 72.0735 L 71.8459 69.6256 Z"
      />
      <path
        fill="#407bff"
        d="M 74.2258 67.3934 C 74.5317 68.1536 74.8699 68.9814 75.192 69.7673 C 75.5141 70.5532 75.8845 71.3488 76.2582 72.1218 C 76.9495 73.6428 77.7605 75.1065 78.6836 76.4991 C 78.9122 76.8212 79.1409 77.1433 79.3793 77.4138 L 79.4244 77.4654 H 79.4083 H 79.3857 C 79.3857 77.4654 79.3857 77.4654 79.3857 77.4879 C 79.456 77.5393 79.5344 77.5785 79.6176 77.6039 C 79.9206 77.6991 80.2345 77.7553 80.5517 77.7713 C 80.9397 77.7986 81.3289 77.8061 81.7177 77.7939 C 83.4217 77.6986 85.1181 77.4974 86.7971 77.1916 L 87.4413 79.0533 C 86.6007 79.4646 85.7347 79.8218 84.8485 80.1227 C 83.9459 80.4397 83.0211 80.6894 82.0817 80.8699 C 81.5815 80.9647 81.0759 81.0282 80.5678 81.06 C 79.9905 81.0972 79.4109 81.0648 78.8414 80.9633 C 78.4849 80.9026 78.1383 80.7943 77.8107 80.6412 C 77.6143 80.5514 77.4267 80.4436 77.2502 80.3191 C 77.1525 80.2495 77.0589 80.1742 76.97 80.0937 L 76.8347 79.9648 L 76.7639 79.894 C 76.4035 79.53 76.0627 79.1473 75.7428 78.7473 C 74.5773 77.2339 73.5621 75.6103 72.7119 73.8998 C 72.2932 73.0687 71.8906 72.2345 71.5266 71.3842 C 71.1626 70.5339 70.8115 69.6996 70.4894 68.7849 L 74.2258 67.3934 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 97.2335 75.4427 L 92.6501 74.5279 C 92.4841 74.4968 92.3125 74.5292 92.1693 74.6187 C 92.0261 74.7082 91.9218 74.8482 91.877 75.011 L 90.5242 80.1291 C 90.5023 80.2067 90.4967 80.2879 90.5079 80.3677 C 90.519 80.4475 90.5467 80.5241 90.5892 80.5926 C 90.6316 80.661 90.6878 80.7199 90.7543 80.7655 C 90.8208 80.811 90.896 80.8422 90.9752 80.8571 L 95.5586 81.7718 C 95.7245 81.8022 95.8957 81.7694 96.0387 81.68 C 96.1817 81.5907 96.2862 81.4511 96.3316 81.2887 L 97.678 76.1609 C 97.6985 76.0845 97.7031 76.0047 97.6916 75.9264 C 97.6801 75.8481 97.6526 75.773 97.611 75.7057 C 97.5694 75.6384 97.5144 75.5804 97.4495 75.5351 C 97.3845 75.4899 97.311 75.4584 97.2335 75.4427 V 75.4427 Z"
      />
      <path
        fill="white"
        d="M 95.6393 73.0914 L 91.0752 72.0768 C 90.91 72.0431 90.7382 72.0723 90.5934 72.1587 C 90.4486 72.2451 90.3413 72.3824 90.2925 72.5438 L 88.827 77.6297 C 88.8039 77.7069 88.797 77.7881 88.8069 77.8681 C 88.8167 77.9482 88.8431 78.0253 88.8842 78.0946 C 88.9254 78.1639 88.9805 78.2239 89.046 78.2709 C 89.1116 78.3178 89.1861 78.3506 89.265 78.3673 L 93.813 79.3819 C 93.9783 79.4164 94.1505 79.3875 94.2954 79.301 C 94.4404 79.2145 94.5476 79.0767 94.5957 78.9149 L 96.0677 73.829 C 96.0914 73.7524 96.099 73.6718 96.09 73.5921 C 96.0809 73.5125 96.0555 73.4355 96.0152 73.3662 C 95.975 73.2969 95.9208 73.2367 95.8561 73.1894 C 95.7914 73.1421 95.7176 73.1087 95.6393 73.0914 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 86.4042 77.533 L 87.8472 75.4168 L 90.1373 77.5105 C 90.1373 77.5105 88.9456 79.1209 87.1805 79.034 L 86.4042 77.533 Z"
      />
      <path
        fill="#263238"
        d="M 85.934 77.4493 C 86.2191 78.0759 86.4602 78.7217 86.6555 79.3819 C 86.6731 79.4349 86.7091 79.4799 86.7569 79.5088 C 86.8047 79.5377 86.8612 79.5487 86.9164 79.5397 C 87.2385 79.4882 87.4543 79.4205 87.4704 79.2466 C 87.4865 79.0727 86.7746 77.1626 86.5266 77.0338 C 86.4413 77.0109 86.3519 77.0079 86.2653 77.0253 C 86.1787 77.0426 86.0973 77.0797 86.0274 77.1337 C 86.0001 77.1478 85.976 77.1674 85.9565 77.1911 C 85.9369 77.2148 85.9224 77.2422 85.9136 77.2716 C 85.9049 77.3011 85.9022 77.332 85.9057 77.3625 C 85.9092 77.3931 85.9188 77.4226 85.934 77.4493 V 77.4493 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 90.6499 75.0915 L 90.9333 75.6423 C 91.0458 75.8605 91.084 76.1093 91.0421 76.3512 C 91.0003 76.593 90.8806 76.8146 90.7014 76.9822 L 90.1345 77.5105 L 87.8444 75.4168 L 89.0491 74.6567 C 89.1809 74.5732 89.3286 74.5181 89.4829 74.4948 C 89.6372 74.4715 89.7947 74.4806 89.9452 74.5215 C 90.0958 74.5624 90.2362 74.6342 90.3575 74.7324 C 90.4789 74.8305 90.5784 74.9528 90.6499 75.0915 V 75.0915 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 65.5131 141.25 L 65.3778 144.413 H 62.9492 L 63.0845 141.25 H 65.5131 Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M 47.756 136.045 L 50.1073 136.657 L 48.6644 139.501 L 46.4129 138.689 L 47.756 136.045 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 67.8159 55.1216 C 67.3843 57.4922 67.0106 58.8579 67.8932 60.3492 C 69.2234 62.6039 72.3703 62.2334 73.5041 60.0271 C 74.5219 58.0526 75.0598 54.5064 73.0048 53.0119 C 72.5518 52.6806 72.0215 52.4706 71.4644 52.402 C 70.9073 52.3333 70.342 52.4083 69.822 52.6197 C 69.3021 52.8311 68.8448 53.1719 68.4937 53.6098 C 68.1425 54.0477 67.9092 54.5681 67.8159 55.1216 Z"
      />
      <path
        fill="#263238"
        d="M 71.8815 57.4439 C 73.0894 57.9109 73.8141 56.1748 73.492 55.55 C 73.8914 55.3084 75.4858 54.938 75.3022 53.714 C 75.1186 52.4901 73.9108 52.1358 73.0282 50.5221 C 72.7287 50.6799 72.8092 51.6623 72.8092 51.6623 C 72.8092 51.6623 72.0845 50.3739 70.2067 50.3965 C 68.3288 50.419 67.6299 49.6427 67.6299 49.6427 C 67.6299 49.6427 67.7716 50.8667 68.4673 51.1663 C 67.4141 51.4626 67.4141 53.0988 65.9196 53.2985 C 66.261 53.9427 67.0952 53.9427 67.0952 53.9427 C 67.0952 53.9427 66.232 54.69 66.4027 55.6241 C 66.5734 56.5581 67.5719 58.8096 66.3769 59.5923 C 68.9634 60.5167 72.3937 59.4731 71.8815 57.4439 Z"
      />
      <path
        fill="#7f3e3b"
        d="M 71.317 57.0574 C 71.1849 57.6424 71.2483 58.2545 71.4974 58.8 C 71.8195 59.5118 72.4637 59.3282 72.7665 58.6743 C 73.0338 58.0881 73.182 57.0123 72.6602 56.5131 C 72.1384 56.0138 71.4877 56.3553 71.317 57.0574 Z"
      />
      <path
        fill="#407bff"
        d="M 65.1264 85.5242 C 65.1264 85.5242 61.825 103.652 60.3143 110.606 C 58.6588 118.22 50.2263 138.596 50.2263 138.596 L 45.994 137.23 C 45.994 137.23 51.2892 117.827 53.6759 110.174 C 54.1752 102.012 55.1898 89.0962 56.8969 83.9717 L 65.1264 85.5242 Z"
      />
      <path
        opacity="0.5"
        fill="black"
        d="M 65.1264 85.5242 C 65.1264 85.5242 61.825 103.652 60.3143 110.606 C 58.6588 118.22 50.2263 138.596 50.2263 138.596 L 45.994 137.23 C 45.994 137.23 51.2892 117.827 53.6759 110.174 C 54.1752 102.012 55.1898 89.0962 56.8969 83.9717 L 65.1264 85.5242 Z"
      />
      <path
        fill="#407bff"
        d="M 51.1217 137.7 C 51.1378 137.7 50.1554 139.179 50.1554 139.179 L 45.4012 137.614 L 45.9649 136.193 L 51.1217 137.7 Z"
      />
      <path
        opacity="0.3"
        fill="black"
        d="M 63.6293 91.863 C 60.5372 92.359 60.7627 101.526 61.1234 106.66 C 62.0253 102.183 63.1365 96.2854 63.9546 91.863 C 63.8464 91.8545 63.7376 91.8545 63.6293 91.863 Z"
      />
      <path
        fill="#407bff"
        d="M 69.6556 86.3777 C 69.6556 86.3777 70.3417 103.249 70.0775 110.654 C 69.8038 118.362 66.2961 143.588 66.2961 143.588 H 62.1797 C 62.1797 143.588 62.0928 119.338 63.0397 111.285 C 62.84 103.037 61.2618 84.7962 61.2618 84.7962 L 69.6556 86.3777 Z"
      />
      <path
        opacity="0.5"
        fill="black"
        d="M 69.6556 86.3777 C 69.6556 86.3777 70.3417 103.249 70.0775 110.654 C 69.8038 118.362 66.2961 143.588 66.2961 143.588 H 62.1797 C 62.1797 143.588 62.0928 119.338 63.0397 111.285 C 62.84 103.037 61.2618 84.7962 61.2618 84.7962 L 69.6556 86.3777 Z"
      />
      <path
        fill="#407bff"
        d="M 67.0786 141.949 C 67.098 141.949 66.8467 143.656 66.8467 143.656 H 61.851 L 61.7158 142.132 L 67.0786 141.949 Z"
      />
      <path
        fill="#263238"
        d="M 56.8873 83.5079 C 56.4653 83.4885 56.4363 84.4999 56.5652 84.7962 C 56.694 85.0926 58.5493 85.2729 63.4709 86.3777 C 68.3925 87.4825 69.61 87.5405 69.7646 87.4729 C 69.9192 87.4052 70.2317 86.0395 69.9289 85.9977 C 69.6261 85.9558 64.5692 85.1441 62.4273 84.6481 C 60.2854 84.152 57.4864 83.5368 56.8873 83.5079 Z"
      />
    </svg>
  )
}
