import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {
  useUpdateProduct,
  useCreateProductLogo,
  getProductsDropdown,
} from '@app/graphql'
import {
  InlineEdit,
  LogoCard,
  Button,
  Dialog,
  ImageUploader,
} from '@app/components'
import { useForm } from 'react-hook-form'
import { getLogosCount } from '@app/utils'
import {
  Container,
  ProductHeader,
  ProductCardInfo,
  ContentSection,
  ProductName,
  LogoSection,
  ProductInfo,
  ProductType,
  ProductFranchise,
  ProductYear,
  SectionHeader,
  LogosList,
  Label,
  Title,
  Link,
  LogoListItem,
  Field,
  DialogFooter,
  ProductActions,
} from './product-detail-components'

type Props = {
  onDelete: () => void
  product: any
}

export default function ProductDetail({ onDelete, product }: Props) {
  const { _id, name, type, franchise, year, logo, productProfileLogo } = product
  const [teamName, setTeamName] = useState(name)
  const [logoList, setLogosList] = useState(logo)
  const [selectedType, setSelectedType] = useState({ value: type, label: type })
  const [selectedFranchise, setSelectedFranchise] = useState({
    value: franchise,
    label: franchise,
  })
  const [selectedYear, setSelectedYear] = useState({ value: year, label: year })

  const [updateProduct] = useUpdateProduct()
  const [createProductLogo] = useCreateProductLogo()

  const [showDelete, setShowDelete] = useState(false)
  const [logoToDelete, setLogoToDelete] = useState({})

  useEffect(() => {
    setTeamName(name)
    setLogosList(logo)
    setSelectedType({ value: type, label: type })
    setSelectedFranchise({ value: franchise, label: franchise })
    setSelectedYear({ value: year, label: year })
    // eslint-disable-next-line
  }, [product])

  const { register } = useForm()
  const { productTypes, franchises, years } = getProductsDropdown()

  function editProduct(e: any) {
    const productName = e.target.value

    if (productName) {
      setTeamName(productName)
      updateProduct({
        variables: {
          _id: _id,
          name: productName,
          type: selectedType.value,
          franchise: selectedFranchise.value,
          year: selectedYear.value,
        },
      })
    }
  }

  function addLogo() {
    const newLogo = {
      ProductId: _id,
      name: '',
      logo: '',
      aiName: '',
      aiLogo: '',
      status: true,
    }

    createProductLogo({ variables: { ...newLogo } })
      .then((res: any) => {
        const {
          data: { createProductLogo },
        } = res
        setLogosList([...logoList, createProductLogo])
      })
      .catch((error: any) => {})
  }

  function changeProductType(type: any) {
    setSelectedType(type)
    updateProduct({
      variables: {
        _id: _id,
        type: type.value,
      },
    })
  }

  function changeFranchise(franchise: any) {
    setSelectedFranchise(franchise)
    updateProduct({
      variables: {
        _id: _id,
        franchise: franchise.value,
      },
    })
  }

  function changeYear(year: any) {
    setSelectedYear(year)
    updateProduct({
      variables: {
        _id: _id,
        year: year.value,
      },
    })
  }

  

  function loadData(url: String, logo: any) {
    // console.log('loadData', url, logo, _id)
  }

  function updateLogo(url: String, logo: any, productId: String) {
    loadData(url, logo)
    if (url && logo) {
      let data = {
        _id: logo._id,
        ProductId: _id,
        logo: url,
      }
      createProductLogo({ variables: { ...data } })
    }
  }

  function updateLogoName(name: any, index: any, type: any, logo: any) {
    if (type === 'change') {
      setLogosList(
        logoList.map((logo: any, idx: any) => {
          if (index !== idx) return logo
          return { ...logo, name: name }
        }),
      )
    }
    if (name && logo) {
      let data = {
        _id: logo._id,
        ProductId: _id,
        name: name,
      }
      createProductLogo({ variables: { ...data } })
    }
  }

  function deleteLogo(logo: any) {
    setShowDelete(true)
    setLogoToDelete(logo)
  }
  function deleteUpdateLogo(updatedLogo: any) {
    setShowDelete(false)
    const updatedLogos = logoList.map((logo: any) => {
      if (logo._id === updatedLogo._id) {
        createProductLogo({ variables: { ...updatedLogo, status: false } })
        return { ...updatedLogo, status: false }
      } else {
        return logo
      }
    })

    setLogosList(updatedLogos)
  }
  function onUpload(e: any) {
    if (e) {
      const data = { _id: _id, productProfileLogo: e }
      updateProduct({ variables: data })
    }
  }


  return (
    <Container>
      {showDelete && (
        <Dialog title="Remove Logo" onClose={() => setShowDelete(false)}>
          <Dialog.Body>Are you sure, you want to remove this logo?</Dialog.Body>
          <Dialog.Footer>
            <DialogFooter>
              <Button variant="text" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button onClick={() => deleteUpdateLogo(logoToDelete)}>
                Remove Logo
              </Button>
            </DialogFooter>
          </Dialog.Footer>
        </Dialog>
      )}
      <ProductHeader>
        <ProductCardInfo key={_id}>
          <ImageUploader
            url={productProfileLogo}
            location="profile"
            onUpload={onUpload}
          />
          <ProductName>
            <InlineEdit
              label="Style Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              onBlur={editProduct}
            />
          </ProductName>
        </ProductCardInfo>
        <ProductActions onClick={(e) => e.stopPropagation()}>
          {/* <FontAwesomeIcon
            className="nike-icon icon-delete"
            onClick={() => deleteProduct()}
            icon={faTrash}
          /> */}
          {/* <TooltipTrigger
            placement="bottom"
            trigger="click"
            tooltip={(e) => deleteConfirmation(e)}>
            {({ getTriggerProps, triggerRef }: any) => {
              return (
                <FontAwesomeIcon
                  {...getTriggerProps({
                    ref: triggerRef,
                    className: 'trigger nike-icon icon-delete',
                  })}
                  icon={faTrash}
                />
              )
            }}
          </TooltipTrigger> */}
        </ProductActions>
        {/* <Button onClick={() => deleteProduct()}>Delete Product</Button> */}
      </ProductHeader>

      <ContentSection>
        <ProductInfo>
          <ProductType>
            <Label>Style Type</Label>
            <Field>
              <Select
                className="select-container"
                value={selectedType}
                name="type"
                onChange={changeProductType}
                register={register({ required: true })}
                options={productTypes}
              />
            </Field>
          </ProductType>
          <ProductFranchise>
            <Label>Sports Franchise</Label>
            <Field>
              <Select
                className="select-container"
                value={selectedFranchise}
                name="franchise"
                onChange={changeFranchise}
                register={register({ required: true })}
                options={franchises}
              />
            </Field>
          </ProductFranchise>
          <ProductYear>
            <Label>Year</Label>
            <Field>
              <Select
                className="select-container"
                value={selectedYear}
                name="year"
                onChange={changeYear}
                register={register({ required: true })}
                options={years}
              />
            </Field>
          </ProductYear>
        </ProductInfo>
        <LogoSection>
          <SectionHeader>
            <Title>Art Files</Title>
            {logoList && getLogosCount(logoList) && (
              <Link onClick={addLogo}>+ Add</Link>
            )}
          </SectionHeader>
          <LogosList>
            {logoList &&
              logoList.map((logo: any, index: number) => {
                if (!logo.status || !logo._id) {
                  return <div key={index + logo._id}></div>
                }
                return (
                  <LogoListItem key={index + logo._id}>
                    <LogoCard
                      location="player"
                      url={logo.logo}
                      name={logo.name}
                      onDelete={() => deleteLogo(logo)}
                      updateName={(val: any) =>
                        updateLogoName(val, index, 'change', logo)
                      }
                      updateLogo={(url) => updateLogo(url, logo, _id)}
                    />
                  </LogoListItem>
                )
              })}
          </LogosList>
        </LogoSection>
      </ContentSection>
    </Container>
  )
}
