import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faEye,
  faFilePdf,
  faFileImage,
} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'react-responsive'
import { vj6, sb6, vk4 } from '@app/data'
import TooltipTrigger from 'react-popper-tooltip'
import {
  Icon,
  DesignerItemsList,
  DesignerItemsConfigure,
  Button,
  AspectRatioContainer,
} from '@app/components'
import { iconNames } from '@app/components/icon/icon'
import {
  InnerContainer,
  DesignerViewContainer,
  Header,
  LeftNav,
  RightNav,
  BackIcon,
  HeaderTitle,
  VerticalSeperator,
  OrderStatus,
  NavItem,
  NavIcon,
  ViewInfo,
  ViewName,
  CanvasSlider,
  CanvasSlide,
  CanvasInnerSlide,
  CanvasSliderProgressBar,
  CanvasSliderThumb,
  CanvasSliderProgress,
  ToolTipBody,
  ToolTipArrow,
  ToolTipContainer,
  ExportContainer,
  ExportItem,
  SwitchBox,
  SizeBox,
} from './designer-components'
import DesignerCanvas from './designer-canvas'

type ViewType = {
  id: 'palm' | 'top' | 'leftCuff' | 'rightCuff' | 'dieCast' | 'smallDieCast'
  name: string
  iconName: keyof typeof iconNames
  productAssets: any
  productItems: any[]
  reRender: number
  width?: string
  height?: string
  prefix?: string
  styleName?: string
}

type TabType = {
  id: number
  name: string
  prefix: string
  iconName: any
  views: ViewType[]
  styleProps?: any[]
}

export const gloveTypes = {
  VJ6: vj6,
  SB6: sb6,
  VK4: vk4,
}

export const Views: ViewType[] = [
  {
    id: 'top',
    name: 'Top of Hand',
    iconName: 'topOfHand',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
  {
    id: 'palm',
    name: 'Palm of Hand',
    iconName: 'palmOfHand',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
  {
    id: 'leftCuff',
    name: 'Left Inside Cuff',
    iconName: 'leftInsideCuff',
    styleName: 'INSIDE CUFF LEFT',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
  {
    id: 'rightCuff',
    name: 'Right Inside Cuff',
    iconName: 'rightInsideCuff',
    styleName: 'INSIDE CUFF RIGHT',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
  {
    id: 'dieCast',
    name: 'Die Cast',
    iconName: 'diecast',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
  {
    id: 'smallDieCast',
    name: 'Small Die Cast',
    iconName: 'diecast',
    productAssets: [],
    productItems: [],
    reRender: 0,
  },
]

const Tabs: TabType[] = [
  {
    id: 1,
    name: 'Preview All',
    prefix: 'preview',
    iconName: '',
    views: [Views[0], Views[1], Views[2], Views[3]],
    styleProps: [
      { width: '42%', height: '70%' },
      { width: '58%', height: '70%' },
      { width: '42%', height: '30%' },
      { width: '42%', height: '30%', marginLeft: '8%' },
      { display: 'none' },
      { display: 'none' },
    ],
  },
  {
    id: 2,
    name: 'Top of Hand',
    prefix: '',
    iconName: 'topOfHand',
    views: [Views[0]],
  },
  {
    id: 3,
    name: 'Palm of Hand',
    prefix: '',
    iconName: 'palmOfHand',
    views: [Views[1]],
  },
  {
    id: 4,
    name: 'Inside Cuff',
    prefix: '',
    iconName: 'leftInsideCuff',
    views: [Views[2], Views[3]],
    styleProps: [
      {
        maxWidth: '500px',
        width: '50%',
        height: '50%',
        float: 'none',
        margin: '0 auto',
      },
      {
        maxWidth: '500px',
        width: '50%',
        height: '50%',
        float: 'none',
        margin: '0 auto',
      },
    ],
  },
]

type Props = {
  title?: string
  trackStatus?: string
  productType: 'VJ6' | 'SB6' | 'VK4'
  productItems: any[]
  colorList: any[]
  logoList: any[]
  customMessage: any
  isAdmin: boolean
  isPdfAvailable: boolean
  generatePdf: (canvas: boolean, specType: string) => void
  generatePng: (canvas: boolean) => void
  onSave: (items: any[], customMsg: any) => void
  onSubmit: (items: any[], customMsg: any) => void
  onReturnClick: (items: any[], customMsg: any, dieCastItems: any) => void
  diecastItem: any
  createDesign: (items: any[], customMsg: any, dieCastItems: any) => void
}
const dieCastSizes = ['XL - 4XL', 'S - L']

export default function DesignerView({
  title = '',
  trackStatus = '',
  productType,
  productItems,
  colorList,
  logoList,
  customMessage,
  isAdmin,
  onSave,
  onSubmit,
  onReturnClick,
  isPdfAvailable,
  generatePdf,
  generatePng,
  diecastItem,
  createDesign,
}: Props) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [selectedTab, setSelectedTab] = useState<any>({})
  const [previousTab, setPreviousTab] = useState<any>({})
  const [itemList, setItemList] = useState<any>([])
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectedBrand, setSelectedBrand] = useState('Nike')
  const [activeSize, setActiveSize] = React.useState(dieCastSizes[0])
  const [, setSelectedColor] = useState<any>({})
  const [, setSelectedMaterial] = useState('')
  const [, setSelectedPattern] = useState('')
  const [, setSelectedCustomPattern] = useState<any>({})
  const [, setSelectedLogo] = useState<any>({})
  const [customMsg, setCustomMsg] = useState<any>({})
  const [dieCastItems, setDieCastItems] = useState<any>({
    itemType: 'dieCast',
    customPattern: [],
    logos: [],
    primaryColors: [],
  })
  const [showToolTip, setShowToolTip] = useState<boolean>(false)
  useEffect(() => {
    updateTabs()
    setDieCastItems(diecastItem)
    // eslint-disable-next-line
  }, [isMobile, isAdmin, diecastItem])

  if (!selectedTab.id) {
    setDefaultColors()
    setProductRelatedItems()
    setBrand()
    setCustomMsg(customMessage || {})
    selectTab(isMobile ? Tabs[1] : Tabs[0])
  }
  function updateTabs() {
    if (!isMobile && isAdmin && Tabs.length === 4) {
      Tabs.push(
        {
          id: 5,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[4]],
        },
        {
          id: 6,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[5]],
          styleProps: [{ display: 'none' }],
        },
      )
    } else if (isMobile && isAdmin && Tabs.length === 6) {
      removeItems(Tabs)
    }
  }

  function removeItems(tabs: any) {
    for (var i = 0; i < 2; i++) {
      tabs.pop()
    }
  }

  function setDefaultColors() {
    productItems.forEach((item: any) => {
      if (item.itemType === 'swoosh' || item.itemType === 'palm') {
        if (item.primaryColors.length) {
          let selectedColor = item.primaryColors.find(
            (color: any) => color.selected === true,
          )

          if (!selectedColor && item.primaryColors.length) {
            item.primaryColors[0].selected = true
          }
        }

        if (item.secondaryColors.length) {
          let selectedColor = item.secondaryColors.find(
            (color: any) => color.selected === true,
          )

          if (!selectedColor && item.secondaryColors.length) {
            item.secondaryColors[0].selected = true
          }
        }

        if (item.tertiaryColors && item.tertiaryColors.length) {
          let selectedColor = item.tertiaryColors.find(
            (color: any) => color.selected === true,
          )

          if (!selectedColor && item.tertiaryColors.length) {
            item.tertiaryColors[0].selected = true
          }
        }
      }
    })
  }

  function setProductRelatedItems() {
    if (productItems && productItems.length) {
      Views.forEach((view) => {
        view.productAssets = gloveTypes[productType][view.id]

        view.productItems = productItems.filter((item: any) => {
          if (view.name === 'Top of Hand' && item.itemType === 'swoosh') {
            return true
          }
          return view.productAssets.assets.find(
            (asset: any) => asset.itemId === item.itemId,
          )
        })
      })
    }
  }

  function setBrand() {
    const swoosh = productItems.find((item: any) => item.itemType === 'swoosh')

    if (swoosh && swoosh.brand) {
      setSelectedBrand(swoosh.brand)
    }
  }

  function selectTab(tab: any) {
    //to prevent tab selection while animating
    if (previousTab.id) {
      return
    }
    if (selectedTab.id) {
      setPreviousTab(selectedTab)
    }

    setSelectedTab(tab)

    setTimeout(() => {
      const visibleItemsList =
        tab.id === 1
          ? [...productItems]
          : tab.views[0].productItems.filter((item: any) => {
              // to hide palm related item in top view
              if (tab.views[0].id === 'top' && item.itemType === 'palm') {
                return false
              } else {
                return true
              }
            })

      setItemList(visibleItemsList)
      setPreviousTab({})
    }, 450)
  }

  function selectItem(item: any) {
    if (selectedItem._id === item._id) {
      setSelectedItem({})
    } else {
      setSelectedItem(item)
    }
  }

  function selectColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })

    if (selectedItem.optionalColors) {
      selectedItem.optionalColors.forEach(
        (option: any) => delete option.selected,
      )
    }
    selectedItem.options.forEach((option: any, index: number) => {
      if (index === colorIndex) {
        option.selected = true
      } else {
        delete option.selected
      }
    })

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length
      ) {
        view.reRender++
      }
    })
  }

  function selectOptionalColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })
    selectedItem.options.forEach((option: any) => delete option.selected)

    selectedItem.optionalColors.forEach((option: any, index: number) => {
      if (index === colorIndex) {
        option.selected = true
      } else {
        delete option.selected
      }
    })

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length
      ) {
        view.reRender++
      }
    })
  }

  function selectPrimaryColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })

    if (color.position) {
      selectedItem.primaryColors.forEach((option: any, index: number) => {
        if (option.position && option.position.length) {
          option.selected = true
        } else {
          delete option.selected
        }
      })
    } else {
      selectedItem.primaryColors.forEach((option: any, index: number) => {
        if (index === colorIndex) {
          option.selected = true
        } else {
          delete option.selected
        }
      })
    }

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length ||
        (view.name === 'Top of Hand' && selectedItem.itemType === 'swoosh')
      ) {
        view.reRender++
      }
    })
  }
  function selectDieCastColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })

    if (color.position) {
      dieCastItems.primaryColors.forEach((option: any, index: number) => {
        if (option.position && option.position.length) {
          option.selected = true
        } else {
          delete option.selected
        }
      })
    } else {
      dieCastItems.primaryColors.forEach((option: any, index: number) => {
        if (index === colorIndex) {
          option.selected = true
        } else {
          delete option.selected
        }
      })
    }

    Views.forEach((view) => {
      if (view.name === 'Die Cast' || view.name === 'Small Die Cast') {
        view.reRender++
      }
    })
  }

  function selectSecondaryColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })
    selectedItem.secondaryColors.forEach((option: any, index: number) => {
      if (index === colorIndex) {
        option.selected = true
      } else {
        delete option.selected
      }
    })

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length ||
        (view.name === 'Top of Hand' && selectedItem.itemType === 'swoosh')
      ) {
        view.reRender++
      }
    })
  }

  function selectTertiaryColor(color: any, colorIndex: number) {
    setSelectedColor({ ...color })
    selectedItem.tertiaryColors.forEach((option: any, index: number) => {
      if (index === colorIndex) {
        option.selected = true
      } else {
        delete option.selected
      }
    })

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length ||
        (view.name === 'Top of Hand' && selectedItem.itemType === 'swoosh')
      ) {
        view.reRender++
      }
    })
  }

  function selectMaterial(material: any) {
    selectedItem.material = material.value
    if (material.patterns) {
      selectedItem.pattern = material.patterns[selectedBrand][0].value
    } else {
      selectedItem.pattern = ''
    }
    setSelectedMaterial(selectedItem.material)

    Views.forEach((view) => {
      if (
        selectedItem.itemType === 'palm' &&
        (view.name === 'Top of Hand' || view.name === 'Palm of Hand')
      ) {
        view.reRender++
      }
    })
  }

  function selectPattern(pattern: string) {
    selectedItem.pattern = pattern
    setSelectedPattern(selectedItem.brand + '-' + selectedItem.pattern)

    if (selectedItem.itemType === 'palm') {
      // update left/right position while switching between patterns
      if (pattern === 'Solid' || pattern === 'Glitter') {
        selectedItem.primaryColors.forEach((color: any) => {
          if (color.selected && !color.position) {
            color.position = ['Right', 'Left']
          }
        })
      } else {
        selectedItem.primaryColors.forEach((color: any) => {
          if (color.selected && color.position) {
            if (color.position.length === 2) {
              delete color.position
            } else {
              if (color.position.indexOf('Left') > -1) {
                delete color.selected
              }
              delete color.position
            }
          }
        })
      }
    }

    Views.forEach((view) => {
      if (view.name === 'Top of Hand' && selectedItem.itemType === 'swoosh') {
        view.reRender++
      } else if (
        view.name === 'Palm of Hand' &&
        selectedItem.itemType === 'palm'
      ) {
        view.reRender++
      }
    })
  }

  function selectLogo(logo: any, logoIndex: number) {
    setSelectedLogo({ ...logo })
    if (logo.position) {
      selectedItem.logos.forEach((logo: any, index: number) => {
        if (logo.position && logo.position.length) {
          logo.selected = true
        } else {
          delete logo.selected
        }
      })
    } else {
      selectedItem.logos.forEach((logo: any, index: number) => {
        if (index === logoIndex) {
          logo.selected = true
        } else {
          delete logo.selected
        }
      })
    }

    Views.forEach((view) => {
      if (
        view.productAssets.assets.filter(
          (asset: any) => asset.itemId === selectedItem.itemId,
        ).length
      ) {
        view.reRender++
      }
    })
  }
  function selectDieCastLogo(logo: any, logoIndex: number) {
    setSelectedLogo({ ...logo })
    if (logo.position) {
      dieCastItems.logos.forEach((logo: any, index: number) => {
        if (logo.position && logo.position.length) {
          logo.selected = true
        } else {
          delete logo.selected
        }
      })
    } else {
      dieCastItems.logos.forEach((logo: any, index: number) => {
        if (index === logoIndex) {
          logo.selected = true
        } else {
          delete logo.selected
        }
      })
    }

    Views.forEach((view) => {
      if (view.name === 'Die Cast' || view.name === 'Small Die Cast') {
        view.reRender++
      }
    })
  }
  function changeCustomMessage(side: 'left' | 'right', msgObj: any) {
    if (side === 'left') {
      setCustomMsg({ ...customMsg, left: msgObj })
    } else if (side === 'right') {
      setCustomMsg({ ...customMsg, right: msgObj })
    }

    selectedTab.views.forEach((view: any) => {
      if (view.id === 'leftCuff' || view.id === 'rightCuff') {
        view.reRender++
      }
    })
  }

  function selectCustomPattern(pattern: any, patternIndex: number) {
    setSelectedCustomPattern({ ...pattern })
    selectedItem.customPattern.forEach((pattern: any, index: number) => {
      if (index === patternIndex && !pattern.selected) {
        pattern.selected = true
      } else {
        delete pattern.selected
      }
    })

    Views.forEach((view) => {
      if (view.name === 'Palm of Hand') {
        view.reRender++
      }
    })
  }
  function selectDieCastCustomPattern(pattern: any, patternIndex: number) {
    setSelectedCustomPattern({ ...pattern })
    if (pattern.position) {
      dieCastItems.customPattern.forEach((pattern: any, index: number) => {
        if (pattern.position && pattern.position.length) {
          pattern.selected = true
        } else {
          delete pattern.selected
        }
      })
    } else {
      dieCastItems.customPattern.forEach((pattern: any, index: number) => {
        if (index === patternIndex) {
          pattern.selected = true
        } else {
          delete pattern.selected
        }
      })
    }

    Views.forEach((view) => {
      if (view.name === 'Die Cast' || view.name === 'Small Die Cast') {
        view.reRender++
      }
    })
  }
  const createOrder = () => {
    createDesign(productItems, customMessage, dieCastItems)
  }
  const exportSpec = () => {
    setShowToolTip(false)
    generatePdf(true, 'full')
  }

  function Trigger({ getTriggerProps, triggerRef }: any) {
    return (
      <div
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
          style: {
            cursor: 'pointer',
          },
        })}
        onClick={() => setShowToolTip(!showToolTip)}>
        <Button variant="secondary">Export Spec</Button>
      </div>
    )
  }

  function Tooltip({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }: any) {
    return (
      <ToolTipContainer
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}>
        <ToolTipArrow
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
            className: 'tooltip-arrow',
          })}
        />
        <ToolTipBody className="tooltip-body">
          <ExportContainer>
            <ExportItem onClick={exportSpec}>
              <FontAwesomeIcon icon={faFilePdf} size="lg" />
              Full Spec
            </ExportItem>
            <ExportItem onClick={exportSpec}>
              <FontAwesomeIcon icon={faFilePdf} size="lg" />
              Spec w/o Logos
            </ExportItem>
            <ExportItem onClick={() => generatePng(true)}>
              <FontAwesomeIcon icon={faFileImage} size="lg" />
              Export PNGs
            </ExportItem>
          </ExportContainer>
        </ToolTipBody>
      </ToolTipContainer>
    )
  }

  function onViewUpdate(viewName: string) {
    Views.forEach((view) => {
      if (view.name === viewName) {
        view.reRender++
      }
    })
  }

  function renderDesignerCanvas(tab: any) {
    return tab.views.map((view: any, index: number) => {
      return (
        <DesignerCanvas
          key={view.id}
          viewType={view.name}
          productType={productType}
          imageAssets={view.productAssets}
          itemList={view.productItems}
          reRender={view.reRender}
          customMessage={
            view.id === 'leftCuff'
              ? customMsg.left
              : view.id === 'rightCuff'
              ? customMsg.right
              : {}
          }
          styleProps={tab.styleProps && tab.styleProps[index]}
          styleName={view.styleName || ''}
          prefix={tab.prefix || ''}
          isAdmin={isAdmin}
          isInteractive={true}
          onItemSelect={selectItem}
          onViewUpdate={() => onViewUpdate(view.name)}
          dieCast={dieCastItems}
        />
      )
    })
  }
  const toggleDieCast = (size: string) => {
    removeItems(Tabs)
    if (size === 'XL - 4XL') {
      Tabs.push(
        {
          id: 5,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[4]],
        },
        {
          id: 6,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[5]],
          styleProps: [{ display: 'none' }],
        },
      )
    } else {
      Tabs.push(
        {
          id: 5,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[5]],
        },
        {
          id: 6,
          name: 'Die Cast',
          prefix: '',
          iconName: 'diecast',
          views: [Views[4]],
          styleProps: [{ display: 'none' }],
        },
      )
    }
    setActiveSize(size)
  }

  return (
    <InnerContainer>
      <DesignerViewContainer>
        <CanvasSlider>
          {Tabs.map((tab, tabIndex) => {
            return (
              <CanvasSlide
                active={selectedTab.id === tab.id}
                inactive={previousTab.id === tab.id}
                left={previousTab && selectedTab.id > previousTab.id}
                right={previousTab && selectedTab.id < previousTab.id}
                key={tab.id}>
                <CanvasInnerSlide>
                  {tab.views.length === 4 ? (
                    <AspectRatioContainer xRatio={6} yRatio={5}>
                      {renderDesignerCanvas(tab)}
                    </AspectRatioContainer>
                  ) : (
                    renderDesignerCanvas(tab)
                  )}
                </CanvasInnerSlide>
              </CanvasSlide>
            )
          })}
        </CanvasSlider>

        {!isMobile && (
          <CanvasSliderProgressBar>
            {Tabs.filter((tab) => tab.id !== (isAdmin ? 6 : 5)).map((tab) => {
              return (
                <CanvasSliderThumb
                  key={tab.id}
                  onClick={() => selectTab(tab)}
                />
              )
            })}
            <CanvasSliderProgress
              style={{ left: (selectedTab.id - 1) * 76 + 'px' }}
            />
          </CanvasSliderProgressBar>
        )}

        <Header>
          <BackIcon
            onClick={() =>
              onReturnClick(productItems, customMsg, dieCastItems)
            }>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackIcon>
          <HeaderTitle>{title}</HeaderTitle>
          <VerticalSeperator isAdmin={isAdmin} />
          {!isAdmin && trackStatus && (
            <OrderStatus className={trackStatus.toLowerCase()}>
              {trackStatus}
            </OrderStatus>
          )}
          {isAdmin && (
            <>
              {selectedTab.id === 5 &&
                dieCastSizes.map((size, index) => {
                  return (
                    <SwitchBox key={index} active={activeSize === size}>
                      <SizeBox
                        onClick={() => {
                          toggleDieCast(size)
                        }}>{`${size}`}</SizeBox>
                    </SwitchBox>
                  )
                })}
              {isPdfAvailable && (
                <TooltipTrigger
                  placement="bottom"
                  trigger="click"
                  tooltipShown={showToolTip}
                  tooltip={Tooltip}>
                  {Trigger}
                </TooltipTrigger>
              )}
            </>
          )}
        </Header>

        <LeftNav className={isAdmin ? 'hideNav' : ''}>
          {Tabs.map((tab, index) => {
            return (
              <NavItem
                active={selectedTab.id === tab.id}
                key={index}
                onClick={() => selectTab(tab)}>
                {tab.id === 1 ? (
                  <NavIcon className="preview">
                    <FontAwesomeIcon icon={faEye} />
                  </NavIcon>
                ) : (
                  <NavIcon>
                    <Icon name={tab.iconName} />
                  </NavIcon>
                )}

                <ViewInfo>
                  <ViewName>{tab.name}</ViewName>
                </ViewInfo>
              </NavItem>
            )
          })}
        </LeftNav>
      </DesignerViewContainer>

      <RightNav>
        {isAdmin ? (
          <DesignerItemsConfigure
            config={gloveTypes[productType]['config']}
            list={itemList}
            selectedItem={selectedItem}
            onItemSelect={selectItem}
            onColorSelect={selectColor}
            onOptionalColorSelect={selectOptionalColor}
            onPrimaryColorSelect={selectPrimaryColor}
            onSecondaryColorSelect={selectSecondaryColor}
            onTertiaryColorSelect={selectTertiaryColor}
            onMaterialSelect={selectMaterial}
            onPatternSelect={selectPattern}
            onLogoSelect={selectLogo}
            showCustomMessage={selectedTab.id === 4 || selectedTab.id === 1}
            customMessage={
              selectedTab.id === 4 || selectedTab.id === 1 ? customMsg : {}
            }
            onCustomMessageChange={changeCustomMessage}
            colorList={colorList}
            logoList={logoList}
            onCustomPatternSelect={selectCustomPattern}
            showDieCastItems={selectedTab.id === 5 || selectedTab.id === 6}
            dieCastItems={dieCastItems}
            onDieCastColorSelect={selectDieCastColor}
            onDieCastLogoSelect={selectDieCastLogo}
            onDieCastCustomPatternSelect={selectDieCastCustomPattern}
            saveOrder={createOrder}
          />
        ) : (
          <DesignerItemsList
            onCustomPatternSelect={selectCustomPattern}
            config={gloveTypes[productType]['config']}
            list={itemList}
            selectedItem={selectedItem}
            onItemSelect={selectItem}
            onColorSelect={selectColor}
            onOptionalColorSelect={selectOptionalColor}
            onPrimaryColorSelect={selectPrimaryColor}
            onSecondaryColorSelect={selectSecondaryColor}
            onTertiaryColorSelect={selectTertiaryColor}
            onMaterialSelect={selectMaterial}
            onPatternSelect={selectPattern}
            onLogoSelect={selectLogo}
            showCustomMessage={selectedTab.id === 4 || selectedTab.id === 1}
            customMessage={
              selectedTab.id === 4 || selectedTab.id === 1 ? customMsg : {}
            }
            onCustomMessageChange={changeCustomMessage}
            colorList={colorList}
            logoList={logoList}
            onSave={() => onSave(productItems, customMsg)}
            onSubmit={() => onSubmit(productItems, customMsg)}
          />
        )}
      </RightNav>
    </InnerContainer>
  )
}
