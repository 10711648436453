import React, { useRef, useEffect, useState } from 'react'
import { fabric } from 'fabric'
import { hexToRgb, is2dLengthEqual, objectPath, loadImage } from '@app/utils'
import { vj6, sb6, vk4 } from '@app/data'
import { CanvasContainer, CanvasInnerContainer } from './designer-components'

export const gloveTypes = {
  VJ6: vj6,
  SB6: sb6,
  VK4: vk4,
}

type Props = {
  viewType: string
  productType: 'VJ6' | 'SB6' | 'VK4'
  imageAssets: any
  itemList: any[]
  customMessage: any
  reRender: number
  styleProps?: any
  prefix?: string
  isAdmin?: boolean
  isInteractive?: boolean
  styleName?: string
  onItemSelect?: (e: any) => void
  onViewUpdate?: () => void
  dieCast?: any
}

export default function DesignerCanvas({
  viewType,
  productType,
  imageAssets,
  itemList,
  customMessage = {},
  reRender,
  styleProps = {},
  prefix = '',
  isAdmin = false,
  isInteractive = false,
  styleName = '',
  dieCast = {},
  onItemSelect,
  onViewUpdate,
}: Props) {
  const containerRef = useRef(null)
  const canvasRef = useRef(null)
  const hiddenCanvasRef = useRef(null)
  const [canvasResolution, setCanvasResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  // original glove assets
  const [loadedImages, setLoadedImages] = useState<any>({})
  // glove assets with colors applied
  const [coloredImages, setColoredImages] = useState<any>({})
  // color code for its respective image
  const [imageColors, setImageColors] = useState<any>({})
  // external images (user and team logos)
  const [externalImages, setExternalImages] = useState<any>({})
  const [canvasObj] = useState<any>({
    label: null,
    shadow: {},
    assets: {},
    swoosh: {},
    palm: {},
    '3dLayer': {},
    customMessage: {},
    dieCast: {},
    activeObject: null,
  })

  useEffect(loadImages, [imageAssets])

  useEffect(() => {
    if (Object.keys(loadedImages).length) {
      getStartPosition(loadedImages)
      setTimeout(updateImageColors, 0)
    }
    // eslint-disable-next-line
  }, [loadedImages, reRender])

  function loadImages() {
    const imagesObj: any = {}

    Object.keys(imageAssets).forEach((key: any) => {
      let assets = imageAssets[key]
      if (!imagesObj[key]) {
        imagesObj[key] = {}
      }

      assets.forEach(function (asset: any, i: number) {
        loadImage(asset.src).then((img: any) => {
          imagesObj[key][i] = img
          if (is2dLengthEqual(imageAssets, imagesObj)) {
            setLoadedImages(imagesObj)
          }
        })
      })
    })
  }

  function updateImageColors() {
    const imagesObj: any = { ...coloredImages }
    const colorsObj: any = { ...imageColors }
    let changedImagesCount = 0

    Object.keys(imageAssets).forEach((key: any) => {
      let assets = imageAssets[key]
      if (!imagesObj[key]) {
        imagesObj[key] = {}
        colorsObj[key] = {}
      }

      assets.forEach(function (asset: any, i: number) {
        const selectedOption = getSelectedColorForAsset(asset)

        if (selectedOption && selectedOption.code) {
          if (selectedOption.code !== colorsObj[key][i]) {
            changedImagesCount++
            const imageData = getColoredImageData(
              loadedImages[key][i],
              selectedOption.code,
              asset,
            )

            if (window['createImageBitmap']) {
              createImageBitmap(imageData).then(function (img) {
                imagesObj[key][i] = img
                colorsObj[key][i] = selectedOption.code

                if (is2dLengthEqual(imageAssets, imagesObj)) {
                  setColoredImages(imagesObj)
                  setImageColors(colorsObj)

                  viewType === 'Die Cast' || viewType === 'Small Die Cast'
                    ? loadDieCastImages(imagesObj)
                    : loadExternalImages(imagesObj)
                }
              })
            } else {
              // for browsers which do not support createImageBitmap
              const canvas = document.createElement('canvas')
              const ctx: any = canvas.getContext('2d')
              canvas.width = imageData.width
              canvas.height = imageData.height
              ctx.putImageData(imageData, 0, 0)

              loadImage(canvas.toDataURL()).then((img: any) => {
                imagesObj[key][i] = img
                colorsObj[key][i] = selectedOption.code

                if (is2dLengthEqual(imageAssets, imagesObj)) {
                  setColoredImages(imagesObj)
                  setImageColors(colorsObj)

                  viewType === 'Die Cast' || viewType === 'Small Die Cast'
                    ? loadDieCastImages(imagesObj)
                    : loadExternalImages(imagesObj)
                }
              })
            }
          }
        } else {
          imagesObj[key][i] = loadedImages[key][i]
        }
      })
    })

    if (changedImagesCount === 0) {
      setColoredImages(imagesObj)
      setImageColors(colorsObj)

      viewType === 'Die Cast' || viewType === 'Small Die Cast'
        ? loadDieCastImages(imagesObj)
        : loadExternalImages(imagesObj)
    }
  }

  //get the selected color for related item
  function getSelectedColorForAsset(asset: any) {
    const relatedItem = itemList.find(
      (item: any) => item.itemId === asset.itemId,
    )
    let selectedOption = null
    if (relatedItem) {
      if (relatedItem.itemType === 'swoosh') {
        if (asset.secondary) {
          selectedOption = relatedItem.secondaryColors.find(
            (option: any) => !!option.selected,
          )
        } else {
          selectedOption = relatedItem.primaryColors.find(
            (option: any) => !!option.selected,
          )
        }
      } else if (relatedItem.itemType === 'palm') {
        if (relatedItem.material === 'Leather') {
          selectedOption = { code: '#bfc7c5' }
        } else if (relatedItem.material === 'Hydragrip') {
          selectedOption = { code: '#121519' }
        } else {
          if (asset.secondary) {
            selectedOption = relatedItem.secondaryColors.find(
              (option: any) => !!option.selected,
            )
          } else if (asset.tertiary) {
            selectedOption = relatedItem.tertiaryColors.find(
              (option: any) => !!option.selected,
            )
          } else {
            if (
              (relatedItem.pattern === 'Solid' ||
                relatedItem.pattern === 'Glitter') &&
              asset.position
            ) {
              selectedOption = relatedItem.primaryColors.find(
                (option: any) =>
                  !!option.selected &&
                  option.position &&
                  option.position.indexOf(asset.position) > -1,
              )
            } else {
              selectedOption = relatedItem.primaryColors.find(
                (option: any) => !!option.selected,
              )
            }
          }
        }
      } else {
        selectedOption = relatedItem.options.find(
          (option: any) => !!option.selected,
        )

        if (!selectedOption && relatedItem.optionalColors) {
          selectedOption = relatedItem.optionalColors.find(
            (option: any) => !!option.selected,
          )
        }
      }
    } else if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      selectedOption = dieCast.primaryColors.find(
        (option: any) =>
          !!option.selected &&
          option.position &&
          option.position.indexOf(asset.position) > -1,
      )
    }

    return selectedOption
  }

  function getColoredImageData(image: any, color: any, assetObj: any) {
    const hiddenCanvas: any = hiddenCanvasRef.current
    const hiddenCtx = hiddenCanvas && hiddenCanvas.getContext('2d')
    const rgb: any = hexToRgb(color)

    hiddenCtx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height)
    hiddenCtx.drawImage(image, 0, 0)

    const imageData = hiddenCtx.getImageData(0, 0, image.width, image.height)
    const colorData = imageData.data
    const previousColor = getPreviousColor(colorData)

    for (let i = 0; i < colorData.length; i += 4) {
      let R, G, B
      if (assetObj.fullColor) {
        R = rgb.r
        G = rgb.g
        B = rgb.b
      } else {
        let r = previousColor.r - colorData[i]
        let g = previousColor.g - colorData[i + 1]
        let b = previousColor.b - colorData[i + 2]
        R = adjustColorValue(rgb.r - r)
        G = adjustColorValue(rgb.g - g)
        B = adjustColorValue(rgb.b - b)
      }

      colorData[i] = R
      colorData[i + 1] = G
      colorData[i + 2] = B
    }

    return imageData
  }

  function adjustColorValue(val: number) {
    if (val < 0) {
      return 0
    } else if (val > 255) {
      return 255
    }

    return val
  }

  function getPreviousColor(colorData: any[]) {
    const colors: any = {}
    for (let i = 0; i < colorData.length; i += 4) {
      let key = colorData[i] + '-' + colorData[i + 1] + '-' + colorData[i + 2]
      if (colors[key]) {
        colors[key]++
      } else {
        colors[key] = 1
      }
    }
    delete colors['0-0-0']

    const keys: any[] = Object.keys(colors)
    const values: number[] = Object.values(colors)
    const maxValue = Math.max(...values)

    const maxValueIndex = values.indexOf(maxValue)

    const mostUsedColor = keys[maxValueIndex] && keys[maxValueIndex].split('-')

    return {
      r: parseInt(mostUsedColor[0], 10),
      g: parseInt(mostUsedColor[1], 10),
      b: parseInt(mostUsedColor[2], 10),
    }
  }

  function loadExternalImages(imagesObj: any) {
    if (viewType === 'Palm of Hand') {
      const palmImagesObj: any = {}
      const palmMaterial = itemList.find((item) => item.itemType === 'palm')
      if (palmMaterial && palmMaterial.material === 'Magnigrip Silicon') {
        if (palmMaterial.pattern === 'Solid' && palmMaterial.customPattern) {
          const customPattern = palmMaterial.customPattern.find(
            (pattern: any) => !!pattern.selected,
          )

          if (customPattern) {
            palmImagesObj['customPattern'] = null
            loadExternalImage(customPattern.src).then((img: any) => {
              palmImagesObj['customPattern'] = img
              if (
                Object.keys(palmImagesObj).length ===
                Object.values(palmImagesObj).filter((val) => val !== null)
                  .length
              ) {
                renderCanvas(imagesObj, palmImagesObj)
              }
            })
          }
        }

        palmMaterial.logos.forEach((logo: any, index: number) => {
          if (logo.selected && logo.position) {
            palmImagesObj[index] = null
            loadExternalImage(logo.logo).then((img) => {
              palmImagesObj[index] = img
              if (
                Object.keys(palmImagesObj).length ===
                Object.values(palmImagesObj).filter((val) => val !== null)
                  .length
              ) {
                renderCanvas(imagesObj, palmImagesObj)
              }
            })
          }
        })
      }

      if (
        Object.keys(palmImagesObj).length ===
        Object.values(palmImagesObj).filter((val) => val !== null).length
      ) {
        renderCanvas(imagesObj)
      }
    } else {
      renderCanvas(imagesObj)
    }
  }

  function loadDieCastImages(imagesObj: any) {
    if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      const dieCastImageObj: any = {}
      dieCast.logos.forEach((logo: any, index: number) => {
        if (logo.selected && logo.position) {
          dieCastImageObj[index] = null
          loadExternalImage(logo.logo).then((img) => {
            dieCastImageObj[index] = img
            if (
              Object.keys(dieCastImageObj).length ===
              Object.values(dieCastImageObj).filter((val) => val !== null)
                .length
            ) {
              renderCanvas(imagesObj, dieCastImageObj)
            }
          })
        }
      })
      if (dieCast.customPattern.length) {
        dieCast.customPattern.forEach((pattern: any, index: any) => {
          if (pattern.selected && pattern.position) {
            dieCastImageObj['customPattern'] = {}
            dieCastImageObj['customPattern'][index] = null
            loadExternalImage(pattern.src).then((img: any) => {
              dieCastImageObj['customPattern'][index] = img
              if (
                Object.keys(dieCastImageObj).length ===
                Object.values(dieCastImageObj).filter((val) => val !== null)
                  .length
              ) {
                renderCanvas(imagesObj, dieCastImageObj)
              }
            })
          }
        })
      }
      if (
        Object.keys(dieCastImageObj).length ===
        Object.values(dieCastImageObj).filter((val) => val !== null).length
      ) {
        renderCanvas(imagesObj)
      }
    } else {
      renderCanvas(imagesObj)
    }
  }

  function renderCanvas(images: any, palmImages?: any) {
    const startPosition = getStartPosition(images)
    if (!canvasObj.canvas) {
      canvasObj.canvas = new fabric.Canvas(canvasRef.current, {
        width: startPosition.largestImageWidth,
        height: startPosition.largestImageHeight,
        hoverCursor: isInteractive ? 'pointer' : 'default',
        selection: false,
      })

      if (isInteractive) {
        canvasObj.canvas.on('mouse:over', function (e: any) {
          if (e.target) {
            let itemId = e.target.name
            if (itemId) {
              let objects = canvasObj.canvas.getObjects()
              objects.forEach((obj: any) => {
                if (obj.name === itemId) {
                  obj.filters[0] = new fabric.Image.filters.Brightness({
                    brightness: 0.3,
                  })
                  obj.applyFilters()
                }
              })
              canvasObj.canvas.requestRenderAll()
            }
          }
        })

        canvasObj.canvas.on('mouse:out', function (e: any) {
          if (e.target) {
            let itemId = e.target.name
            if (itemId) {
              let objects = canvasObj.canvas.getObjects()
              objects.forEach((obj: any) => {
                if (obj.name === itemId) {
                  obj.filters[0] = false
                  obj.applyFilters()
                }
              })
              canvasObj.canvas.requestRenderAll()
            }
          }
        })

        canvasObj.canvas.on('mouse:down', function (e: any) {
          if (e.target) {
            let itemId = e.target.name
            if (itemId && onItemSelect) {
              let selectedItem = itemList.find((item) => item.itemId === itemId)
              onItemSelect(selectedItem)
            }
          }
        })

        canvasObj.canvas.on('mouse:up', function (e: any) {
          if (canvasObj.activeObject) {
            const { left, top, angle, scaleX } = e.target
            canvasObj.activeObject.x = left
            canvasObj.activeObject.y = top
            canvasObj.activeObject.angle = angle
            canvasObj.activeObject.scale = scaleX
          }
        })
      }
    }

    if (imageAssets['shadow'] && !prefix) {
      renderShadow(canvasObj.canvas, images, startPosition)
    }

    renderAssets(canvasObj.canvas, images, startPosition)

    if (imageAssets['3dLayer']) {
      render3dLayer(canvasObj.canvas, images, startPosition)
    }

    if (viewType === 'Top of Hand') {
      renderSwoosh(canvasObj.canvas, images, startPosition)
    }

    if (viewType === 'Palm of Hand') {
      renderPalmMaterial(canvasObj.canvas, images, palmImages, startPosition)
    }
    renderOverlayAssets(canvasObj.canvas, images, startPosition)
    if (viewType === 'Palm of Hand') {
      renderPalmLogos(canvasObj.canvas, palmImages, startPosition)
    }

    if (viewType === 'Left Inside Cuff' || viewType === 'Right Inside Cuff') {
      renderCustomMsg(canvasObj.canvas, startPosition)
    }

    if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      renderCustomPattern(canvasObj.canvas, palmImages, startPosition)
    }

    if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      renderDieCastLogos(canvasObj.canvas, palmImages, startPosition)
    }

    if (styleName) {
      renderLabel(canvasObj.canvas, startPosition)
    }

    canvasObj.canvas.requestRenderAll()
  }

  function renderShadow(canvas: any, images: any, startPosition: any) {
    imageAssets.shadow.forEach(function (asset: any, i: number) {
      if (!canvasObj.shadow[i]) {
        canvasObj.shadow[i] = new fabric.Image(images['shadow'][i], {
          left: asset.x + startPosition.x,
          top: asset.y + startPosition.y,
          width: images['shadow'][i].width,
          height: images['shadow'][i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: false,
        })

        canvas.add(canvasObj.shadow[i])
      } else {
        canvasObj.shadow[i].setElement(images['shadow'][i])
      }
    })
  }

  function renderAssets(canvas: any, images: any, startPosition: any) {
    const swoosh = itemList.find((item) => item.itemType === 'swoosh')

    imageAssets.assets.forEach(function (asset: any, i: number) {
      if (!asset.overlay && (!asset.brand || asset.brand === swoosh.brand)) {
        if (!canvasObj.assets[i]) {
          canvasObj.assets[i] = new fabric.Image(images['assets'][i], {
            left: asset.x + startPosition.x,
            top: asset.y + startPosition.y,
            width: images['assets'][i].width,
            height: images['assets'][i].height,
            globalCompositeOperation: asset.globalComposition || 'source-over',
            selectable: false,
            evented: !!asset.itemId,
            perPixelTargetFind: true,
            name: asset.itemId,
          })

          canvas.add(canvasObj.assets[i])
        } else {
          canvasObj.assets[i].setElement(images['assets'][i])
        }
      }
    })
  }

  function renderSwoosh(canvas: any, images: any, startPosition: any) {
    const swoosh = itemList.find((item) => item.itemType === 'swoosh')

    Object.values(canvasObj.swoosh).forEach((obj) => {
      canvas.remove(obj)
    })
    canvasObj.swoosh = {}

    if (swoosh) {
      const swooshKey = (swoosh.brand + '-' + swoosh.pattern).toLowerCase()
      const swooshImages = imageAssets[swooshKey]

      if (swooshImages) {
        swooshImages.forEach(function (asset: any, i: number) {
          canvasObj.swoosh[i] = new fabric.Image(images[swooshKey][i], {
            left: asset.x + startPosition.x,
            top: asset.y + startPosition.y,
            width: images[swooshKey][i].width,
            height: images[swooshKey][i].height,
            globalCompositeOperation: asset.globalComposition || 'source-over',
            selectable: false,
            evented: !!asset.itemId,
            perPixelTargetFind: true,
            name: asset.itemId,
          })

          canvas.add(canvasObj.swoosh[i])
        })
      }
    }
  }

  function renderCustomPattern(
    canvas: any,
    palmImages: any,
    startPosition: any,
  ) {
    Object.values(canvasObj.dieCast).forEach((obj) => {
      canvasObj.canvas.remove(obj)
    })
    canvasObj.dieCast = {}
    if (dieCast.customPattern) {
      dieCast.customPattern.forEach((pattern: any, i: any) => {
        if (!!pattern.selected && pattern.position && pattern.position.length) {
          pattern.position.forEach((placement: any, placementIndex: number) => {
            renderDieCastPattern(
              canvas,
              palmImages.customPattern[i],
              startPosition,
              pattern,
              placementIndex,
            )
          })
        }
      })
    }
  }
  function renderDieCastPattern(
    canvas: any,
    imag: any,
    startPosition: any,
    pattern: any,
    index: any,
  ) {
    let placement = pattern.position[index]
    let defaultValues = { x: 0, y: 0 }
    switch (placement.name) {
      case 'Left':
        defaultValues = {
          x: 650,
          y: 0,
        }
        break
      case 'Right':
        defaultValues = {
          x: 0,
          y: 0,
        }
        break
    }

    if (!placement.x) {
      placement = {
        ...placement,
        ...{
          x: defaultValues.x + startPosition.x,
          y: defaultValues.y + startPosition.y,
          angle: 0,
        },
        scale: 1,
      }
      pattern.position[index] = placement
    }
    canvasObj.dieCast[`${placement.name}`] = new fabric.Image(imag, {
      left: placement.x,
      top: placement.y,
      angle: placement.angle,
      globalCompositeOperation: 'source-atop',
      selectable: isAdmin && !prefix,
      borderColor: '#0672E4',
      borderDashArray: [16, 12],
      borderScaleFactor: 3,
      borderOpacityWhenMoving: 0.8,
      padding: -45,
      cornerStyle: 'circle',
      cornerSize: 15,
      cornerColor: '#0672E4',
      cornerStrokeColor: 'white',
    })
    canvasObj.dieCast[placement.name].scale(placement.scale)
    canvasObj.dieCast[placement.name].onSelect = function () {
      canvasObj.activeObject = pattern.position[index]
    }
    canvasObj.dieCast[placement.name].onDeselect = function () {
      canvasObj.activeObject = null
      if (onViewUpdate) onViewUpdate()
    }
    canvas.add(canvasObj.dieCast[placement.name])
    // prevent corner resizing
    canvasObj.dieCast[placement.name].setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      mtr: true,
    })
  }

  function renderPalmMaterial(
    canvas: any,
    images: any,
    palmImages: any,
    startPosition: any,
  ) {
    Object.values(canvasObj.palm).forEach((obj) => {
      canvasObj.canvas.remove(obj)
    })
    canvasObj.palm = {}

    const palmMaterial = itemList.find((item) => item.itemType === 'palm')

    if (palmMaterial && palmMaterial.material === 'Magnigrip Silicon') {
      if (palmMaterial.pattern === 'Solid') {
        if (palmMaterial.customPattern) {
          const customPattern = palmMaterial.customPattern.find(
            (pattern: any) => !!pattern.selected,
          )
          if (customPattern) {
            canvasObj.palm.customPattern = new fabric.Image(
              palmImages.customPattern,
              {
                left: startPosition.x,
                top: startPosition.y,
                width: palmImages.customPattern.width,
                height: palmImages.customPattern.height,
                globalCompositeOperation: 'source-over',
                selectable: false,
                evented: false,
              },
            )

            canvas.add(canvasObj.palm.customPattern)
          }
        }
      } else {
        const palmKey = palmMaterial.pattern.toLowerCase().replace(/\s/g, '-')
        const palmImages = imageAssets[palmKey]
        if (palmImages) {
          palmImages.forEach(function (asset: any, i: number) {
            canvasObj.palm[i] = new fabric.Image(images[palmKey][i], {
              left: asset.x + startPosition.x,
              top: asset.y + startPosition.y,
              width: images[palmKey][i].width,
              height: images[palmKey][i].height,
              globalCompositeOperation:
                asset.globalComposition || 'source-over',
              selectable: false,
              evented: false,
            })

            canvas.add(canvasObj.palm[i])
          })
        }
      }
    }
  }

  function renderOverlayAssets(canvas: any, images: any, startPosition: any) {
    imageAssets.assets.forEach(function (asset: any, i: number) {
      if (asset.overlay) {
        if (canvasObj.assets[i]) {
          canvas.remove(canvasObj.assets[i])
        }
        canvasObj.assets[i] = new fabric.Image(images['assets'][i], {
          left: asset.x + startPosition.x,
          top: asset.y + startPosition.y,
          width: images['assets'][i].width,
          height: images['assets'][i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })

        canvas.add(canvasObj.assets[i])
      }
    })
  }

  function renderPalmLogos(canvas: any, images: any, startPosition: any) {
    const palmMaterial = itemList.find((item) => item.itemType === 'palm')
    if (palmMaterial && palmMaterial.material === 'Magnigrip Silicon') {
      palmMaterial.logos.forEach((logo: any, i: number) => {
        if (logo.selected && logo.position) {
          logo.position.forEach((placement: any, placementIndex: number) =>
            drawPalmLogo(
              canvas,
              images[i],
              startPosition,
              logo,
              placementIndex,
            ),
          )
        }
      })
    }
  }

  function renderDieCastLogos(canvas: any, images: any, startPosition: any) {
    dieCast.logos.forEach((logo: any, i: number) => {
      if (!!logo.selected && logo.position && logo.position.length) {
        logo.position.forEach((placement: any, placementIndex: number) =>
          drawDieCastLogo(
            canvas,
            images[i],
            startPosition,
            logo,
            placementIndex,
          ),
        )
      }
    })
  }

  function drawPalmLogo(
    canvas: any,
    img: any,
    startPosition: any,
    logo: any,
    placementIndex: number,
  ) {
    let placement = logo.position[placementIndex]
    let path = ['config', 'logoPositions']
    let defaultPosition = {}
    let size = 80

    switch (placement.name) {
      case 'Right Small Cuff':
        path.push('Small Cuff', 'right')
        defaultPosition = {
          x: 140,
          y: 890,
          angle: 50,
        }
        size = 80
        break
      case 'Left Small Cuff':
        path.push('Small Cuff', 'left')
        defaultPosition = {
          x: 1295,
          y: 900,
          angle: -45,
        }
        size = 80
        break
      case 'Right Middle Palm':
        path.push('Middle Palm', 'right')
        defaultPosition = {
          x: 400,
          y: 675,
          angle: 45,
        }
        size = 325
        break
      case 'Left Middle Palm':
        path.push('Middle Palm', 'left')
        defaultPosition = {
          x: 1060,
          y: 680,
          angle: -45,
        }
        size = 325
        break
      case 'LockUp':
        path.push('LockUp')
        defaultPosition = {
          x: 725,
          y: 600,
        }
        size = 700
        break
    }

    const ratio = size / Math.max(img.width, img.height)

    if (!placement.x) {
      const { x, y, angle = 0 } = objectPath(
        gloveTypes[productType],
        path,
        defaultPosition,
      )

      placement = {
        ...placement,
        ...{ x: x + startPosition.x, y: y + startPosition.y, angle: angle },
        scale: ratio,
      }
      logo.position[placementIndex] = placement
    }

    canvasObj.palm[placement.name] = new fabric.Image(img, {
      left: placement.x,
      top: placement.y,
      globalCompositeOperation: 'source-over',
      originX: 'center',
      originY: 'center',
      angle: placement.angle,
      borderColor: 'white',
      borderDashArray: [16, 12],
      borderScaleFactor: 3,
      borderOpacityWhenMoving: 0.8,
      padding: 10,
      cornerStyle: 'circle',
      cornerSize: 24,
      cornerColor: '#0672e4',
      cornerStrokeColor: 'white',
      selectable: isAdmin && !prefix,
      evented: isAdmin && !prefix,
      perPixelTargetFind: true,
    })
    canvasObj.palm[placement.name].scale(placement.scale)
    canvasObj.palm[placement.name].onSelect = function () {
      canvasObj.activeObject = logo.position[placementIndex]
    }
    canvasObj.palm[placement.name].onDeselect = function () {
      canvasObj.activeObject = null
      if (onViewUpdate) onViewUpdate()
    }
    canvas.add(canvasObj.palm[placement.name])
  }

  function drawDieCastLogo(
    canvas: any,
    img: any,
    startPosition: any,
    logo: any,
    placementIndex: number,
  ) {
    let placement = logo.position[placementIndex]
    let path = ['config', 'dieCastLogoPositions']
    let defaultPosition = {}
    let size = 80

    switch (placement.name) {
      case 'Right Small Cuff':
        path.push('Small Cuff', 'right')
        defaultPosition = {
          x: 140,
          y: 890,
          angle: 50,
        }
        size = 60
        break
      case 'Left Small Cuff':
        path.push('Small Cuff', 'left')
        defaultPosition = {
          x: 1295,
          y: 900,
          angle: -45,
        }
        size = 60
        break
      case 'Right Middle Palm':
        path.push('Middle Palm', 'right')
        defaultPosition = {
          x: 400,
          y: 675,
          angle: 45,
        }
        size = 110
        break
      case 'Left Middle Palm':
        path.push('Middle Palm', 'left')
        defaultPosition = {
          x: 1060,
          y: 680,
          angle: -45,
        }
        size = 110
        break
    }
    const ratio = size / Math.max(img.width, img.height)

    if (!placement.x) {
      const { x, y, angle = 0 } = objectPath(
        gloveTypes[productType],
        path,
        defaultPosition,
      )

      placement = {
        ...placement,
        ...{ x: x + startPosition.x, y: y + startPosition.y, angle: angle },
        scale: ratio,
      }
      logo.position[placementIndex] = placement
    }

    canvasObj.dieCast[placement.name] = new fabric.Image(img, {
      left: placement.x,
      top: placement.y,
      globalCompositeOperation: 'source-over',
      originX: 'center',
      originY: 'center',
      angle: placement.angle,
      selectable: isAdmin && !prefix,
      borderColor: 'white',
      borderDashArray: [16, 12],
      borderScaleFactor: 3,
      borderOpacityWhenMoving: 0.8,
      padding: 10,
      cornerStyle: 'circle',
      cornerSize: 24,
      cornerColor: '#0672e4',
      cornerStrokeColor: 'white',
    })
    canvasObj.dieCast[placement.name].scale(placement.scale)
    canvasObj.dieCast[placement.name].onSelect = function () {
      canvasObj.activeObject = logo.position[placementIndex]
    }
    canvasObj.dieCast[placement.name].onDeselect = function () {
      canvasObj.activeObject = null
      if (onViewUpdate) onViewUpdate()
    }
    canvas.add(canvasObj.dieCast[placement.name])
  }

  function render3dLayer(canvas: any, images: any, startPosition: any) {
    imageAssets['3dLayer'].forEach(function (asset: any, i: number) {
      if (!canvasObj['3dLayer'][i]) {
        canvasObj['3dLayer'][i] = new fabric.Image(images['3dLayer'][i], {
          left: asset.x + startPosition.x,
          top: asset.y + startPosition.y,
          width: images['3dLayer'][i].width,
          height: images['3dLayer'][i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: false,
        })

        canvas.add(canvasObj['3dLayer'][i])
      } else {
        canvasObj['3dLayer'][i].setElement(images['3dLayer'][i])
      }
    })
  }

  function renderLabel(canvas: any, startPosition: any) {
    if (canvasObj.label) {
      canvas.remove(canvasObj.label)
    }

    canvasObj.label = new fabric.Text(styleName, {
      fill: '#000',
      left: startPosition.largestImageWidth * 0.5,
      top: 35,
      originX: 'center',
      originY: 'center',
      fontFamily: 'NikeFieldTypeFootball',
      fontSize: 70,
      textAlign: 'center',
      selectable: false,
    })

    canvas.add(canvasObj.label)
  }

  function renderCustomMsg(canvas: any, startPosition: any) {
    Object.values(canvasObj.customMessage).forEach((obj) => {
      canvas.remove(obj)
    })
    canvasObj.customMessage = {}

    if (customMessage.type === 'text') {
      let fontSize = 180
      if (customMessage.value) {
        if (customMessage.value.length > 16) {
          fontSize = 90
        } else if (customMessage.value.length > 12) {
          fontSize = 120
        } else if (customMessage.value.length > 8) {
          fontSize = 150
        }
      }
      canvasObj.customMessage.text = new fabric.Text(
        customMessage.value || '',
        {
          fill: customMessage.color || '#000',
          left: 640 + startPosition.x,
          top: 320 + startPosition.y,
          originX: 'center',
          originY: 'center',
          fontFamily: 'NikeFieldTypeFootball',
          fontSize,
          textAlign: 'center',
          selectable: false,
          evented: false,
        },
      )

      canvas.add(canvasObj.customMessage.text)
    } else if (customMessage.type === 'logo') {
      loadExternalImage(customMessage.value).then((img: any) => {
        const widthRatio = 960 / img.width
        const heightRatio = 320 / img.height
        const ratio = Math.min(widthRatio, heightRatio)

        canvasObj.customMessage.logo = new fabric.Image(img, {
          left: 645 + startPosition.x,
          top: 250 + startPosition.y,
          originX: 'center',
          originY: 'center',
          globalCompositeOperation: 'source-over',
          selectable: false,
          evented: false,
        })
        canvasObj.customMessage.logo.scale(ratio)

        canvas.add(canvasObj.customMessage.logo)
      })
    }
  }

  function loadExternalImage(src: string) {
    return new Promise((resolve: any, reject: any) => {
      if (externalImages[src]) {
        resolve(externalImages[src])
      } else {
        loadImage(src, true).then((img) => {
          setExternalImages({ ...externalImages, [src]: img })
          resolve(img)
        })
      }
    })
  }

  function getStartPosition(images: any) {
    let largestImageWidth = 0,
      largestImageHeight = 0,
      negativeOffsetX = 0,
      negativeOffsetY = 0

    Object.values(images['assets']).forEach((image: any, index: number) => {
      const { x, y } = imageAssets.assets[index]
      if (image.width + x > largestImageWidth) {
        largestImageWidth = image.width + x
      }
      if (image.height + y > largestImageHeight) {
        largestImageHeight = image.height + y
      }

      if (negativeOffsetX > x) {
        negativeOffsetX = x
      }
      if (negativeOffsetY > y) {
        negativeOffsetY = y
      }
    })

    if (images['shadow'] && !prefix) {
      Object.values(images['shadow']).forEach((image: any, index: number) => {
        const { x, y } = imageAssets.shadow[index]

        if (image.width + x > largestImageWidth) {
          largestImageWidth = image.width + x
        }
        if (image.height + y > largestImageHeight) {
          largestImageHeight = image.height + y
        }

        if (negativeOffsetX > x) {
          negativeOffsetX = x
        }
        if (negativeOffsetY > y) {
          negativeOffsetY = y
        }
      })
    }

    largestImageWidth = largestImageWidth + Math.abs(negativeOffsetX) + 2
    largestImageHeight = largestImageHeight + Math.abs(negativeOffsetY) + 2

    if (styleName) {
      largestImageHeight = largestImageHeight + 90
    }

    setCanvasResolution({
      width: largestImageWidth,
      height: largestImageHeight,
    })

    return {
      x: Math.abs(negativeOffsetX),
      y: (styleName ? 90 : 0) + Math.abs(negativeOffsetY),
      largestImageWidth,
      largestImageHeight,
    }
  }

  return (
    <CanvasContainer style={styleProps}>
      <CanvasInnerContainer ref={containerRef}>
        <canvas
          id={
            (prefix ? prefix + '-' : '') +
            viewType.toLowerCase().replace(/\s/g, '-')
          }
          ref={canvasRef}
          style={{ maxWidth: '100%', maxHeight: '100%' }}></canvas>
        <canvas
          ref={hiddenCanvasRef}
          width={canvasResolution.width}
          height={canvasResolution.height}
          style={{
            display: 'none',
          }}></canvas>
      </CanvasInnerContainer>
    </CanvasContainer>
  )
}
