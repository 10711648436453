import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { objectPath, useLocation, generateThumbnail } from '@app/utils'
import { useGetOrder, useUpdateOrder } from '@app/graphql'
import { Icon, Toast } from '@app/components'
import {
  Container,
  OverlayContainer,
  OverlayMessageContainer,
  OverlayIconContainer,
  OverlayMessage,
} from './designer-components'
import DesignerView from './designer-view'
import SuccessMessage from './success-message'

export default function Designer() {
  const { orderId }: any = useParams()
  const { navigate } = useLocation()
  const { loading, data, error } = useGetOrder({ orderId: orderId })
  const order = objectPath(data, ['order'], {})
  const playerId = objectPath(data, ['order', 'player', '_id'], '')
  const playerCode = objectPath(data, ['order', 'player', 'playerCode'], '')
  const productId = objectPath(data, ['order', 'product', '_id'], '')
  const productType = objectPath(data, ['order', 'product', 'name'], 'VJ6')
  const productItems = objectPath(data, ['order', 'productItems'], [])
  const customMessage = objectPath(data, ['order', 'customMessage'], {})
  const diecastItem = objectPath(data, ['order', 'dieCast'], {})
  const [orderTitle, setOrderTitle] = useState('')
  const [colorList, setColorList] = useState<any[]>([])
  const [logoList, setLogoList] = useState<any[]>([])
  const [overlayMessage, setOverlayMessage] = useState('')
  const [toastMessage, setToastMessage] = useState<any>({
    type: '',
    message: '',
    title: '',
  })
  const [updateOrder] = useUpdateOrder({
    playerCode: playerCode,
  })
  const [showToast, setShowToast] = React.useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    if (order) {
      updateOrderTitle()
      updateColorsAndLogos()
    }
    // eslint-disable-next-line
  }, [order])

  if (loading) {
    return <Icon name="nikeLoading" />
  }
  if (error) {
    return <div>Error</div>
  }

  function updateOrderTitle() {
    let title = [order.orderCode]

    if (order.orderColorNumber) {
      title.push(order.orderColorNumber)
    }

    setOrderTitle(title.join(' '))
  }

  function updateColorsAndLogos() {
    const { playerLogo = [], teamColor = [], teamLogo = [] } = order

    setColorList(teamColor.filter((color: any) => !!color.status))
    setLogoList([
      ...teamLogo.filter((logo: any) => !!(logo.status && logo.logo)),
      ...playerLogo.filter((logo: any) => !!(logo.status && logo.logo)),
    ])
  }

  function checkNflRules(items: any) {
    const BOHMaterial = items.find((item: any) => {
      if (productType === 'SB6') {
        return item.itemId === 104
      } else if (productType === 'VK4') {
        return item.itemId === 107
      }
      return item.itemId === 101
    })
    const Swoosh = items.find((item: any) => item.itemType === 'swoosh')
    const BOHMaterialColor =
      BOHMaterial.options.find((color: any) => !!color.selected) ||
      BOHMaterial.optionalColors.find((color: any) => !!color.selected)
    const SwooshColor = Swoosh.primaryColors.find(
      (color: any) => !!color.selected,
    )

    if (
      BOHMaterialColor &&
      SwooshColor &&
      BOHMaterialColor.code &&
      SwooshColor.code &&
      BOHMaterialColor.code === SwooshColor.code
    ) {
      setShowToast(true)
      setToastMessage({
        type: 'warn',
        title: 'Color not Available',
        message: `Due to NFL rules, the Swoosh/Jumpman and BOH must contrast each other. Please change the Suggested Options and retry saving`,
      })
      return false
    } else {
      setShowToast(false)
      setToastMessage({})
      return true
    }
  }

  function save(items: any[], customMsg: any) {
    if (!checkNflRules(items)) {
      return
    }

    setOverlayMessage('Saving design changes. Please wait...')

    generateThumbnail(order._id, 'preview-top-of-hand').then((res: any) => {
      const updatedItems = items.map((item: any) => {
        let itemCopy = { ...item }
        delete itemCopy.__typename
        return itemCopy
      })

      updateOrder({
        variables: {
          _id: order._id,
          playerId: playerId,
          productId: productId,
          trackStatus:
            order.trackStatus && order.trackStatus === 'Submitted'
              ? 'Submitted'
              : 'In-progress',
          thumbnailLogo: res.Location,
          productItems: updatedItems,
          customMessage: customMsg,
        },
      }).then(() => {
        if (order.trackStatus === 'Submitted') {
          setOverlayMessage(`Design changes have been saved successfully.`)
        } else {
          setOverlayMessage(`
          Design changes have been saved successfully.
          Click “Submit Order” to complete your design and send back to Nike.
          `)
        }

        setTimeout(() => setOverlayMessage(''), 4000)
      })

      order.trackStatus =
        order.trackStatus && order.trackStatus === 'Submitted'
          ? 'Submitted'
          : 'In-progress'
      order.customMessage = customMsg
    })
  }

  function submitOrder(items: any[], customMsg: any) {
    if (!checkNflRules(items)) {
      return
    }

    setOverlayMessage('Submitting design. Please wait...')

    generateThumbnail(order._id, 'preview-top-of-hand').then((res: any) => {
      const updatedItems = items.map((item: any) => {
        let itemCopy = { ...item }
        delete itemCopy.__typename
        return itemCopy
      })

      updateOrder({
        variables: {
          _id: order._id,
          playerId: playerId,
          productId: productId,
          trackStatus: 'Submitted',
          orderStatus: 'Player Approved',
          thumbnailLogo: res.Location,
          productItems: updatedItems,
          customMessage: customMsg,
        },
      }).then(() => {
        setOverlayMessage('')
        setShowSuccessMessage(true)
      })
      order.trackStatus = 'Submitted'
      order.customMessage = customMsg
    })
  }

  return (
    <>
      <Toast
        message={toastMessage.message}
        showToast={showToast}
        type={toastMessage.type}
        title={toastMessage.title}
        onClose={() => setShowToast(false)}
      />

      <Container blur={showSuccessMessage}>
        <DesignerView
          title={orderTitle}
          trackStatus={order.trackStatus}
          productType={productType}
          productItems={productItems}
          colorList={colorList}
          logoList={logoList}
          customMessage={customMessage}
          isAdmin={false}
          isPdfAvailable={false}
          generatePdf={() => {}}
          generatePng={() => {}}
          onSave={save}
          onSubmit={submitOrder}
          onReturnClick={() => navigate(`/user/orders`)}
          diecastItem={diecastItem}
          createDesign={() => {}}
        />
      </Container>

      {!!overlayMessage && (
        <OverlayContainer>
          <OverlayMessageContainer>
            <OverlayIconContainer>
              <Icon name="nikeLoading" />
            </OverlayIconContainer>
            <OverlayMessage>{overlayMessage}</OverlayMessage>
          </OverlayMessageContainer>
        </OverlayContainer>
      )}

      {showSuccessMessage && (
        <SuccessMessage orderId={orderId} playerCode={playerCode} />
      )}
    </>
  )
}
