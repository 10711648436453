import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const GET_PLAYERS = gql`
  {
    players(limit: 50, page: 1, sort: DESC, search: "") {
      players {
        _id
        name
        description
        playerProfileLogo
        brand
        position
        accessCode
        number
        size
        playerCode
        team {
          _id
          name
          logo {
            name
            logo
            _id
            status
          }
          color {
            name
            code
            publicName
            _id
            status
          }
        }
        logo {
          _id
          name
          logo
          status
        }
      }
    }
  }
`
export function useGetPlayers() {
  return useQuery(GET_PLAYERS, { fetchPolicy: 'network-only' })
}
//fetchPolicy: "network-only"
/* const GET_PLAYER_BY_ID = gql`
  query player($id: ID!) {
    player(_id: $id) {
      _id
      name
      description
      playerProfileLogo
      teamId
      brand
      number
      size
      logo {
        _id
        name
        logo
      }
    }
  }
`
export function useGetPlayersById(variables: { id?: any }) {
  return useQuery(GET_PLAYER_BY_ID, { variables })
} */

const GET_LAZY_PLAYERS = gql`
  {
    players(limit: 50, page: 1, search: "", sort: DESC) {
      count
      pages
      page
      players {
        _id
        name
        description
        playerProfileLogo
        teamId
        accessCode
        position
        playerCode
        brand
        number
        playerCode
        size
        logo {
          _id
          name
          playerId
          status
          logo
          aiLogo
          aiName
        }
      }
    }
  }
`
export function useLazyGetPlayers() {
  return useQuery(GET_LAZY_PLAYERS)
}

const GET_TEAMS_FOR_PLAYERS = gql`
  {
    teams(page: 1, sort: ASC, search: "") {
      count
      pages
      page
      teams {
        value: _id
        label: name
      }
    }
  }
`
export function useGetTeamsPlayer() {
  return useQuery(GET_TEAMS_FOR_PLAYERS)
}
const CREATE_PLAYER = gql`
  mutation createPlayer(
    $_id: ID
    $name: String
    $accessCode: String
    $description: String
    $teamId: String
    $number: Int
    $brand: String
    $size: String
    $position: String
    $playerProfileLogo: String
  ) {
    createPlayer(
      _id: $_id
      name: $name
      description: $description
      teamId: $teamId
      number: $number
      accessCode: $accessCode
      playerProfileLogo: $playerProfileLogo
      brand: $brand
      size: $size
      position: $position
    ) {
      _id
      name
      description
      playerCode
      status
      message
      accessCode
      playerProfileLogo
      teamId
      brand
      number
      size
      position
      logo {
        _id
        name
        playerId
        status
        logo
      }
    }
  }
`

export function useCreatePlayer() {
  return useMutation(CREATE_PLAYER, {
    update(cache, { data: { createPlayer } }) {
      const { players }: any = cache.readQuery({
        query: GET_LAZY_PLAYERS,
        variables: { limit: 50, page: 1, search: '' },
      })
      const playerIndex = players.players.findIndex(
        (player: any) => player._id === createPlayer._id,
      )

      if (playerIndex === -1) {
        players.players.unshift(createPlayer)

        cache.writeQuery({
          query: GET_LAZY_PLAYERS,
          variables: { limit: 50, page: 1, search: '' },
          data: { players: { ...players } },
        })
      }
    },
  })
}
export function useUpdatePlayer() {
  return useMutation(CREATE_PLAYER, {
    update(cache, { data: { createPlayer } }) {
      const { players }: any = cache.readQuery({
        query: GET_LAZY_PLAYERS,
        variables: { limit: 50, page: 1, search: '' },
      })
      const playerIndex = players.players.findIndex(
        (player: any) => player._id === createPlayer._id,
      )

      if (playerIndex > -1) {
        players.players[playerIndex] = createPlayer

        cache.writeQuery({
          query: GET_LAZY_PLAYERS,
          variables: { limit: 50, page: 1, search: '' },
          data: { players: { ...players } },
        })
      }
    },
  })
}
const CREATE_PLAYER_LOGO = gql`
  mutation createPlayerLogo(
    $_id: ID
    $playerId: String
    $name: String
    $status: Boolean
    $logo: String
    $aiLogo: String
    $aiName: String
  ) {
    createPlayerLogo(
      _id: $_id
      playerId: $playerId
      status: $status
      name: $name
      logo: $logo
      aiLogo: $aiLogo
      aiName: $aiName
    ) {
      _id
      playerId
      name
      status
      logo
      aiLogo
      aiName
    }
  }
`

export function useCreatePlayerLogo() {
  return useMutation(CREATE_PLAYER_LOGO, {
    update(cache, { data: { createPlayerLogo } }) {
      const { players }: any = cache.readQuery({
        query: GET_LAZY_PLAYERS,
        variables: { limit: 50, page: 1, search: '' },
      })
      const playerIndex = players.players.findIndex(
        (player: any) => player._id === createPlayerLogo.playerId,
      )
      if (playerIndex > -1) {
        const selectedPlayer = players.players[playerIndex]
        const logoIndex = selectedPlayer.logo.findIndex(
          (logo: any) => logo._id === createPlayerLogo._id,
        )

        if (logoIndex === -1) {
          // selectedPlayer.logo.push(createPlayerLogo)
        }

        cache.writeQuery({
          query: GET_LAZY_PLAYERS,
          variables: { limit: 50, page: 1, search: '' },
          data: { players: { ...players } },
        })
      }
    },
    refetchQueries: [{ query: GET_LAZY_PLAYERS }],
  })
}

const SIZES = [
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  { label: 'XL', value: 'XL' },
  { label: '2XL', value: '2XL' },
  { label: '3XL', value: '3XL' },
  { label: '4XL', value: '4XL' },
  { label: '5XL', value: '5XL' },
]
export function useGetSizes() {
  return SIZES
}

const POSITIONS = [
  { label: 'WR', value: 'WR' },
  { label: 'TE', value: 'TE' },
  { label: 'QB', value: 'QB' },
  { label: 'RB', value: 'RB' },
  { label: 'FB', value: 'FB' },
  { label: 'LT', value: 'LT' },
  { label: 'LG', value: 'LG' },
  { label: 'C', value: 'C' },
  { label: 'RG', value: 'RG' },
  { label: 'RT', value: 'RT' },
  { label: 'CB', value: 'CB' },
  { label: 'DB', value: 'DB' },
  { label: 'S', value: 'S' },
  { label: 'SS', value: 'SS' },
  { label: 'FS', value: 'FS' },
  { label: 'OLB', value: 'OLB' },
  { label: 'ILB', value: 'ILB' },
  { label: 'MLB', value: 'MLB' },
  { label: 'LB', value: 'LB' },
  { label: 'DL', value: 'DL' },
  { label: 'DT', value: 'DT' },
  { label: 'DE', value: 'DE' },
  { label: 'K', value: 'K' },
  { label: 'P', value: 'P' },
]
export function useGetPositions() {
  return POSITIONS
}

const PLAYER_LOGIN = gql`
  mutation playerLogin($accessCode: String) {
    playerLogin(accessCode: $accessCode) {
      status
      message
      data {
        name
        accessCode
        playerCode
      }
    }
  }
`
export function usePlayerLogin() {
  return useMutation(PLAYER_LOGIN)
}
