import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

const GET_USER = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      status
      message
      data {
        _id
        email
        name
        status
        userProfileLogo
      }
    }
  }
`
export function useGetUser() {
  return useMutation(GET_USER)
}

const GET_ALLUSERS = gql`
  query allUsers {
    users {
      _id
      name
      email
      status
      userProfileLogo
    }
  }
`

export function useGetAllUsers() {
  return useQuery(GET_ALLUSERS, {
    // fetchPolicy: 'no-cache',
  })
}

const CREATE_USER = gql`
  mutation createUser(
    $_id: ID
    $name: String
    $email: String
    $status: Boolean
    $userProfileLogo: String
  ) {
    createUser(
      _id: $_id
      name: $name
      email: $email
      status: $status
      userProfileLogo: $userProfileLogo
    ) {
      success
      message
      user {
        _id
        name
        email
        status
        userProfileLogo
      }
    }
  }
`
export function useCreateUser() {
  return useMutation(CREATE_USER)
}

export function useUpdateUser() {
  return useMutation(CREATE_USER, {
    update(cache, { data: { createUser } }) {
      const { users }: any = cache.readQuery({
        query: GET_ALLUSERS,
      })
      const userIndex = users.findIndex(
        (user: any) => user._id === createUser.user._id,
      )
      if (userIndex > -1) {
        users[userIndex] = createUser.user

        cache.writeQuery({
          query: GET_ALLUSERS,
          data: { ...users },
        })
      }
    },
  })
}
