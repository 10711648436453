import top from './top'
import palm from './palm'
import leftCuff from './left-cuff'
import rightCuff from './right-cuff'
import dieCast from './die-cast'
import smallDieCast from './die-cast-small'

const config = {
  brands: [
    {
      name: 'Nike',
      value: 'Nike',
      icon: 'nike',
      patterns: [
        { name: 'Solid', value: 'Solid' },
        { name: 'Glitter', value: 'Glitter' },
        { name: 'Outline', value: 'Outline' },
      ],
    },
    {
      name: 'Jordan',
      value: 'Jordan',
      icon: 'jordan',
      patterns: [
        { name: 'Solid', value: 'Solid' },
        { name: 'Glitter', value: 'Glitter' },
        { name: 'Outline', value: 'Outline' },
      ],
    },
  ],
  palmMaterials: [
    {
      name: 'Leather',
      value: 'Leather',
    },
    {
      name: 'Hydragrip',
      value: 'Hydragrip',
    },
    {
      name: 'Magnigrip Silicon',
      value: 'Magnigrip Silicon',
      patterns: {
        Nike: [
          { name: 'Solid', value: 'Solid' },
          { name: 'Glitter', value: 'Glitter' },
          { name: 'Hypnotic', value: 'Hypnotic' },
        ],
        Jordan: [
          { name: 'Solid', value: 'Solid' },
          { name: 'Glitter', value: 'Glitter' },
          { name: 'Hypnotic', value: 'Hypnotic' },
          { name: 'Jordan 1 Sole', value: 'Jordan 1 Sole' },
        ],
      },
      logoPlacements: [
        { name: 'Right Small Cuff', value: 'Right Small Cuff' },
        { name: 'Left Small Cuff', value: 'Left Small Cuff' },
        { name: 'Right Middle Palm', value: 'Right Middle Palm' },
        { name: 'Left Middle Palm', value: 'Left Middle Palm' },
        { name: 'LockUp', value: 'LockUp' },
      ],
    },
  ],
  logoPositions: {
    'Small Cuff': {
      right: {
        x: 140,
        y: 890,
        angle: 50,
      },
      left: {
        x: 1295,
        y: 900,
        angle: -45,
      },
    },
    'Middle Palm': {
      right: {
        x: 400,
        y: 675,
        angle: 45,
      },
      left: {
        x: 1060,
        y: 680,
        angle: -45,
      },
    },
    LockUp: {
      x: 725,
      y: 600,
    },
  },
  dieCastLogoPositions: {
    'Small Cuff': {
      right: {
        x: 230,
        y: 530,
        angle: 45,
      },
      left: {
        x: 1050,
        y: 530,
        angle: -45,
      },
    },
    'Middle Palm': {
      right: {
        x: 270,
        y: 425,
        angle: 45,
      },
      left: {
        x: 1015,
        y: 425,
        angle: -45,
      },
    },
  },
}

export default {
  config,
  top,
  palm,
  leftCuff,
  rightCuff,
  dieCast,
  smallDieCast,
}
