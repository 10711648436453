import { asset1, asset2, asset3, asset4 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0 },
  { src: asset2, x: 0, y: 436.4746, itemId: 111 },
  { src: asset3, x: 0, y: 453.408 },
  { src: asset4, x: 551.6925, y: 436.4746 },
]

const data = {
  assets: assets,
}

export default data
