import React, { useContext } from 'react'
import { Header } from '@app/components'
import { Container } from './layout-components'
import { AuthContext } from '@app/providers'
export default function Layout() {
  const { pathname } = window.location
  const { userInfo } = useContext(AuthContext)
  const userEmail = userInfo && userInfo.email
  const [email, setEmail] = React.useState(userEmail)
  React.useEffect(() => {
    if (email !== userEmail) {
      setEmail(userEmail)
    }
    // eslint-disable-next-line
  }, [email, userEmail])
  return (
    <Container>
      {pathname.indexOf('/designer') !== 0 &&
        pathname.indexOf('/user') !== 0 &&
        email && <Header />}
    </Container>
  )
}
