import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from 'apollo-boost'
export const baseURL = process.env.REACT_APP_HTTP_BASEURL
const httpLinkOptions: HttpLink.Options = {
  uri: `${baseURL}graphql`,
  credentials: 'same-origin',
}

const httpLink = new HttpLink(httpLinkOptions)

export const client = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache: new InMemoryCache(),
})
