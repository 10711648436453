import * as _ from 'lodash'
export function getLogosCount(logos: any) {
  let list = _.filter(logos, function (o) {
    return o.status
  })
  return list.length < 15 ? true : false
}
export function getShortName(name: any) {
  let arrName = name.split(' ')
  let sName =
    arrName && arrName.length > 1
      ? arrName[0].charAt(0) + arrName[1].charAt(0)
      : arrName.length === 1
      ? arrName[0].charAt(0)
      : '--'
  return sName
}
