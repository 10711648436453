import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '@app/components'
import {
  Container,
  Logo,
  Nav,
  NavItem,
  Separator,
  UserIcon,
  IconContainer,
  Label,
  ToolTipBody,
  ToolTipContainer,
  LogoutText,
  LogoutBox,
  UserImage,
  PreviewImg,
} from './header-components'
import { AuthContext } from '@app/providers'
import { useLocation as pathLocation, getInitialsFromName } from '@app/utils'
export default function Header() {
  const { pathname } = useLocation()
  const { navigate } = pathLocation()
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const [showLogout, setShowLogout] = React.useState<boolean>(false)
  const userName = userInfo && userInfo.name
  const userLogo = (userInfo && userInfo.userProfileLogo) || undefined
  const accountRef: any = React.useRef(null)
  function handleClickOutside(e: any) {
    if (
      showLogout &&
      accountRef.current &&
      !accountRef.current.contains(e.target)
    ) {
      setShowLogout(false)
    }
  }
  document.addEventListener('click', handleClickOutside)

  const signOut = () => {
    localStorage.clear()
    sessionStorage.clear()
    setUserInfo(null)
    navigate('/')
  }
  const AccountDialog = () => {
    return (
      <ToolTipContainer>
        <ToolTipBody>
          <LogoutBox>
            <Icon name="logout" />
            <LogoutText onClick={signOut}>Logout</LogoutText>
          </LogoutBox>
        </ToolTipBody>
      </ToolTipContainer>
    )
  }

  return (
    <Container>
      <Logo>
        <IconContainer>
          <Icon name="nike" />
        </IconContainer>
        <Label>Equipment Builder</Label>
      </Logo>

      <Nav>
        <NavItem active={pathname.indexOf('/orders') === 0 || pathname === '/'}>
          <Link to="/orders">
            <IconContainer>
              <Icon name="home" />
            </IconContainer>
            <Label>Designs</Label>
          </Link>
        </NavItem>
        <NavItem active={pathname === '/teams'}>
          <Link to="/teams">
            <IconContainer>
              <Icon name="team" />
            </IconContainer>
            <Label>Teams</Label>
          </Link>
        </NavItem>
        <NavItem active={pathname === '/players'}>
          <Link to="/players">
            <IconContainer>
              <Icon name="player" />
            </IconContainer>
            <Label>Players</Label>
          </Link>
        </NavItem>
        <NavItem active={pathname === '/styles'}>
          <Link to="/styles">
            <IconContainer>
              <Icon name="product" />
            </IconContainer>
            <Label>Styles</Label>
          </Link>
        </NavItem>
        <NavItem active={pathname === '/admins'}>
          <Link to="/admins">
            <IconContainer>
              <Icon name="player" />
            </IconContainer>
            <Label>Admins</Label>
          </Link>
        </NavItem>
      </Nav>

      <NavItem>
        <Link to="/create-order">
          <IconContainer>
            <Icon name="add" />
          </IconContainer>
        </Link>
      </NavItem>

      <Separator />

      <UserIcon ref={accountRef}>
        <UserImage onClick={() => setShowLogout(!showLogout)}>
          {getInitialsFromName(userName)}
          {userLogo && <PreviewImg image={userLogo} />}
        </UserImage>

        {showLogout && <AccountDialog />}
      </UserIcon>
    </Container>
  )
}
