import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import JSZIP from 'jszip'
import { saveAs } from 'file-saver'
import moment from 'moment'
import {
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset9,
  asset10,
  asset11,
  asset12,
  pdfLogo,
} from '@app/data/sb6/pdf-images'
import {
  Vjasset1,
  Vjasset7,
  Vjasset6,
  Vjasset5,
  Vjasset4,
  Vjasset3,
  Vjasset2,
  Vjasset8,
} from '@app/data/vj6/pdf-images'
import {
  Vkasset1,
  Vkasset2,
  Vkasset3,
  Vkasset4,
  Vkasset5,
  Vkasset6,
  Vkasset7,
} from '@app/data/vk4/pdf-images'

function getSelectedColor(data: any) {
  let obj = data.find((option: any) => !!option.selected)
  let colorName = obj && obj.name ? obj.name : '--'
  let colorCode = obj && obj.code ? obj.code : ''
  return { colorName: colorName, colorCode: colorCode }
}
function getImageDimensions(file: any) {
  return new Promise(function (resolved, rejected) {
    var i = new Image()
    i.onload = function () {
      resolved({ w: i.width, h: i.height, image: i })
    }
    i.crossOrigin = ''
    i.src = file
  })
}
function getAiLogos(logos: any) {
  let list: any = []
  logos &&
    logos.forEach((logo: any) => {
      if (logo.aiLogo) {
        list.push(logo)
      }
    })
  return list
}

const convertImagetoBase64String = (image: any) => {
  var img = new Image()
  img.src = image
  return img
}

export const getPdf = async (params: any) => {
  let topUrl = ''
  let palmUrl = ''
  let letInsideUrl = ''
  let rightInsideUrl = ''
  let dieCastUrl = ''
  let dieCastSmallUrl = ''
  const { canvas = false } = params
  if (canvas) {
    topUrl = (document.getElementById(
      'preview-top-of-hand',
    ) as HTMLCanvasElement).toDataURL()
    palmUrl = (document.getElementById(
      'preview-palm-of-hand',
    ) as HTMLCanvasElement).toDataURL()
    letInsideUrl = (document.getElementById(
      'preview-left-inside-cuff',
    ) as HTMLCanvasElement).toDataURL()
    rightInsideUrl = (document.getElementById(
      'preview-right-inside-cuff',
    ) as HTMLCanvasElement).toDataURL()
    dieCastUrl = (document.getElementById(
      'die-cast',
    ) as HTMLCanvasElement).toDataURL()
    dieCastSmallUrl = (document.getElementById(
      'small-die-cast',
    ) as HTMLCanvasElement).toDataURL()
  } else {
    topUrl = (document.getElementById(
      'order-preview-top-of-hand',
    ) as HTMLCanvasElement).toDataURL()
    palmUrl = (document.getElementById(
      'order-preview-palm-of-hand',
    ) as HTMLCanvasElement).toDataURL()
    letInsideUrl = (document.getElementById(
      'order-preview-left-inside-cuff',
    ) as HTMLCanvasElement).toDataURL()
    rightInsideUrl = (document.getElementById(
      'order-preview-right-inside-cuff',
    ) as HTMLCanvasElement).toDataURL()
    dieCastUrl = (document.getElementById(
      'order-preview-die-cast',
    ) as HTMLCanvasElement).toDataURL()
    dieCastSmallUrl = (document.getElementById(
      'order-preview-small-die-cast',
    ) as HTMLCanvasElement).toDataURL()
  }
  let rows: any = []
  const dimensions: any = await getImageDimensions(topUrl)
  const topUrlRatio = dimensions.h / dimensions.w
  const dimensionsPalm: any = await getImageDimensions(palmUrl)
  const palmUrlRatio = dimensionsPalm.h / dimensionsPalm.w
  const dimensionsLeftInsideUrl: any = await getImageDimensions(letInsideUrl)
  const letInsideUrlRatio =
    dimensionsLeftInsideUrl.h / dimensionsLeftInsideUrl.w
  const dimensionsRightInsideUrl: any = await getImageDimensions(rightInsideUrl)
  const rightInsideUrlRatio =
    dimensionsRightInsideUrl.h / dimensionsRightInsideUrl.w
  params.products.forEach((item: any) => {
    let color: any = {},
      obj: any = {}
    if (item.itemType === 'swoosh') {
      color = getSelectedColor([...item.primaryColors])
      obj['id'] = item.component
      obj['location'] = item.item
      obj['color'] = color.colorName
      obj['code'] = color.colorCode
      rows.push(obj)
      color = getSelectedColor([...item.secondaryColors])
      if (color.colorCode !== '') {
        let optionalObj = {
          id: '',
          location: item.pattern,
          color: color.colorName,
          material: '',
          code: color.colorCode,
          supplier: item.supplier,
        }
        rows.push(optionalObj)
      }
    } else if (item.itemType === 'palm') {
      let optionalObj = {
        id: item.component,
        location: item.item,
        color: '',
        material: item.material,
        code: '',
        supplier: item.supplier,
      }
      rows.push(optionalObj)
      let primaryColor = getSelectedColor([...item.primaryColors])
      let primaryObj = {
        id: '',
        location: 'Palm Base Color',
        color: primaryColor.colorName,
        material: '',
        code: primaryColor.colorCode,
      }
      if (primaryColor.colorCode !== '') {
        rows.push(primaryObj)
      }
      let secondaryColor = getSelectedColor([...item.secondaryColors])
      let secondaryObj = {
        id: '',
        location: 'Palm Secondary Color',
        color: secondaryColor.colorName,
        material: '',
        code: secondaryColor.colorCode,
      }
      if (secondaryColor.colorCode !== '') {
        rows.push(secondaryObj)
      }

      if (item.pattern !== '') {
        let palmGraphicsObj = {
          id: '',
          location: 'Palm Graphics',
          color: '',
          material: item.pattern,
          code: '',
          supplier: item.supplier,
        }
        rows.push(palmGraphicsObj)
      }
    } else {
      color = getSelectedColor([...item.options, ...item.optionalColors])
      obj = {
        id: item.component,
        location: item.item,
        color: color.colorName,
        material: item.notes,
        code: color.colorCode,
        supplier: item.supplier,
      }
      rows.push(obj)
    }
  })
  let specColumns = [
    { head: 'COMPONENT', key: 'id' },
    { head: 'LOCATION', key: 'location' },
    { head: 'COLOR', key: 'color' },
    { head: 'MATERIAL', key: 'material' },
    { head: 'SUPPLIER', key: 'supplier' },
  ]
  let specColumnDef: any = []
  specColumns.forEach((item: any, index: any) => {
    let obj = {
      header: item.head,
      dataKey: item.key,
    }
    specColumnDef.push(obj)
  })
  let specColumnHeader: any = []
  specColumns.forEach((item: any, index: any) => {
    let obj = {
      content: item.head,
      styles: {
        halign: 'left',
        valign: 'middle',
        fontStyle: 'bold',
        fontSize: 10,
        lineWidth: 0.1,
        textColor: '#323232',
        fillColor: '#e1e1e1',
      },
    }
    specColumnHeader.push(obj)
  })
  var doc = new jsPDF('landscape', '', '', 10)
  doc.setFontSize(12)

  var width = doc.internal.pageSize.getWidth()
  var height = doc.internal.pageSize.getHeight()
  let pageHeight = height - 50
  let coordinates = {
    topLeft: { x: 0, y: 0, w: 0, h: 0 },
    topRight: { x: 0, y: 0, w: 0, h: 0 },
    bottomLeft: { x: 0, y: 0, w: 0, h: 0 },
    bottomRight: { x: 0, y: 0, w: 0, h: 0 },
  }
  let initialWidth = 0
  let initialHeight = 0
  for (
    let gloveWidth = 0;
    initialWidth < width / 2;
    gloveWidth = gloveWidth + 0.5
  ) {
    coordinates.bottomLeft.w =
      gloveWidth * letInsideUrlRatio < width / 2
        ? gloveWidth * letInsideUrlRatio
        : initialWidth
    initialWidth = gloveWidth * letInsideUrlRatio
  }
  coordinates.bottomLeft.w = coordinates.bottomLeft.w - 30
  coordinates.bottomLeft.h = letInsideUrlRatio * coordinates.bottomLeft.w
  initialWidth = 0
  for (
    let gloveWidth = 0;
    initialWidth < width / 2;
    gloveWidth = gloveWidth + 0.5
  ) {
    coordinates.bottomRight.w =
      gloveWidth * rightInsideUrlRatio < width / 2
        ? gloveWidth * rightInsideUrlRatio
        : initialWidth
    initialWidth = gloveWidth * rightInsideUrlRatio
  }
  coordinates.bottomRight.w = coordinates.bottomRight.w - 30
  coordinates.bottomRight.h = rightInsideUrlRatio * coordinates.bottomRight.w

  /* top left and right cuff */
  let remainingHeight = 0
  let heightRatio =
    letInsideUrlRatio > rightInsideUrlRatio
      ? letInsideUrlRatio
      : rightInsideUrlRatio
  remainingHeight = pageHeight - (width / 2) * heightRatio
  coordinates.bottomLeft.x = (width / 2 - coordinates.bottomLeft.w) / 2
  coordinates.bottomLeft.y = remainingHeight + 25
  coordinates.bottomRight.x =
    width / 2 + (width / 2 - coordinates.bottomRight.w) / 2
  coordinates.bottomRight.y = remainingHeight + 25
  initialWidth = 0
  for (
    let gloveWidth = 0;
    initialWidth < width / 2;
    gloveWidth = gloveWidth + 0.5
  ) {
    coordinates.topLeft.w =
      gloveWidth * topUrlRatio < width / 2
        ? gloveWidth * topUrlRatio
        : initialWidth
    initialWidth = gloveWidth * topUrlRatio
  }
  if (topUrlRatio * coordinates.topLeft.w < remainingHeight) {
    coordinates.topLeft.h = topUrlRatio * coordinates.topLeft.w
    coordinates.topLeft.x = (width / 2 - coordinates.topLeft.w) / 2
    coordinates.topLeft.y = 20 + (remainingHeight - coordinates.topLeft.h) / 2
  } else {
    initialHeight = topUrlRatio * coordinates.topLeft.w
    for (
      let gloveWidth = coordinates.topLeft.w;
      topUrlRatio * initialHeight > remainingHeight;
      gloveWidth = gloveWidth - 0.5
    ) {
      initialHeight = gloveWidth * topUrlRatio
    }
    coordinates.topLeft.w = initialHeight
    coordinates.topLeft.h = topUrlRatio * coordinates.topLeft.w
    coordinates.topLeft.x = (width / 2 - coordinates.topLeft.w) / 2
    coordinates.topLeft.y = 20 + (remainingHeight - coordinates.topLeft.h) / 2
  }

  initialWidth = 0
  for (
    let gloveWidth = 0;
    initialWidth < width / 2;
    gloveWidth = gloveWidth + 0.5
  ) {
    coordinates.topRight.w =
      gloveWidth * palmUrlRatio < width / 2
        ? gloveWidth * palmUrlRatio
        : initialWidth
    initialWidth = gloveWidth * palmUrlRatio
  }
  if (palmUrlRatio * coordinates.topRight.w < remainingHeight) {
    coordinates.topRight.h = palmUrlRatio * coordinates.topRight.w
    coordinates.topRight.x =
      width / 2 + (width / 2 - coordinates.topRight.w) / 2
    coordinates.topRight.y = 20 + (remainingHeight - coordinates.topRight.h) / 2
  } else {
    initialHeight = palmUrlRatio * coordinates.topRight.w
    for (
      let gloveWidth = coordinates.topRight.w;
      palmUrlRatio * initialHeight > remainingHeight;
      gloveWidth = gloveWidth - 0.5
    ) {
      initialHeight = gloveWidth * palmUrlRatio
    }
    coordinates.topRight.w = initialHeight
    coordinates.topRight.h = palmUrlRatio * coordinates.topRight.w
    coordinates.topRight.x =
      width / 2 + (width / 2 - coordinates.topRight.w) / 2
    coordinates.topRight.y = 20 + (remainingHeight - coordinates.topRight.h) / 2
  }
  function getDimensionsWithFixedWidth() {
    let w = width,
      h = (width * 5) / 6
    return { width: w, height: h }
  }
  function getDimensionsWithFixedHeight() {
    let w = (height * 6) / 5,
      h = height
    return { width: w, height: h }
  }
  let Idimensions = getDimensionsWithFixedWidth()
  if (Idimensions.height > height) {
    Idimensions = getDimensionsWithFixedHeight()
  }

  const topOfHandRatio = {
    wRatio: (Idimensions.width * 0.42) / Math.max(dimensions.h, dimensions.w),
    hRatio:
      ((Idimensions.height - 50) * 0.7) / Math.max(dimensions.h, dimensions.w),
  }
  const topofHand = {
    x: 50,
    y: 15,
    w: dimensions.w * topOfHandRatio.wRatio,
    h: dimensions.h * topOfHandRatio.hRatio,
  }
  doc.addImage(
    topUrl,
    'PNG',
    topofHand.x,
    topofHand.y,
    topofHand.w,
    topofHand.h,
    '',
    'FAST',
  )
  const plamRatio = {
    wRatio:
      ((Idimensions.width - 30) * 0.5) /
      Math.max(dimensionsPalm.h, dimensionsPalm.w),
    hRatio:
      ((Idimensions.height - 50) * 0.7) /
      Math.max(dimensionsPalm.w, dimensionsPalm.h),
  }
  const plamCoordinates = {
    x: coordinates.topRight.x - 15,
    h: dimensionsPalm.h * plamRatio.hRatio,
    y:
      10 +
      (pageHeight -
        ((Idimensions.width * 0.42) / 2) * plamRatio.hRatio -
        dimensionsPalm.h * plamRatio.hRatio) /
        2,
    W: dimensionsPalm.w * plamRatio.wRatio,
  }

  doc.addImage(
    palmUrl,
    'PNG',
    plamCoordinates.x,
    plamCoordinates.y,
    plamCoordinates.W,
    plamCoordinates.h,
    '',
    'FAST',
  )
  const leftInsideCuffRatio = {
    wRatio: topOfHandRatio.wRatio,
    hRatio:
      ((Idimensions.height - 50) * 0.2) / Math.max(dimensions.h, dimensions.w),
  }
  const leftInsideCuff = {
    x:
      ((Idimensions.width * 0.42) / 2 -
        dimensions.h * leftInsideCuffRatio.hRatio) /
        2 +
      40,
    y:
      pageHeight -
      ((Idimensions.width * 0.42) / 2) * leftInsideCuffRatio.hRatio,
    w: dimensions.w * leftInsideCuffRatio.wRatio,
    h: dimensions.h * leftInsideCuffRatio.hRatio,
  }

  doc.addImage(
    letInsideUrl,
    'PNG',
    leftInsideCuff.x,
    leftInsideCuff.y - 15,
    leftInsideCuff.w,
    leftInsideCuff.h,
    '',
    'FAST',
  )
  const rightInsideCuffRatio = {
    wRatio:
      ((Idimensions.width - 30) * 0.42) /
      Math.max(dimensionsPalm.h, dimensionsPalm.w),
    hRatio:
      ((Idimensions.height - 50) * 0.2) /
      Math.max(dimensionsPalm.h, dimensionsPalm.w),
  }

  const rightInsideCuff = {
    x: coordinates.bottomRight.x + 13,
    y:
      pageHeight -
      ((Idimensions.width * 0.42) / 2) * rightInsideCuffRatio.hRatio,
    w: dimensionsPalm.w * rightInsideCuffRatio.wRatio - 25,
    h: dimensionsPalm.h * rightInsideCuffRatio.hRatio + 10,
  }

  doc.addImage(
    rightInsideUrl,
    'PNG',
    rightInsideCuff.x,
    rightInsideCuff.y - 15,
    rightInsideCuff.w,
    rightInsideCuff.h,
    '',
    'FAST',
  )
  doc.addPage('l')
  autoTable(doc, {
    head: [specColumnHeader],
    columns: specColumnDef,
    theme: 'grid', //'striped'|'grid'|'plain'
    tableLineWidth: 0.1,
    margin: { top: 20, left: 8, bottom: 35, right: 8 },
    didDrawCell: (data) => {
      if (data.section === 'body' && data.column.index === 2) {
        var rawData: any = data.row && data.row.raw && data.row.raw
        let code: any
        if (rawData) {
          code = rawData && rawData.code ? rawData.code : ''
        }
        if (code) {
          doc.setLineWidth(0)
          doc.setDrawColor(0)
          doc.setFillColor(`${code}`)
          doc.circle(data.cell.x + 5, data.cell.y + 4, 2, 'FD')
        }
      }
    },
    columnStyles: {
      0: {
        cellWidth: 30,
        cellPadding: 2,
        valign: 'middle',
        halign: 'left',
        fontSize: 9,
      },
      1: { cellPadding: 2, valign: 'middle', halign: 'left', fontSize: 9 },
      2: {
        cellWidth: 50,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 10 },
        valign: 'middle',
        halign: 'left',
        fontSize: 9,
      },
      3: { cellPadding: 2, valign: 'middle', halign: 'left', fontSize: 9 },
      4: { cellPadding: 2, valign: 'middle', halign: 'left', fontSize: 9 },
    },
    body: rows,
  })

  async function getConvertedImage(i: any) {
    const convertImage: any = await getImageDimensions(i)
    const hratio = (height - 10) / convertImage.h
    const wratio = (width - 10) / convertImage.w
    const imageWidth = convertImage.w * wratio
    const imageHeight = convertImage.h * hratio
    doc.addPage('l')
    return [convertImage.image, imageWidth, imageHeight]
  }

  const getCoordinates = () => {
    const x = width / 2 - 50
    const y = height / 2 + 32
    doc.setTextColor('#fff')
    doc.setFontSize(18)
    doc.setFontStyle('bold')
    return doc.text(`${params.order.gloveSize}`, x, y, null, null, 'center')
  }
  const getPlamColors = () => {
    if (params.dieCast.length) {
      const { dieCast = [] } = params
      dieCast
        .filter((color: any) => color.selected === true)
        .forEach((color: any, i: number) => {
          doc.setFontSize(10)
          doc.setFontStyle('normal')
          doc.setLineWidth(0)
          doc.setDrawColor(0)
          doc.setFillColor(`${color.code}`)
          if (params.order.product.name === 'SB6') {
            doc.rect(135, i === 1 ? 45 : 40, 22, 5, 'FD')
            doc.setTextColor(255, 255, 255)
            doc.text(136, i === 1 ? 49 : 44, `${color.name}`)
          } else {
            doc.rect(135, i === 1 ? 40 : 35, 22, 5, 'FD')
            doc.setTextColor(255, 255, 255)
            doc.text(138, i === 1 ? 44 : 39, `${color.name}`)
          }
        })
    }
    const { products = [] } = params
    const bindingColor = products.filter(
      (product: any) => product.item === 'Binding',
    )

    if (bindingColor.length) {
      bindingColor[0].options
        .filter((option: any) => option.selected === true)
        .forEach((option: any, i: any) => {
          doc.setFontSize(8)
          doc.setLineWidth(0)
          doc.setDrawColor(0)
          doc.setFillColor(`${option.code}`)
          doc.rect(133, i === 0 ? 152 : 157, 20, 5, 'FD')
          doc.setTextColor(255, 255, 255)
          doc.text(133, i === 0 ? 156 : 161, `${option.name}`)
        })
    }
  }
  if (params.order.product.name === 'VJ6') {
    const page3: any[] = await getConvertedImage(Vjasset1)
    doc.addImage(page3[0], 'PNG', 0, 0, page3[1], page3[2], '', 'FAST')
    const page4: any[] = await getConvertedImage(Vjasset2)
    doc.addImage(page4[0], 'PNG', 0, 5, page4[1], page4[2], '', 'FAST')
    const page5: any[] = await getConvertedImage(Vjasset3)
    doc.addImage(page5[0], 'PNG', 0, 0, page5[1], page5[2], '', 'FAST')
    const page6: any[] = await getConvertedImage(Vjasset4)
    doc.addImage(page6[0], 'PNG', 0, 5, page6[1], page6[2], '', 'FAST')
    getCoordinates()
    const page7: any[] = await getConvertedImage(Vjasset5)
    doc.addImage(page7[0], 'PNG', 0, 5, page7[1], page7[2], '', 'FAST')
    const page8: any[] = await getConvertedImage(Vjasset6)
    doc.addImage(page8[0], 'PNG', 0, 0, page8[1], page8[2], '', 'FAST')
    const page9: any[] = await getConvertedImage(Vjasset7)
    doc.addImage(page9[0], 'PNG', 0, 5, page9[1], page9[2], '', 'FAST')
    const page10: any[] = await getConvertedImage(Vjasset8)
    doc.addImage(page10[0], 'PNG', 0, 0, page10[1], page10[2] - 10, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 58,
      coordinates.bottomLeft.y + 28,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 30,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 8,
      coordinates.bottomRight.y + 28,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 30,
      '',
      'FAST',
    )
    const page11: any[] = await getConvertedImage(asset12)
    doc.addImage(page11[0], 'PNG', 0, 0, page11[1], page11[2] - 10, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastSmallUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 58,
      coordinates.bottomLeft.y + 28,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 30,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 8,
      coordinates.bottomRight.y + 28,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 30,
      '',
      'FAST',
    )
  }
  if (params.order.product.name === 'VK4') {
    const page3: any[] = await getConvertedImage(Vkasset1)
    doc.addImage(page3[0], 'PNG', 0, 0, page3[1], page3[2], '', 'FAST')
    const page4: any[] = await getConvertedImage(Vkasset2)
    doc.addImage(
      page4[0],
      'PNG',
      0,
      5,
      page4[1] - 10,
      page4[2] - 10,
      '',
      'FAST',
    )
    const page5: any[] = await getConvertedImage(Vkasset3)
    doc.addImage(page5[0], 'PNG', 0, 5, page5[1], page5[2], '', 'FAST')
    getCoordinates()
    const page6: any[] = await getConvertedImage(Vkasset4)
    doc.addImage(
      page6[0],
      'PNG',
      0,
      5,
      page6[1] - 10,
      page6[2] - 10,
      '',
      'FAST',
    )
    const page7: any[] = await getConvertedImage(Vkasset5)
    doc.addImage(page7[0], 'PNG', 0, 0, page7[1], page7[2], '', 'FAST')
    const page8: any[] = await getConvertedImage(Vkasset6)
    doc.addImage(page8[0], 'PNG', 0, 5, page8[1], page8[2], '', 'FAST')
    const page9: any[] = await getConvertedImage(Vkasset7)
    doc.addImage(page9[0], 'PNG', 0, 0, page9[1], page9[2] - 10, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 58,
      coordinates.bottomLeft.y + 20,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 25,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 8,
      coordinates.bottomRight.y + 20,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 25,
      '',
      'FAST',
    )
    const page10: any[] = await getConvertedImage(asset12)
    doc.addImage(page10[0], 'PNG', 0, 0, page10[1], page10[2] - 10, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastSmallUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 58,
      coordinates.bottomLeft.y + 20,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 25,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 8,
      coordinates.bottomRight.y + 20,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 25,
      '',
      'FAST',
    )
  }
  if (params.order.product.name === 'SB6') {
    const page3: any[] = await getConvertedImage(asset2)
    doc.addImage(page3[0], 'PNG', 0, 0, page3[1], page3[2], '', 'FAST')
    const page4: any[] = await getConvertedImage(asset3)
    doc.addImage(page4[0], 'PNG', 0, 5, page4[1], page4[2], '', 'FAST')
    const page5: any[] = await getConvertedImage(asset4)
    doc.addImage(page5[0], 'PNG', 0, 0, page5[1], page5[2], '', 'FAST')
    const page6: any[] = await getConvertedImage(asset5)
    doc.addImage(page6[0], 'PNG', 0, 5, page6[1], page6[2], '', 'FAST')
    getCoordinates()
    const page7: any[] = await getConvertedImage(asset6)
    doc.addImage(page7[0], 'PNG', 0, 5, page7[1], page7[2], '', 'FAST')
    const page8: any[] = await getConvertedImage(asset9)
    doc.addImage(page8[0], 'PNG', 0, 0, page8[1], page8[2], '', 'FAST')
    const page9: any[] = await getConvertedImage(asset10)
    doc.addImage(page9[0], 'PNG', 0, 5, page9[1], page9[2], '', 'FAST')
    const page10: any[] = await getConvertedImage(asset11)
    doc.addImage(page10[0], 'PNG', 0, 5, page10[1], page10[2] - 15, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 56,
      coordinates.bottomLeft.y + 36,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 30,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 6,
      coordinates.bottomRight.y + 36,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 30,
      '',
      'FAST',
    )
    const page11: any[] = await getConvertedImage(asset12)
    doc.addImage(page11[0], 'PNG', 0, 5, page11[1], page11[2] - 15, '', 'FAST')
    getPlamColors()
    doc.addImage(dieCastSmallUrl, 'PNG', 18, 10, 250, 150, '', 'FAST')
    doc.addImage(
      letInsideUrl,
      'PNG',
      coordinates.bottomLeft.x + 56,
      coordinates.bottomLeft.y + 36,
      coordinates.bottomLeft.w - 60,
      coordinates.bottomLeft.h - 30,
      '',
      'FAST',
    )
    doc.addImage(
      rightInsideUrl,
      'PNG',
      coordinates.bottomRight.x - 6,
      coordinates.bottomRight.y + 36,
      coordinates.bottomRight.w - 60,
      coordinates.bottomRight.h - 30,
      '',
      'FAST',
    )
  }
  const pageCount = doc.internal.getNumberOfPages()
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i)
    doc.setLineWidth(0.4)
    doc.setDrawColor(100)
    doc.line(4, 10, width - 4, 10)
    doc.setTextColor('#000')
    doc.setFontSize(10)
    doc.setFont('AntennaExtraCond-Bold', 'bold')
    doc.setFontStyle('bold')
    doc.addImage(convertImagetoBase64String(pdfLogo), 'PNG', 8, 4, 20, 0)
    doc.text(`Page ${i} of ${pageCount}`, width - 10, 6, null, null, 'right')
    doc.setFontSize(7)
    let arr = [
      '',
      'PRODUCT NUMBER',
      'PRODUCT NAME',
      'CATEGORY',
      'DESIGNER',
      'ENGINEER',
      'PRODUCT MANGER',
      'SEASON',
      'REVISION DATE',
    ]
    let headData: any = []
    arr.forEach((item: any, index: any) => {
      let obj = {
        content: `${item}${index === 0 ? '' : ':'}`,
        colSpan: 1,
        rowSpan: index === 0 ? 2 : 1,
        styles: {
          halign: index === 0 ? 'center' : 'left',
          valign: 'middle',
          fontStyle: 'bold',
          cellPadding: 1,
          fontSize: index === 0 ? 14 : 7,
        },
      }
      headData.push(obj)
    })
    let footerArr = [
      '',
      '<TBD>',
      `${params.order.product.name} - NFL`,
      `FOOTBALL`,
      `ANDREW NGUYEN`,
      `CODY DILL`,
      `LAUREN SILBER`,
      `${params.order.season}`,
      `${moment(params.order.updateAt).format('YYYY-MM-DD')}`,
    ]
    let footerData: any = []
    footerArr.forEach((item: any, index: any) => {
      let obj = {
        content: `${item}`,
        colSpan: 1,
        rowSpan: 1,
        styles: {
          halign: 'left',
          cellPadding: 1,
          fontSize: 10,
        },
      }
      footerData.push(obj)
    })
    let staticFooter: any = [
      {
        content: `STRICTLY CONFIDENTIAL JR286 DESIGN. "KEEP TIGHT" - ALL MATERIAL IS PROPERTY OF JR286, ANY USE, REPRESENTATION OR PRODUCTION WITHOUT WRITTEN AUTHORIZATION STRICTLY PROHIBITED `,
        colSpan: 10,
        rowSpan: 1,
        styles: {
          halign: 'left',
          fillColor: '#323232',
          textColor: 'white',
          fontSize: 7,
        },
      },
    ]

    autoTable(doc, {
      head: [headData],
      columns: headData,
      body: [footerData],
      didDrawCell: (data) => {
        if (data.section === 'head' && data.column.index === 0) {
          doc.addImage(
            convertImagetoBase64String(pdfLogo),
            'PNG',
            data.cell.x + 5,
            data.cell.y + 4,
            20,
            0,
            '',
            'FAST',
          )
        }
      },
      columnStyles: {
        0: {
          cellWidth: 28,
          cellPadding: 1,
          valign: 'middle',
          halign: 'left',
        },
      },
      theme: 'plain', //'striped'|'grid'|'plain'
      tableLineWidth: 0.3,
      tableLineColor: '#323232',
      margin: { top: height - 25, left: 4, bottom: 2, right: 4 },
      foot: [staticFooter],
    })
  }

  const pdfFile = new File([doc.output('blob')], `${params.orderId}.pdf`, {
    type: 'pdf',
  })
  var zip = new JSZIP()

  zip.file(`${params.orderId}.pdf`, pdfFile)
  zip.file(`${params.orderId}-1.pdf`, pdfFile)
  let logoList: any = getAiLogos(params.playerLogo)
  logoList.forEach((ai: any) => {
    let blob: any = fetch(ai.aiLogo).then((r) => (r ? r.blob() : null))
    zip.file(ai.aiName, blob, { binary: true })
  })

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, `${params.orderId}.zip`)
  })
}
