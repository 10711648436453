import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const GET_TEAMS = gql`
  {
    teams(limit: 50, page: 1, sort: DESC, search: "") {
      count
      pages
      page
      teams {
        _id
        name
        logo {
          _id
          teamId
          status
          name
          logo
          aiLogo
          aiName
        }
        color {
          _id
          teamId
          name
          status
          code
          publicName
        }
      }
    }
  }
`

export function useGetTeams() {
  return useQuery(GET_TEAMS)
}

const CREATE_TEAM = gql`
  mutation createTeam($_id: ID, $name: String) {
    createTeam(_id: $_id, name: $name) {
      _id
      name
      logo {
        _id
        teamId
        name
        status
        logo
        aiLogo
        aiName
      }
      color {
        _id
        teamId
        status
        name
        code
      }
    }
  }
`

export function useCreateTeam() {
  return useMutation(CREATE_TEAM, {
    update(cache, { data: { createTeam } }) {
      const { teams }: any = cache.readQuery({ query: GET_TEAMS })
      const teamIndex = teams.teams.findIndex(
        (team: any) => team._id === createTeam._id,
      )

      if (teamIndex === -1) {
        teams.teams.unshift(createTeam)

        cache.writeQuery({
          query: GET_TEAMS,
          data: { teams: { ...teams } },
        })
      }
    },
  })
}

const UPDATE_TEAM_NAME = gql`
  mutation createTeam($_id: ID, $name: String) {
    createTeam(_id: $_id, name: $name) {
      _id
      name
    }
  }
`

export function useUpdateTeamName() {
  return useMutation(UPDATE_TEAM_NAME)
}

const CREATE_TEAM_LOGO = gql`
  mutation createTeamLogo(
    $_id: ID
    $teamId: String
    $status: Boolean
    $name: String
    $logo: String
    $aiLogo: String
    $aiName: String
  ) {
    createTeamLogo(
      _id: $_id
      status: $status
      teamId: $teamId
      name: $name
      logo: $logo
      aiLogo: $aiLogo
      aiName: $aiName
    ) {
      _id
      teamId
      status
      name
      logo
      aiLogo
      aiName
    }
  }
`

export function useCreateTeamLogo() {
  return useMutation(CREATE_TEAM_LOGO, {
    update(cache, { data: { createTeamLogo } }) {
      const { teams }: any = cache.readQuery({ query: GET_TEAMS })
      const teamIndex = teams.teams.findIndex(
        (team: any) => team._id === createTeamLogo.teamId,
      )

      if (teamIndex > -1) {
        const selectedTeam = teams.teams[teamIndex]
        const logoIndex = selectedTeam.logo.findIndex(
          (logo: any) => logo._id === createTeamLogo._id,
        )

        if (logoIndex === -1) {
          // selectedTeam.logo.push(createTeamLogo)
        }

        cache.writeQuery({
          query: GET_TEAMS,
          data: { teams: { ...teams } },
        })
      }
    },
  })
}

const CREATE_TEAM_COLOR = gql`
  mutation createTeamColor(
    $_id: ID
    $teamId: String!
    $name: String!
    $status: Boolean
    $code: String!
    $publicName: String
  ) {
    createTeamColor(
      _id: $_id
      status: $status
      teamId: $teamId
      name: $name
      code: $code
      publicName: $publicName
    ) {
      _id
      teamId
      name
      status
      code
      publicName
    }
  }
`

export function useCreateTeamColor() {
  return useMutation(CREATE_TEAM_COLOR, {
    update(cache, { data: { createTeamColor } }) {
      const { teams }: any = cache.readQuery({ query: GET_TEAMS })
      const teamIndex = teams.teams.findIndex(
        (team: any) => team._id === createTeamColor._id,
      )
      if (teamIndex > -1) {
        const selectedTeam = teams.teams[teamIndex]
        const colorIndex = selectedTeam.color.findIndex(
          (color: any) => color._id === createTeamColor._id,
        )

        if (colorIndex === -1) {
          selectedTeam.color.push(createTeamColor)
        }

        cache.writeQuery({
          query: GET_TEAMS,
          data: { teams: { ...teams } },
        })
      }
    },
  })
}
