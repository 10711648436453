import React from 'react'

export default function PalmOfHand() {
  return (
    <svg width="27px" height="46px" viewBox="0 0 27 46" version="1.1">
      <title>Palm</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="hand-front" fill="#49545F">
          <path
            d="M20.865,17.834 C20.451,17.834 20.115,17.499 20.115,17.084 L20.115,7.751 C20.115,7.336 20.451,7.001 20.865,7.001 C21.279,7.001 21.615,7.336 21.615,7.751 L21.615,17.084 C21.615,17.499 21.279,17.834 20.865,17.834"
            id="Fill-100"></path>
          <path
            d="M15.836,18.94 C15.422,18.94 15.086,18.603 15.086,18.19 L15.086,3.224 C15.086,2.81 15.422,2.474 15.836,2.474 C16.25,2.474 16.586,2.81 16.586,3.224 L16.586,18.19 C16.586,18.603 16.25,18.94 15.836,18.94"
            id="Fill-101"></path>
          <path
            d="M10.808,19.151 C10.394,19.151 10.058,18.815 10.058,18.401 L10.058,3.216 C10.058,2.802 10.394,2.466 10.808,2.466 C11.222,2.466 11.558,2.802 11.558,3.216 L11.558,18.401 C11.558,18.815 11.222,19.151 10.808,19.151"
            id="Fill-102"></path>
          <path
            d="M5.779,19.27 C5.365,19.27 5.029,18.934 5.029,18.52 L5.029,4.961 C5.029,4.547 5.365,4.211 5.779,4.211 C6.193,4.211 6.529,4.547 6.529,4.961 L6.529,18.52 C6.529,18.934 6.193,19.27 5.779,19.27"
            id="Fill-103"></path>
          <path
            d="M11.469,45.744 C8.291,45.744 5.1,44.849 2.183,43.061 C1.961,42.925 1.825,42.682 1.825,42.422 L1.825,34.936 L0.075,31.298 C0.026,31.196 0.001,31.085 0.001,30.972 L0.001,8.733 C0.001,8.319 0.337,7.983 0.751,7.983 C1.165,7.983 1.501,8.319 1.501,8.733 L1.501,30.802 L3.251,34.44 C3.299,34.542 3.325,34.653 3.325,34.765 L3.325,39.996 C8.49,43.007 14.305,43.035 19.4,40.077 L19.355,35.28 C19.353,35.05 19.457,34.83 19.638,34.686 L24.133,31.11 C24.637,30.526 24.914,29.787 24.914,29.021 L24.914,23.501 C24.914,22.867 24.681,22.253 24.256,21.772 L20.32,17.599 C20.036,17.298 20.049,16.823 20.351,16.539 C20.652,16.254 21.127,16.268 21.411,16.57 L25.364,20.761 C26.048,21.535 26.414,22.501 26.414,23.501 L26.414,29.021 C26.414,30.177 25.987,31.29 25.211,32.156 C25.183,32.187 25.152,32.217 25.119,32.243 L20.859,35.633 L20.904,42.494 C20.906,42.767 20.76,43.02 20.522,43.154 C17.698,44.881 14.59,45.744 11.469,45.744 L11.469,45.744 Z"
            id="Fill-104"></path>
          <path
            d="M20.865,8.332 C20.451,8.332 20.115,7.996 20.115,7.582 C20.115,6.635 19.323,5.865 18.351,5.865 C17.378,5.865 16.586,6.635 16.586,7.582 C16.586,7.996 16.25,8.332 15.836,8.332 C15.422,8.332 15.086,7.996 15.086,7.582 C15.086,5.808 16.551,4.365 18.351,4.365 C20.151,4.365 21.615,5.808 21.615,7.582 C21.615,7.996 21.279,8.332 20.865,8.332"
            id="Fill-105"></path>
          <path
            d="M20.735,24.761 C20.546,24.761 20.356,24.69 20.21,24.547 L16.788,21.193 C16.171,20.62 15.81,19.819 15.786,18.951 C15.763,18.088 16.076,17.262 16.668,16.626 C17.259,15.989 18.059,15.616 18.921,15.576 C19.793,15.533 20.614,15.836 21.245,16.424 C21.549,16.705 21.567,17.18 21.284,17.483 C21.003,17.788 20.527,17.804 20.225,17.522 C19.891,17.212 19.445,17.059 18.991,17.074 C18.524,17.096 18.089,17.3 17.767,17.647 C17.443,17.994 17.273,18.443 17.286,18.91 C17.298,19.372 17.489,19.798 17.823,20.108 L21.26,23.475 C21.556,23.765 21.561,24.24 21.271,24.536 C21.124,24.686 20.929,24.761 20.735,24.761"
            id="Fill-106"></path>
          <path
            d="M15.836,3.967 C15.422,3.967 15.086,3.631 15.086,3.217 C15.086,2.27 14.295,1.5 13.322,1.5 C12.35,1.5 11.559,2.27 11.559,3.217 C11.559,3.631 11.222,3.967 10.809,3.967 C10.395,3.967 10.059,3.631 10.059,3.217 C10.059,1.443 11.522,-2.81996648e-14 13.322,-2.81996648e-14 C15.122,-2.81996648e-14 16.586,1.443 16.586,3.217 C16.586,3.631 16.25,3.967 15.836,3.967"
            id="Fill-107"></path>
          <path
            d="M10.808,6.016 C10.394,6.016 10.058,5.679 10.058,5.266 C10.058,4.318 9.266,3.549 8.293,3.549 C7.321,3.549 6.529,4.318 6.529,5.266 C6.529,5.679 6.193,6.016 5.779,6.016 C5.365,6.016 5.029,5.679 5.029,5.266 C5.029,3.492 6.494,2.049 8.293,2.049 C10.094,2.049 11.558,3.492 11.558,5.266 C11.558,5.679 11.222,6.016 10.808,6.016"
            id="Fill-108"></path>
          <path
            d="M5.779,9.565 C5.365,9.565 5.029,9.229 5.029,8.815 C5.029,7.869 4.237,7.099 3.265,7.099 C2.292,7.099 1.5,7.869 1.5,8.815 C1.5,9.229 1.164,9.565 0.75,9.565 C0.336,9.565 2.13162821e-13,9.229 2.13162821e-13,8.815 C2.13162821e-13,7.042 1.465,5.599 3.265,5.599 C5.065,5.599 6.529,7.042 6.529,8.815 C6.529,9.229 6.193,9.565 5.779,9.565"
            id="Fill-109"></path>
          <path
            d="M13.208,33.295 C13.173,33.295 13.138,33.293 13.102,33.287 C12.692,33.23 12.406,32.85 12.464,32.44 C13.043,28.344 16.132,27.098 16.263,27.046 C16.648,26.899 17.084,27.088 17.234,27.474 C17.384,27.858 17.194,28.291 16.811,28.442 C16.707,28.484 14.401,29.456 13.95,32.65 C13.896,33.024 13.576,33.295 13.208,33.295"
            id="Fill-110"></path>
          <path
            d="M5.78,36.892 C5.744,36.892 5.709,36.89 5.673,36.883 C2.457,36.422 1.207,33.663 1.155,33.545 C0.988,33.165 1.161,32.724 1.54,32.556 C1.917,32.392 2.359,32.561 2.528,32.938 C2.572,33.036 3.518,35.059 5.885,35.399 C6.296,35.458 6.58,35.838 6.521,36.247 C6.468,36.622 6.147,36.892 5.78,36.892"
            id="Fill-111"></path>
          <path
            d="M16.448,36.882 C16.034,36.882 15.698,36.546 15.698,36.132 C15.698,35.718 16.034,35.382 16.448,35.382 C18.939,35.382 20.347,34.127 20.361,34.114 C20.667,33.836 21.142,33.859 21.42,34.164 C21.699,34.471 21.677,34.945 21.37,35.224 C21.296,35.291 19.513,36.882 16.448,36.882"
            id="Fill-112"></path>
        </g>
      </g>
    </svg>
  )
}
